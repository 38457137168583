import React, { useContext } from 'react';
import { Table, EllipsisDropdown, ButtonLink, ControlledPopup } from 'dyl-components';
import { Checkbox, Dropdown, DropdownItem, Header, Popup } from 'semantic-ui-react';
import { BulkActionsContext } from 'shared/context/BulkActionsProvider';

import '../index.scss';
import { PhoneUtil } from 'utils';

const Row = ({ callRoutingRule = {}, onReadCallRoutingRule, onDelete }) => {
    const { id, label, phones, destination, in_use } = callRoutingRule;
    const [selectedCallRoutingRules, setSelectedCallRoutingRules] = useContext(BulkActionsContext);

    const isSelected = (routing_rule_id) => {
        return selectedCallRoutingRules.includes(routing_rule_id);
    }

    const onToggleNumber = (routing_rule_id) => {
        const newSelectedCallRoutingRules = isSelected(routing_rule_id) ? selectedCallRoutingRules.filter(id => routing_rule_id !== id) : [...selectedCallRoutingRules, routing_rule_id];
        setSelectedCallRoutingRules(newSelectedCallRoutingRules);
    }

    return (
        <Table.Row>
            <Table.Cell>
                <Checkbox
                    checked={isSelected(id)}
                    onChange={() => {
                        onToggleNumber(id);
                    }}
                />
            </Table.Cell>
            <Table.Cell>
                <ButtonLink onClick={() => onReadCallRoutingRule(id)}>
                    {label}
                </ButtonLink>
            </Table.Cell>
            <Table.Cell>
                <Popup
                    basic
                    on={"click"}
                    position="bottom center"
                    disabled={phones?.length <= 2}
                    trigger={
                        <div
                            style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "245px",
                            }}
                        >
                            {phones?.map((number, idx) => (
                                <span>
                                    {PhoneUtil.formatPhoneNumber(number)}
                                    {phones.length - 1 === idx ? "" : ", "}
                                </span>
                            ))}
                        </div>
                    }
                    content={
                        <div style={{ maxHeight: 128, overflow: "auto" }}>
                            {phones?.map((number, idx) => {
                                return (
                                    <div
                                        style={{
                                            marginRight: 50,
                                            marginBottom:
                                                idx === phones.length - 1
                                                    ? 0
                                                    : 7,
                                        }}
                                    >
                                        <span>
                                            {PhoneUtil.formatPhoneNumber(
                                                number
                                            )}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    }
                />
            </Table.Cell>
            <Table.Cell>None</Table.Cell>
            <Table.Cell>{destination?.label}</Table.Cell>
            <Table.Cell>
                <EllipsisDropdown>
                    <DropdownItem
                        text={"Edit"}
                        icon={"fa-solid fa-pencil"}
                        onClick={() => onReadCallRoutingRule(id)}
                    />

                    <ControlledPopup
                        header={
                            <Header as="h4" textAlign="center">
                                Are you sure?
                                <Header.Subheader style={{ marginTop: 5 }}>
                                    You cannot undo delete actions.
                                </Header.Subheader>
                            </Header>
                        }
                        trigger={
                            in_use ? (
                                <Popup
                                    content="Routing rule in use"
                                    position="right center"
                                    inverted
                                    on={'hover'}
                                    trigger={
                                        <Dropdown.Item
                                            className={`routing-rule-${
                                                in_use ? "disable" : ""
                                            }`}
                                            text="Delete"
                                            icon="fa-solid fa-trash"
                                        />
                                    }
                                />
                            ) : (
                                <Dropdown.Item
                                    text="Delete"
                                    icon="fa-solid fa-trash"
                                />
                            )
                        }
                        disabled={in_use}
                        onConfirm={() => onDelete(id)}
                        inverted
                    />
                </EllipsisDropdown>
            </Table.Cell>
        </Table.Row>
    );
}

export default Row;


