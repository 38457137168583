import { Table } from "dyl-components";
import { Icon } from "semantic-ui-react";

const Subscription = ({ price, productInfo, user, RowCheckboxComponent, id }) => {
    return (
        <Table.Row>
            {RowCheckboxComponent && (
                <Table.Cell>
                    <RowCheckboxComponent id={id} disabled={user?.active} />
                </Table.Cell>
            )}
            <Table.Cell>
                {productInfo}
            </Table.Cell>
            <Table.Cell>
                {user && (
                    <div>
                        <div>
                            <Icon className="fas fa-circle-user" /> {user.name}
                        </div>
                        <div>Ext. {user.extension}</div>
                    </div>
                )}
            </Table.Cell>
            <Table.Cell>${price}</Table.Cell>
        </Table.Row>
    );
};

export default Subscription;
