import { generateResolver, Modal, Notification, STATUS_TYPES, Step, VALIDATORS, yup } from 'dyl-components';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Button, Form, Portal } from 'semantic-ui-react';
import * as XLSX from 'xlsx';

import ImportSetup from './ImportSetup';
import ImportSetupFields from '../DataMapping/ImportSetupFields';
import DataMapping from '../DataMapping/DataMapping';
import MappingTable from '../DataMapping/MappingTable';
import { ImportFooter } from './ImportFooter';
import useWidthListener from 'shared/SettingsFooter/useWidthListener';
import useWindowWidth from 'shared/SettingsFooter/useWindowWidth';
import { useDispatch, useSelector } from 'react-redux';
import importActions from 'actions/import_data';
import { MathUtils } from 'utils';
import customFieldsActions from 'actions/custom_fields';
import pipelineActions from 'actions/pipeline';
import { ObjectUtils, AccountUtils, StringUtils, ValidationUtils } from '../../utils'
import { default as STEPS } from '../DataMapping/Steps'
import { DUPLICATE_OPTIONS, DYL_FIELDS_SCHEMA } from '../DataMapping/DYLFieldsConstants';
import FileUtils, { FILE_CATEGORIES } from 'utils/FileUtils';

const AddImport = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const { pipelineCategories, isReadingPipelineCategories } = useSelector((state) => {
        const categories = state.pipeline.categories;
        return {
            pipelineCategories: categories.length === 1 ?
                categories[0].stages :
                categories.find((pipeline) => pipeline.primary)?.stages,
            isReadingPipelineCategories: state.pipeline.isReadingCategories,
        }
    });
    const dispatch = useDispatch();
    const width = useWidthListener("settingsSidebar");
    const windowWidth = useWindowWidth();

    const { control: importSetupControl, formState: { isValid: isImportSetupValid, isDirty: isImportSetupDirty }, watch, setValue, getValues } = useForm({
        mode: 'onChange',
        defaultValues: {
            description: '',
            module: null,
            duplicate_option: null,
            file: [],
            stage: null,
            match_type: ['email', 'full_name', 'phone']
        },
        resolver: generateResolver({
            file: FileUtils.getFilesizeValidator(FILE_CATEGORIES.IMPORT),
            description: VALIDATORS.DESCRIPTION().required('This field is required'),
            module: yup.mixed().oneOf(['Lead', 'Contact', 'Opportunity', 'Customer']),
            duplicate_option: yup.mixed().required('This field is required').oneOf(DUPLICATE_OPTIONS.map(option => option.value)),
            stage: yup.mixed().when("module", {
                is: (stage) => stage !== 'Contact',
                then: schema => schema.required('This field is required'),
                otherwise: schema => schema.nullable(true)
            }),
        })
    });

    const { control: dataMappingControl, formState: { isValid: isDataMappingValid, isDirty: isDataMappingFormDirty }, setValue: setDataMappingValue, watch: dataMappingWatch, getValues: getDataMappingValues, trigger: dataMappingTrigger } = useForm({
        mode: 'onChange',
        defaultValues: {
            interests: [],
            group: 'none',
            campaign: 'none',
            fields: []
        },
        resolver: generateResolver({
            fields: DYL_FIELDS_SCHEMA
        })
    });

    const { fields, update: updateField } = useFieldArray({
        control: dataMappingControl,
        name: 'fields'
    });

    const [isMappingSummaryDisplayed, setIsMappingSummaryDisplayed] = useState(false);

    const watchedFields = dataMappingWatch('fields');

    const interestsSelectedAsDylField = watchedFields.findIndex(field => field.dyl_field === "interests") !== -1;
    const isFieldSkipped = !(fields.length === dataMappingWatch()?.fields.filter((field) => field.skipped).length);
    const productInterestsSelectedAsDylField = watchedFields.findIndex(field => field.dyl_field === "product_interests") !== -1;

    const isNextStepEnabled = () => {
        if (currentStep === 0) {
            return isImportSetupValid && isImportSetupDirty && getValues("file").length === 1;
        }
        if (currentStep === 1) {
            return isDataMappingValid && isDataMappingFormDirty && isFieldSkipped && ValidationUtils.isMinimumFields(dataMappingWatch()).isValid;
        }
    }

    const [module, duplicate_option] = watch(['module', 'duplicate_option']);

    const clearStage = () => {
        setValue('stage', null);
    }

    const onUpload = (files) => {
        if (files[0]) {
            setValue('description', files[0].name, { shouldValidate: true });
        }
    }

    const fieldOptions = useSelector(state => {
        return ObjectUtils.formatFields(state.custom_fields.mapping_fields)
    });

    const parseCSVForDataMapping = (fieldOptions) => {
        const file = getValues('file')[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            const arrayBuffer = reader.result;

            const options = { type: 'array' };
            const workbook = XLSX.read(arrayBuffer, options);

            const sheetName = workbook.SheetNames
            const sheet = workbook.Sheets[sheetName]
            const entries = XLSX.utils.sheet_to_json(sheet, { header: 1, blankrows: false });
            const headers = entries[0];
            const firstEntry = entries[1];
            setDataMappingValue('fields', headers.map((header, index) => {
                const customField = fieldOptions.find(option =>
                    (option?.field_type === "phone" ?
                        StringUtils.sanitizeTerminology(StringUtils.formatSearch(StringUtils.sanitizeString(header?.toLowerCase()))).includes("phone") : true)
                    && StringUtils.sanitizeTerminology(StringUtils.formatSearch(StringUtils.sanitizeString(header?.toLowerCase())))
                        .includes(StringUtils.sanitizeAppendedValue(option?.text?.toLowerCase())) && !option?.disabled,
                );
                return {
                    name: header,
                    value: firstEntry[index] || '',
                    dyl_field: customField?.value || '',
                    field_type: customField?.field_type || '',
                    default_value: '',
                    duplicateProcess: duplicate_option === 'merge' ? 'keep' : duplicate_option === 'merge_only' ? 'replace' : 'keep',
                    skipped: false,
                    options:'',
                    // valid_field: TODO: BE return valid fields
                }
            }));
            dataMappingTrigger('fields');
        }
        reader.readAsArrayBuffer(file);
    }
    const goToNextStep = async () => {
        const isNotFinalStep = currentStep < STEPS.length - 1;
        if (isMappingSummaryDisplayed) {
            setCurrentStep(currentStep + 1);
            setIsMappingSummaryDisplayed(false);
        } else if (currentStep === 1) {
            const { file: files, description, module, duplicate_option, match_type, stage } = getValues();
            const { fields } = getDataMappingValues();
            const file = files[0];
            const formData = new FormData();
            const mapping = {};
            fields.forEach(field => {
                mapping[field.name] = {
                    default: field.default_value,
                    overwrite: field.duplicateProcess === 'replace',
                    skipped: field.skipped,
                    value: field.dyl_field
                }
            })
            formData.append('file', file, file.name);
            formData.append('file_name', file.name);
            formData.append('file_description', description);
            formData.append('mapping', JSON.stringify(mapping));
            const pipelineStage = pipelineCategories?.find((pipelineStage) => (pipelineStage.name === stage));

            try {
                await dispatch(importActions.addImport(formData, {
                    module,
                    duplicate_on: match_type,
                    duplicate_option,
                    pipeline_stage_id: pipelineStage?.id,
                }, null, {
                    "Accept": "application/json",
                    "Content-Type": "multipart/form-data",
                }));
                setIsMappingSummaryDisplayed(true);
            } catch (e) {
                console.log(e);
                Notification.alert('Failed to map', STATUS_TYPES.ERROR);
            }

        } else if (isNotFinalStep) {
            setCurrentStep(currentStep + 1);
            const isDataMappingStep = currentStep + 1 === 1;
            if (isDataMappingStep) {
                const { module } = getValues();
                dispatch(customFieldsActions.readMappingFields({ module: module === 'Contact' ? 'person' : module?.toLowerCase() })).then((response) => {
                    parseCSVForDataMapping(ObjectUtils.formatFields(response));
                });
            }
        }
    }

    const goToPreviousStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    }

    useEffect(() => {
        const pipelineStage = AccountUtils.getModulePipeline(module);
        if (pipelineStage) {
            dispatch(pipelineActions.getPipelineCategories({ account_stage: pipelineStage }));
        }
    }, [dispatch, module])
    return (
        <React.Fragment>
            <Step.Group horizontal>
                {STEPS.map((step, index) => (
                    <Step key={step.title} completed={index < currentStep} active={index === currentStep} title={step.title}>
                        {step.icon}
                    </Step>
                ))}
            </Step.Group>
            {currentStep === 0 && (
                <ImportSetup
                    control={importSetupControl}
                    module={module}
                    clearStage={clearStage}
                    duplicate_option={duplicate_option}
                    onUpload={onUpload}
                    importSetupFields={(
                        <ImportSetupFields
                            clearStage={clearStage}
                            control={importSetupControl}
                            duplicate_option={duplicate_option}
                            module={module}
                            pipelineCategories={pipelineCategories}
                            isReadingPipelineCategories={isReadingPipelineCategories}
                        />
                    )}
                />
            )}
            {currentStep === 1 && (
                <DataMapping
                    dataMappingControl={dataMappingControl}
                    module={module}
                    interestsSelectedAsDylField={interestsSelectedAsDylField}
                    productInterestsSelectedAsDylField={productInterestsSelectedAsDylField}
                    importSetupFields={[
                        <Controller
                            control={importSetupControl}
                            name='description'
                            render={({ field: { value } }) => (
                                <Form.Field
                                    control='div'
                                    label='Source'
                                >
                                    <div>
                                        Import Data
                                    </div>
                                    <small>
                                        File: {value}
                                    </small>
                                </Form.Field>
                            )}
                        />,
                        <ImportSetupFields
                            clearStage={clearStage}
                            control={importSetupControl}
                            duplicate_option={duplicate_option}
                            module={module}
                            disable_module
                            pipelineCategories={pipelineCategories}
                            isReadingPipelineCategories={isReadingPipelineCategories}
                        />
                    ]}
                    mappingTable={(
                        <MappingTable
                            fields={fields}
                            isMerging={duplicate_option === 'merge' || duplicate_option === 'merge_only'}
                            dataMappingControl={dataMappingControl}
                            module={module}
                            updateField={updateField}
                            watchedFields={watchedFields}
                            fieldOptions={fieldOptions}
                            trigger={dataMappingTrigger}
                        />
                    )}
                />
            )}
            <Modal open={isMappingSummaryDisplayed}>
                <Modal.Header>
                    Mapping Summary
                </Modal.Header>
                <Modal.Content>
                    <p><b>New {module === 'Opportunity' ? 'opportunities' : `${module}s`} created:</b> 0</p>
                    <p><b>Duplicates merged:</b> 0</p>
                    <p><b>Rejected records:</b> 0</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button primary basic onClick={() => { setIsMappingSummaryDisplayed(false) }}>Cancel</Button>
                    <Button primary onClick={goToNextStep}>Next</Button>
                </Modal.Actions>
            </Modal>
            <Portal open>
                <ImportFooter
                    isMinimumFields={ValidationUtils.isMinimumFields(dataMappingWatch())}
                    currentStep={currentStep}
                    goToNextStep={goToNextStep}
                    isNextStepEnabled={isNextStepEnabled}
                    goToPreviousStep={goToPreviousStep}
                    STEPS={STEPS}
                    width={MathUtils.calculatePercentage(windowWidth, windowWidth - width)}
                    {...(currentStep === 1 ? { customStep: 'Map' } : {})}
                />
            </Portal>
        </React.Fragment>
    )
};

export default AddImport;
