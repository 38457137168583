import { BillingOverview } from 'pages';
import React from 'react';
import { Route } from 'react-router-dom';

export const BILLING_ROUTES = [
    <Route index path='overview' element={<BillingOverview />} />,
    <Route index path='transaction-history' element={<></>} />,
    <Route index path='payment-method' element={<></>} />,
    <Route index path='billing-contacts' element={<></>} />
];
