import React, { useEffect, useState } from "react";
import {
    ClearableCheckboxDropdown,
    Table,
    TimestampFilters,
} from "dyl-components";
import { Table as SemanticTable, Icon } from "semantic-ui-react";

import { useSearchParams, useNavigate } from "react-router-dom";
import {
    getData,
    onToggleTimeUnit,
    useRangeCalendar,
} from "shared/CustomDateRange/customDateRange";

const SortActions = ({ direction = false, onToggleSort = () => {} }) => {
    return (
        <div
            onClick={onToggleSort}
            style={{ display: "inline-flex", cursor: "pointer" }}
        >
            <Icon
                name="fa-solid fa-arrow-down-long"
                style={{
                    marginRight: -7,
                    opacity: !direction ? "100%" : "50%",
                }}
            />
            <Icon
                name="fa-solid fa-arrow-up-long"
                style={{ opacity: direction ? "100%" : "50%" }}
            />
        </div>
    );
};

export const ImportTableHeader = () => {
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const {
        timestamp,
        setTimestamp,
        isCustomDateRangeOpen,
        setCustomDateRangeOpen,
        isTimeRangeOpen,
        setTimeRangeOpen,
        userAuthTimezone,
        dateRange,
        setDateRange,
    } = useRangeCalendar(params);

    const [sortDirection, setSortDirection] = useState(false);

    const onDateRangeChange = (_, { value }) => {
        setDateRange(value);
    };

    const sortFilter = () => {
        setSortDirection(!sortDirection);
        const query = new URLSearchParams(params);
        const direction = !sortDirection ? "ascending" : "descending";
        query.set("direction", direction);
        const query_string = query.toString();
        navigate(
            `/settings/import-data${query_string ? `?${query_string}` : ""}`
        );
    };

    const [filters, setFilters] = useState({
        module: params.get("module")?.split(","),
        product_interests: params.get("product_interests")?.split(","),
    });

    const onFilter = async (_, { name, value }) => {
        setFilters({
            ...filters,
            [name]: value,
        });
    };

    useEffect(() => {
        const query = new URLSearchParams(params);

        const { module, product_interests } = filters;

        if (module?.length > 0) {
            query.set("module", module.join(","));
        } else {
            query.delete("module");
        }

        if (product_interests?.length > 0) {
            query.set("product_interests", product_interests.join(","));
        } else {
            query.delete("product_interests");
        }

        const query_string = query.toString();
        navigate(
            `/settings/import-data${query_string ? `?${query_string}` : ""}`
        );
    }, [filters, navigate, params]);

    return (
        <Table.Row>
            <SemanticTable.HeaderCell width={3}>
                <span style={{ marginRight: 5 }}>Timestamp</span>

                <TimestampFilters
                    timestamp={timestamp}
                    onToggleTimeUnit={(timestamp) =>
                        onToggleTimeUnit(
                            timestamp,
                            setTimestamp,
                            params,
                            navigate,
                            "/settings/import-data"
                        )
                    }
                    isCustomDateRangeOpen={isCustomDateRangeOpen}
                    onOpenCustomDateRange={() => {
                        setCustomDateRangeOpen(true);
                    }}
                    onCloseCustomDateRange={() => {
                        setCustomDateRangeOpen(false);
                    }}
                    onOpenTimeUnitOptions={() => {
                        setTimeRangeOpen(true);
                    }}
                    onCloseTimeUnitOptions={() => {
                        setTimeRangeOpen(false);
                    }}
                    areTimeUnitOptionsOpen={isTimeRangeOpen}
                    dateRange={dateRange}
                    onDateRangeChange={onDateRangeChange}
                    getData={() =>
                        getData({
                            setCustomDateRangeOpen,
                            dateRange,
                            userAuthTimezone,
                            params,
                            navigate,
                            url: "/settings/import-data",
                        })
                    }
                />

                <SortActions
                    direction={sortDirection}
                    onToggleSort={() => {
                        sortFilter();
                    }}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={3}>
                File Name
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={3}>
                File Description
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={3}>
                <ClearableCheckboxDropdown
                    text="Product Interest(s)"
                    name="product_interests"
                    onChange={(_, { value }) => {
                        onFilter(_, { name: "product_interests", value });
                    }}
                    value={params.get("product_interests")?.split(",")}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={2}>
                <ClearableCheckboxDropdown
                    text="Module"
                    name="module"
                    onChange={(_, { value }) => {
                        onFilter(_, { name: "module", value });
                    }}
                    value={params.get("module")?.split(",")}
                    options={[
                        { key: 0, value: "contacts", text: "Contacts" },
                        { key: 1, value: "leads", text: "Leads" },
                    ]}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={2}>
                Profiles created
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={1}>
                Actions
            </SemanticTable.HeaderCell>
        </Table.Row>
    );
};
