import { FileInput, Notification, STATUS_TYPES } from 'dyl-components';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Form, Input, Segment, Icon } from 'semantic-ui-react';
import FileUtils, { FILE_CATEGORIES } from 'utils/FileUtils';

const ImportSetup = ({
    control,
    onUpload,
    importSetupFields,
    dropzoneRef = React.createRef()
}) => {
    return (
        <Form noValidate>
            <Form.Group>
                <Controller
                    name="file"
                    defaultValue={[]}
                    control={control}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <Form.Field
                            width={6}
                            control={Input}
                            error={error && error.message && {
                                pointing: 'below'
                            }}
                            className='Template__attachments-section'
                        >
                            <Segment style={{width: '100%', padding: 0}} basic>
                            <div>
                                <Icon disabled={value.length > 0} onClick={() => { dropzoneRef.current.open() }} color='blue' name='plus circle' link /> <b>Attach {value.length > 0 && 'more '}files</b>
                            </div>
                            <div className='Template__attachments' style={{marginBottom: 10}}>
                                <FileInput
                                    onChange={(_, { value }) => {
                                        if(value.length > 1){
                                            Notification.alert("Only accepts one .csv file.", STATUS_TYPES.ERROR, true);
                                        } else {
                                            onChange({
                                                target: { name, value }
                                            })
                                            onUpload(value);
                                        }
                                    }}
                                    onRemove={(_, { value }) => { 
                                        onChange({ target: { name, value } }); 
                                    }}
                                    onReject={(rejected) => {
                                        if (rejected.length > 0) {
                                            Notification.alert("Only accepts .csv files.", STATUS_TYPES.ERROR, true);
                                        }
                                    }}
                                    files={value}
                                    name="file"
                                    accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    icon="file csv outline"
                                    size="mini"
                                    dropzoneRef={dropzoneRef}
                                    showIcon
                                    hasBrowse
                                />
                            </div>
                            <i>Remaining: {FileUtils.getRemainingSize(value, FILE_CATEGORIES.IMPORT)} MB </i> {error?.message && `(${error.message})`}
                            </Segment>
                        </Form.Field>
                    )}
                />      
                <Form.Field
                    control='div'
                    width={7}
                >
                    <Controller
                        control={control}
                        name='description'
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <Form.Input
                                label='File Description'
                                name={name}
                                value={value}
                                onChange={(_, { value }) => {
                                    onChange({ target: { name, value } });
                                }}
                                error={error?.message}
                                required
                                placeholder='Enter file description'
                            />
                        )}
                    />
                    <Form.Group widths='equal'>
                        {importSetupFields}
                    </Form.Group>
                </Form.Field>
            </Form.Group>
        </Form>
    )
};

export default ImportSetup;