import React, { useCallback, useEffect, useState } from 'react';
import { Notification, STATUS_TYPES, TableWithHeader } from 'dyl-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BulkActionsProvider from 'shared/context/BulkActionsProvider';
import { useDispatch } from 'react-redux';
import Toolbar from './subcomponents/Toolbar';
import Table from './subcomponents/Table';
import IVRPromptModal from 'shared/modals/IVRPromptModal';
import pbxConfigActions from 'actions/pbx_config';

const LIST_VIEW_URL = "/settings/phone-management/routing/ivr-prompts"

const IVRPrompts = () => {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const dispatch = useDispatch();
    const searchQuery = params.get('search') || '';
    const [search, setSearch] = useState(searchQuery);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const onChangeSearch = (_, { value }) => {
        setSearch(value);
    }
    
    const cancelFunction = () => {
        setSearch("");
        const query = new URLSearchParams(params);
        query.delete('search');
        const query_string = query.toString();
        navigate(`${LIST_VIEW_URL}${query_string ? `?${query_string}` : ''}`,);
    }

    const onSearchSubmit = (value) => {
        const query = new URLSearchParams(params);
        query.set('search', value.trim());
        query.set('page', 1);
        const query_string = query.toString();
        navigate(`${LIST_VIEW_URL}${query_string ? `?${query_string}` : ''}`);
    }

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`${LIST_VIEW_URL}${query_string ? `?${query_string}` : ''}`);
    }

    const onReload = useCallback(() => {
        dispatch(pbxConfigActions.readIvrs(params));
    }, [dispatch, params])

    const onReadIvr = async (id) => {
        await dispatch(pbxConfigActions.getIvr(id));
        setIsEditing(true);
        setIsModalOpen(true);
    }
    
    const onDelete = async (id) => {
        try {
            await dispatch(pbxConfigActions.deleteIvr(id))
            onReload();
            Notification.alert('IVR prompt deleted successfully!', STATUS_TYPES.SUCCESS);
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to delete IVR prompt', STATUS_TYPES.ERROR);
        }
    }

    const onClose = () => {
        setIsEditing(false);
        setIsModalOpen(false);
    }

    useEffect(() => {
        const query = new URLSearchParams(params);
        if (search.trim()) {
            query.set('search', search);
        } else {
            query.delete('search');
        }
        const query_string = query.toString();
        navigate(`${LIST_VIEW_URL}${query_string ? `?${query_string}` : ''}`,);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        onReload();
    }, [onReload])

    return (
        <div className='IvrPrompts'>
            <TableWithHeader
                header={(
                    <Toolbar
                        search={searchQuery}
                        onChangeSearch={onChangeSearch}
                        onSearchSubmit={onSearchSubmit}
                        cancelFunction={cancelFunction}
                        onOpenModal={() => setIsModalOpen(true)}
                    />
                )}
                table={(
                    <Table onPageChange={onPageChange} onRead={onReadIvr} onDelete={onDelete} listViewUrl={LIST_VIEW_URL} />
                )}
            />
            <IVRPromptModal open={isModalOpen} onClose={onClose} onReload={onReload} isEditing={isEditing} />
        </div>
    )
}

export default () => (
    <BulkActionsProvider>
        <IVRPrompts />
    </BulkActionsProvider>
);