import { HTMLPreview } from 'dyl-components';
import React from 'react';
import { Segment } from 'semantic-ui-react';

const WebformSuccessMessage = ({
    form_background_color,
    use_logo,
    logo,
    success_message,

}) => (
    <Segment style={{ backgroundColor: form_background_color }} padded='very'>
        <div>
            {use_logo && (
                <span
                dangerouslySetInnerHTML={{ __html: `<img width="150px" class="WebformPreview__logo WebformPreview__logo--company" src="${logo}" onerror="this.style.display='none'" />`}}
                ></span>
            )}
            <HTMLPreview content={success_message} full padded={false} />
        </div>
    </Segment>
);

export default WebformSuccessMessage;
