export const hostedProfileSettings = [
    {
        settingName: "hostedProfileSaveButtonText",
        settingValue: `Add`,
    },
    {
        settingName: "hostedProfilePageBorderVisible",
        settingValue: `false`,
    },
    {
        settingName: "hostedProfileHeadingBgColor",
        settingValue: `#2B78FF`,
    },
    {
        settingName: "hostedProfileValidationMode",
        settingValue: "testMode",
    },
    {
        settingName: "hostedProfileManageOptions",
        settingValue: "showPayment",
    },
    {
        settingName: "hostedProfileBillingAddressRequired",
        settingValue: "true"
    },
    {
        settingName:
            "hostedProfileIFrameCommunicatorUrl",
        settingValue: `${`http${
                    window.location.host === "localhost:3001" ? "" : "s"
                }://${window.location.host}`}/index.html`,
    },
];
