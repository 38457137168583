import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RecordsTable } from 'dyl-components';

import sequenceTaskActions from 'actions/sequence_tasks';
import sequencesActions from 'actions/sequences';
import Header from './subcomponents/Header';
import Row from './subcomponents/Row';

import './index.scss';

const SequenceReports = () => {
    const [params] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(sequenceTaskActions.read({ ...Object.fromEntries(params) }));
        dispatch(sequencesActions.readShort({ deleted: true, has_contact: true, limit: 2500 }));
        dispatch(sequencesActions.readUsers());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    const [filters, setFilters] = useState({
        workflow_id: params.get('workflow_id')?.split(','),
        user_id: params.get('user_id')?.split(','),
        action: params.get('action')?.split(','),
        status: params.get('status')?.split(','),
        filter: params.get('filter')
    });

    const onFilter = async (_, { name, value }) => {
        setFilters({
            ...filters,
            [name]: value
        });
    }

    useEffect(() => {
        const query = new URLSearchParams(params);

        const { workflow_id, user_id, action, status } = filters;
        if (workflow_id?.length > 0) {
            query.set('workflow_id', workflow_id.join(','));
        } else {
            query.delete('workflow_id');
        }
        if (user_id?.length > 0) {
            query.set('user_id', user_id.join(','));
        } else {
            query.delete('user_id');
        }
        if (action?.length > 0) {
            query.set('action', action.join(','));
        } else {
            query.delete('action');
        }
        if (status?.length > 0) {
            query.set('status', status.join(','));
        } else {
            query.delete('status');
        }

        const { filter } = filters;
        if (filter?.length > 0) {
            query.set('filter', filter);
        } else {
            query.delete('filter');
        }

        const query_string = query.toString();
        navigate(`/settings/sequence-reports${query_string ? `?${query_string}` : ''}`,);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`/settings/sequence-reports${query_string ? `?${query_string}` : ''}`);
    }

    const { sequenceReportsReducer, isReadingSequencesShort } = useSelector(state => ({
        sequenceReportsReducer: state.sequence_tasks,
        isReadingSequencesShort: state.sequences.isReadingSequencesShort
    }));
    const { sequenceTasks = [], isReadingSequenceTasks, sequence_task_count } = sequenceReportsReducer;

    return (
        <div className='SequenceReportsPage'>
            <RecordsTable
                tableBodyClassName='SequenceReportsPage__Table'
                isTableLoading={isReadingSequenceTasks || isReadingSequencesShort}
                isSearchResultEmpty={false}
                isTableEmpty={!sequence_task_count || sequence_task_count === 0}
                TableHeader={<Header onFilter={onFilter} />}
                TableRows={sequenceTasks.map(sequenceTask => (
                    <Row
                        key={sequenceTask.id}
                        sequenceTask={sequenceTask}
                    />
                ))}
                recordsCount={sequence_task_count}
                activePage={params.get('page')}
                onPageChange={onPageChange}
                recordsName='Sequence'
                emptySubtitle={<span></span>}
            />
        </div>
    );
}

export default SequenceReports;
