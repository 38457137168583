import React, { useEffect, useState } from 'react';
import { Table, ButtonLink } from 'dyl-components';
import { Checkbox, Icon, Loader } from 'semantic-ui-react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { PhoneUtil } from 'utils';

const UserExtensionsSubrow = ({ extensions, parentId, onSave, isLoading }) => {
    const [editMode, setEditMode] = useState(false);

    const initialValues = () => {
        return {
            extensions: extensions.map(({id, active}) => ({id, active})) || [],
            id: parentId
        };
    }

    const { control, reset, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues: initialValues(),
    })

    const { fields } = useFieldArray({
        control,
        name: "extensions"
    })

    const onCancel = () => {
        setEditMode(false);
        reset(initialValues());
    }

    useEffect(() => {
        reset({
            extensions: extensions.map(({id, active}) => ({id, active})) || [],
            id: parentId
        });
    }, [extensions, reset, parentId])

    return (
        <Table.CollapsibleRowContent indented nested colSpan="6">
                <Table.Cell colSpan="5">
                    {isLoading ? (
                        <Loader active inline />
                    ) : (
                        <>
                            {!editMode ? (
                                <ButtonLink onClick={() => setEditMode(true)}>
                                    <Icon className='fa-solid fa-pencil' />
                                    Edit All
                                </ButtonLink>
                            ) : (
                                <>
                                    <ButtonLink className="UserExtensions__cancelButton" onClick={() => onCancel()}>
                                        <Icon className='fa-solid fa-x' />
                                        Cancel
                                    </ButtonLink>
                                    <Icon className='fa-solid fa-pipe UserExtensions__buttonSeparator' />
                                    <ButtonLink onClick={handleSubmit(onSave)}>
                                        <Icon className='fa-solid fa-check' />
                                        Save
                                    </ButtonLink>
                                </>
                            )}
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell width={6}>
                                            <Icon className="fa-solid fa-phone-volume" /> Ring Group / <Icon className="fa-solid fa-user-clock" /> Call Queues
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={6}>Internal Ext.</Table.HeaderCell>
                                        <Table.HeaderCell width={6}>Status</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {fields?.map(({active}, index) => {
                                        const { type, label, alias } = extensions[index];
                                        return (
                                            <Table.Row>
                                                <Table.Cell>
                                                    <Icon className={type === "ring_group" ? "fa-solid fa-phone-volume" : "fa-solid fa-user-clock"} />
                                                    <Link to={`${PhoneUtil.DESTINATION_TYPES_URLS["ring_group"]}?type=${type}&search=${label}`}>{label}</Link>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {!!alias ? `Ext. ${alias}` : ""}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {editMode ? (
                                                        <Controller
                                                            control={control}
                                                            name={`extensions[${index}].active`}
                                                            render={({ field: { onChange, value, name } }) =>
                                                                <Checkbox
                                                                    name={name}
                                                                    checked={value}
                                                                    label={value ? "Active" : "Paused"}
                                                                    toggle
                                                                    onChange={(_, {checked}) => {
                                                                        onChange({ target: { name, value: checked } })
                                                                    }}
                                                                />
                                                            } 
                                                        />
                                                    ) : (
                                                        active ? "Active" : "Paused"
                                                    )}
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                                </Table.Body>
                            </Table>
                        </>
                    )}
                </Table.Cell>
        </Table.CollapsibleRowContent>
    )
}

export default UserExtensionsSubrow