import { Modal, generateResolver, yup, ToggableDropdown, Notification, STATUS_TYPES, ConfirmationPrompt } from 'dyl-components';
import React, { useEffect, useState, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Icon, Popup, Modal as SemanticModal } from 'semantic-ui-react';
import groupsActions from 'actions/groups';
import { useParams } from 'react-router-dom';
import "./GroupContactBulkModal.scss";

const GroupContactBulkModal = ({ open, onClose, numberSelectedContacts = 0, selectedContacts, reloadContacts, areContactsInAllPagesSelected, parent }) => {
    const [search, setSearch] = useState('');
    const [groupOptions, setGroupOptions] = useState([]);
    const [isPromptOpen, setIsPromptOpen] = useState(false);
    const { group_id } = useParams();

    const dispatch = useDispatch();

    const { groups, subgroups, isReadingGroups } = useSelector((state) => {
        return {
            groups: state.groups.groups,
            subgroups: state.groups.subgroups,
            contactGroups: state.groups.contactGroups,
            contactGroupsCount: state.groups.contactGroupsCount,
            isReadingGroups: state.groups.isReadingGroups,
            isReadingContactGroups: state.groups.isReadingContactGroups,
            isAddingContact: state.groups.isAddingContact
        }
    });

    const { control, reset, watch, formState: {isDirty, isValid}, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues: {
            type: ''
        },
        resolver: generateResolver({
            type: yup.string().required('This field is required'),
            group: yup.mixed().when('type', {
                is: 'move_contacts',
                then: schema => schema.required('This field is required')
            }),
        })
    });

    const watchedType = watch("type");

    const close = () => {
        reset();
        onClose();
    }

    const bulkActionOptions = [
        {
            key: 'move_contacts', value: 'move_contacts', text: 'Move Contacts', content: (
                <>
                    <Icon className={`fa-solid fa-folder-arrow-down`} />
                    Move Contacts
                </>
            )
        },
        {
            key: 'delete_contacts', value: 'delete_contacts', text: `Remove From ${parent ? "Subgroup" : "Group"}`, content: (
                <>
                    <Icon className={`fa-solid fa-trash`} />
                    {`Remove From ${parent ? "Subgroup" : "Group"}`}
                </>
            )
        },
    ];

    const handleSearchChange = (e, { searchQuery }) => {
        setSearch(searchQuery);
    }
    
    const getSubgroups = async (parent_label_id) => {
        await dispatch(groupsActions.readSubgroups({parent_label_id}));
    }

    const getGroups = useCallback(async (search) => {
        await dispatch(groupsActions.readGroups({search, limit: 500}));
    }, [dispatch])

    const onMove = async (contact_id, {type: action, group: {value: new_group_id}}) => {
        try {
            await dispatch(groupsActions.bulkGroupContacts({group_id, action, contact_id, new_group_id}));
            close();
            reloadContacts();
        Notification.alert(`Successfully moved the contacts!`, STATUS_TYPES.SUCCESS);
        } catch (error) {
            console.log(error);
            Notification.alert(`Failed to move the contacts`, STATUS_TYPES.ERROR);
        }
    }

    const onRemove = async (contact_id) => {
        try {
            if (group_id !== "hotlist") {
                await dispatch(groupsActions.bulkGroupContacts({group_id, action: "delete_contacts", contact_id}));
            } else {
                await dispatch(groupsActions.bulkHotlistContacts({action: "delete_contacts", contact_id}));
            }
            close();
            reloadContacts();
            setIsPromptOpen(false);
            Notification.alert(`Successfully removed the contacts!`, STATUS_TYPES.SUCCESS);
        } catch (error) {
            console.log(error);
            Notification.alert(`Failed to remove the contacts`, STATUS_TYPES.ERROR);
        }
    }

    const onSave = async (data) => {
        const {type} = data;

        if (type === "move_contacts") {
            onMove(areContactsInAllPagesSelected ? null : selectedContacts, data)
        } else {
            setIsPromptOpen(true);
        }
    }

    useEffect(() => {
        getGroups(search);
    }, [search, getGroups])

    useEffect(() => {
        const groupOptionsAux = groups.map((group) => {
            let subgroupsAux = [];
            if (subgroups[group.id] && subgroups[group.id].length > 0) {
                subgroupsAux = subgroups[group.id].map((subgroup) => ({text: subgroup.name, key: subgroup.id, value: subgroup.id}));
            }
            return {text: group.name, key: group.id, value: group.id, hasOptions: group.sub_labels, options: subgroupsAux, parent: group.parent_label_name};
        })
        setGroupOptions(groupOptionsAux);
    }, [groups, subgroups])

    return (
        <>
            <Modal open={open} onClose={close} className="GroupContactBulkModal">
                <Modal.Header>
                    Bulk Action <div style={{ float: 'right', marginRight: '1em' }}>
                        <small>{numberSelectedContacts} selected</small>
                        <Popup
                            trigger={<Icon size='small' style={{ marginLeft: '1em' }} floated='right' className='fas fa-circle-info' color='primary' />}
                            content={`Duplicates will be ignored`}
                            inverted
                            position='left center'
                            wide
                        />
                    </div>
                </Modal.Header>
                <Modal.Content>
                    <Form noValidate>
                        <Controller
                            name='type'
                            control={control}
                            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                <Form.Select
                                    {...value ? { text: bulkActionOptions.find(action => action.value === value)?.content } : {}}
                                    label='Bulk Action Type'
                                    placeholder='Select bulk action type'
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    value={value}
                                    options={bulkActionOptions}
                                    required
                                    error={error?.message}
                                    width={8}
                                    selectOnBlur={false}
                                />
                            )}
                        />
                        {watchedType === "move_contacts" && (
                            <Controller
                                name='group'
                                control={control}
                                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                    <Form.Field
                                        control={ToggableDropdown}
                                        value={value}
                                        nested_options={groupOptions}
                                        onChange={( _, { value }) => {
                                            onChange({ target: { name, value }});
                                        }}
                                        placeholder='Search Group/Subgroup Name'
                                        label='Group/Subgroup Name'
                                        onHoverParent={getSubgroups}
                                        loading={isReadingGroups}
                                        onSearchChange={handleSearchChange}
                                        searchValue={search}
                                        resetSearchValue={() => setSearch("")}
                                        width={8}
                                        selection
                                        required
                                    />
                                )}
                            />
                        )}
                    </Form>
                </Modal.Content>
                <Modal.Actions
                    hasSaveButton
                    saveDisabled={!isDirty || !isValid}
                    onSave={handleSubmit(onSave)}
                />
            </Modal>
            <SemanticModal open={isPromptOpen} style={{ padding: 30, width: 350 }}>
                <ConfirmationPrompt 
                    header={<div style={{ paddingTop: '1em' }}>Are you sure?</div>}
                    subheader={<div>Selected contacts will be removed.</div>}
                    onConfirm={() => onRemove(areContactsInAllPagesSelected ? null : selectedContacts)}
                    onCancel={() => setIsPromptOpen(false)}
                />
            </SemanticModal>
        </>
    )
}

export default GroupContactBulkModal;
