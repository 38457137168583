import React, { useState } from 'react';
import { DividingHeader, generateResolver } from 'dyl-components';
import { Form, Grid, Icon, Image } from 'semantic-ui-react';
import './index.scss';
import { UploadPhotoModal } from 'shared/modals';
import { useForm } from 'react-hook-form';
import FileUtils, { FILE_CATEGORIES } from 'utils/FileUtils';


const Logo = ({ logo, onRemoveLogo, uploadedNewLogo, onUploadLogo, fileInputRef = React.createRef() }) => {
    const [isUploadPhotoModalOpen, setIsUploadPhotoModalOpen] = useState(false);
    const [tempNewLogo, setTempNewLogo] = useState(null);
    const [checkImage, setImageCheck] = useState(false);

    const { control, formState: { isValid }, getValues, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            attachments: []
        },
        resolver: generateResolver({
            attachments: FileUtils.getFilesizeValidator(FILE_CATEGORIES.PROFILE)
        })
    });

    
    const showUploadModal = () => {
        setIsUploadPhotoModalOpen(true);
        setImageCheck(true);
    }

    const onFileChange = () => {
        setTempNewLogo(getValues("attachments").length > 0 ? getValues("attachments")[0] : null);
    }

    const hideUploadModal = () => {
        setIsUploadPhotoModalOpen(false);
        setTempNewLogo(null);
        reset();
    }

    const removeLogo = async () => {
        await onRemoveLogo();
        setTempNewLogo(null);
    }

    const getLogo = () => {
        if (uploadedNewLogo) {
            return URL.createObjectURL(new Blob([logo]));
        } else {
            return logo;
        }
    }

    const getTempNewLogo = () => {
        return tempNewLogo && URL.createObjectURL(tempNewLogo);
    }

    const onUpload = async () => {
        await onUploadLogo(tempNewLogo);
        setIsUploadPhotoModalOpen(false);
    }

    return (
        <div className='CompanyLogo'>
            <DividingHeader content='Profile' compact noline />
            <Grid>
                <Grid.Row>
                    <Grid.Column width={4}>
                        <Form.Field label={<React.Fragment><Icon name='image' /> <b>Logo</b> </React.Fragment>} />
                        {logo ?
                            <div className='CompanyCurrentLogo'>
                                <Image src={getLogo()} size='small' className='CompanyCurrentLogo__logo' />
                                <div class='CompanyCurrentLogo__overlay'>
                                    <Icon name='camera' size='large' link onClick={showUploadModal} />
                                    <Icon name='trash' size='large' link onClick={removeLogo} />
                                </div>
                            </div>
                            :
                            <div className='CompanyUploadLogo' onClick={showUploadModal}>
                                <Icon name='camera' color='blue' size='large' />
                            </div>}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <UploadPhotoModal
                newImage={getTempNewLogo()}
                oldImage={getLogo()}
                onClose={hideUploadModal}
                open={isUploadPhotoModalOpen}
                fileChange={onFileChange}
                onUpload={onUpload}
                circular={false}
                ref={fileInputRef}
                control={control}
                isValid={isValid}
                getValues={getValues}
                checkImage={checkImage}
                setImageCheck={setImageCheck}

            />
        </div>
    );
};

export default Logo;
