import React, { useState, useEffect, useCallback } from "react";
import { RecordsTable, Button, Notification, STATUS_TYPES } from "dyl-components";
import { useDispatch, useSelector } from "react-redux";
import Header from "./subcomponents/Header";
import Row from "./subcomponents/Row";
import { SearchBar } from "dyl-components";
import { Icon } from "semantic-ui-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BulkActionsProvider from "shared/context/BulkActionsProvider";
import pbxConfigActions from "actions/pbx_config";
import "../index.scss";
import CompanyExtensionModal from "shared/modals/CompanyExtensionModal";

const COMPANY_URL = "/settings/phone-management/extensions/company";

const Company = () => {
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const searchQuery = params.get("search") || "";
    const [search, setSearch] = useState(searchQuery);

    const { companyExtensions, isReadingCompanyExtensions } = useSelector(
        (state) => state.pbx_config
    );

    const onChangeSearch = (_, { value }) => {
        setSearch(value);
    }

    const onSearchSubmit = (value) => {
        const query = new URLSearchParams(params);
        query.set('search', value.trim());
        query.set('page', 1);
        const query_string = query.toString();
        navigate(`${COMPANY_URL}${query_string ? `?${query_string}` : ''}`);
    }

    const cancelFunction = () => {
        setSearch("");
        const query = new URLSearchParams(params);
        query.delete('search');
        const query_string = query.toString();
        navigate(`${COMPANY_URL}${query_string ? `?${query_string}` : ''}`,);
    }

    const [typeCounts, setTypeCounts] = useState({});
    const [filters, setFilters] = useState({
        type: params.get("type")?.split(","),
        status: params.get("order")?.split(","),
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState("");

    const onReload = useCallback(() => {
        dispatch(pbxConfigActions.readCompanyExtensions(params))
    }, [dispatch, params])

    useEffect(() => {
        onReload();
    }, [onReload]);

    useEffect(() => {
        const query = new URLSearchParams(params);
        const { type, order } = filters;

        if (search.trim()) {
            query.set('search', search);
        } else {
            query.delete('search');
        }

        if (type?.length > 0) {
            query.set("type", type.join(","));
        } else {
            query.delete("type");
        }

        if (order) {
            query.set("order", order);
        } else {
            query.delete("order");
        }
        
        const query_string = query.toString();
        navigate(
            `${COMPANY_URL}${
                query_string ? `?${query_string}` : ""
            }`
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    useEffect(() => {
        const counts = {
            conference_room: companyExtensions?.conference_count,
            parking_lot: companyExtensions?.park_count
        }
        setTypeCounts(counts || {});
    }, [companyExtensions]);

    const onFilter = async (_, { name, value }) => {
        setFilters({
            ...filters,
            [name]: value,
        });
    };

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`${COMPANY_URL}${query_string ? `?${query_string}` : ''}`);
    };

    const onOpenModal = () => {
        setIsModalOpen(true);
    }

    const onCloseModal = () => {
        setIsEditing("");
        setIsModalOpen(false);
    }

    const onReadCompanyExtension = async (id, type) => {
        switch (type) {
            case "company_voicemail": {
                await dispatch(pbxConfigActions.getCompanyVoicemail(id))
                setIsEditing("company_voicemail");
                break;
            }
            case "conference_room": {
                await dispatch(pbxConfigActions.getConferenceRoom(id))
                setIsEditing("conference_room");
                break;
            }
            case "parking_lot": {
                await dispatch(pbxConfigActions.getParkingLot(id))
                setIsEditing("parking_lot");
                break;
            }
            default:
                break;
        }
        setIsModalOpen(true);
    }

    const onRemove = async (id, type) => {
        try {
            switch (type) {
                case "company_voicemail": {
                    await dispatch(pbxConfigActions.deleteCompanyVoicemail(id))
                    break;
                }
                case "conference_room": {
                    await dispatch(pbxConfigActions.deleteConferenceRoom(id))
                    break;
                }
                case "parking_lot": {
                    await dispatch(pbxConfigActions.deleteParkingLot(id))
                    break;
                }
                default:
                    break;
            }
            onReload();
            Notification.alert('Company Extension deleted successfully!', STATUS_TYPES.SUCCESS);
        } catch (error) {
            console.log(error);
            Notification.alert('Failed to delete Company Extension', STATUS_TYPES.ERROR);
        }
    }

    return (
        <div className="extensionCompanyTable">
            <div className="Extensions">
                <SearchBar
                    isSearching={false}
                    search={searchQuery}
                    onChange={onChangeSearch}
                    searchFcn={onSearchSubmit}
                    cancelFcn={cancelFunction}
                    placeholder={"Search by name or extension"}
                />
                <div className="Extensions__ButtonGroup">
                    <Button className="Extensions__NewUserButton" disabled>
                        Bulk Actions
                    </Button>
                    <Button onClick={onOpenModal} className="Extensions__NewUserButton">
                        <Icon name="plus" /> New
                    </Button>
                </div>
            </div>

            <RecordsTable
                tableBodyClassName="ExtensionsCompanyTable"
                isTableLoading={isReadingCompanyExtensions}
                isSearchResultEmpty={
                    !companyExtensions.count && params.get("search")
                }
                isTableEmpty={!companyExtensions.count && !params.get("search")}
                TableHeader={
                    <Header
                        extensions={companyExtensions}
                        onFilter={onFilter}
                    />
                }
                TableRows={companyExtensions.data?.map((extension) => (
                    <Row extension={extension} typeCounts={typeCounts} onReadCompanyExtension={onReadCompanyExtension} onRemove={onRemove} />
                ))}
                recordsCount={companyExtensions.count}
                activePage={params.get("page")}
                onPageChange={onPageChange}
                recordsName="Company Extension"
                emptySubtitle=" "
                emptyListTitle={"No Company Extensions Created"}
            />
            <CompanyExtensionModal open={isModalOpen} onClose={onCloseModal} onReload={onReload} isEditing={isEditing} />
        </div>
    );
};

export default () => (
    <BulkActionsProvider>
        <Company />
    </BulkActionsProvider>
);
