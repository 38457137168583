const initialState = {
    contacts: [],
    recent_contacts: [],
    isReadingContacts: false,
    isReadingRecentContacts: false,
    isCreating: false,
    queryParameters: {
        page: 1,
        limit: 300
    },
    filterQueryParameters: {

    },
    allMarked: false,
    allPagesMarked: false,
    bulkAction: null,
    contacts_searched: [],
    isSearchingContacts: false,
    contacts_searched_count: 0,

    islookingupContact: false,
    contact_lookup: null
};

export default initialState;
