import React, { useEffect, useState, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Header } from 'semantic-ui-react';
import groupsActions from 'actions/groups';
import { Modal, generateResolver, yup, Notification, STATUS_TYPES } from 'dyl-components';

const MoveSubgroupModal = ({ open, onClose, parent, subgroup, reloadSubgroups }) => {
    const [groupOptions, setGroupOptions] = useState([]);
    const user_id = useSelector((state) => state.auth.user_id);
    const dispatch = useDispatch();

    const { control, reset, formState: {isValid, isDirty}, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues: {
            group: ''
        },
        resolver: generateResolver({
            group: yup.mixed().required('This field is required')
        })
    });

    const close = () => {
        reset();
        onClose();
    }

    const getGroups = useCallback(() => {
        const { user_id } = parent
        dispatch(groupsActions.readGroupOptions({type: user_id ? "private" : "shared", limit: 1000})).then((response) => {
            const { data } = response;
            const groupOptionsAux = data.map((group) => ({text: group.name, value: group.id, key: group.id}))
                .filter((group) => parent.id !== group.key);
            setGroupOptions(groupOptionsAux);
        });
    }, [dispatch, parent]);

    const onSave = async (data) => {
        const { group } = data;
        const { id } = subgroup;
        try {
            await dispatch(groupsActions.moveSubgroup(id, {user_id, group}))
            Notification.alert('Successfully moved subgroup!', STATUS_TYPES.SUCCESS, true);
            close();
            reloadSubgroups(group);
        } catch (error) {
            console.log(error);
            Notification.alert('Failed to move subgroup!', STATUS_TYPES.SUCCESS, true);
        }
    }

    useEffect(() => {
        getGroups();
    }, [getGroups])

    return (
        <Modal open={open} onClose={close}>
            <Modal.Header>
                Move Subgroup
            </Modal.Header>
            <Modal.Content style={{minHeight: 350}}>
                <Header as='h4'>{`Move '${subgroup.name}' from '${parent.name}' to...`}</Header>
                <Form noValidate>
                    <Controller
                        name='group'
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <Form.Select
                                placeholder="Select Group"
                                label="Group Name"
                                name={name}
                                value={value}
                                width={8}
                                options={groupOptions}
                                onChange={(_, { value }) => {
                                    onChange({ target: { name, value } });
                                }}
                                required
                                search
                            />
                        )}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions
                hasSaveButton
                saveDisabled={!isValid || !isDirty}
                onSave={handleSubmit(onSave)}
            />
        </Modal>
    )
}

export default MoveSubgroupModal;
