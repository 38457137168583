import React from "react";
import { Form, Header, Segment } from "semantic-ui-react";
import UserSearchField from "shared/forms/UserSearchField";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import {
    ContactCard,
    DYLAvatar,
    Notification,
    OnHoverOnClick,
    STATUS_TYPES,
} from "dyl-components";
import TableDropdown from "shared/TableDropdown";
import assignmentAction from "actions/assignment";
import {
    SearchResultsRender,
    SelectionRender,
} from "shared/Sequence/SettingsForm";
import record_assignment from "actions/record_assignment";
import { useNavigate } from "react-router-dom";

export const RecordInfo = ({ created, last_modified, assignees, record_id, refresh }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { owned_by, co_owned_by, teams, territories } = assignees || {};

    const isUpdating = useSelector(state => state.record_assignment.isAssigningOwner || state.record_assignment.isAssigningTeamsTerritories);
    
    const defaultValues = {
        owned_by: owned_by?.id || null,
        co_owned_by: co_owned_by?.id || null,
        teams_territories: {
            teams: teams?.data || [],
            territories: territories || [],
        },
    };    

    const { control, getValues, resetField, formState: { isValid, isDirty } } = useForm({
        mode: "onChange",
        defaultValues: defaultValues,
    });

    const onSearchAction = async (search) => {
        if (search?.trim()) {
            const { teams, territories } = await dispatch(
                assignmentAction.readTeamsTerritories({ search: search })
            );
            return {
                data: { teams: teams.data, territories: territories.data },
                count: (teams?.count || 0) + (territories?.count || 0),
            };
        }
    };

    const onFocusAction = async () => {
        const { teams, territories } = await dispatch(
            assignmentAction.readTeamsTerritories({})
        );
        return {
            data: { teams: teams.data, territories: territories.data },
            count: (teams?.count || 0) + (territories?.count || 0),
        };
    };

    const onChangeSelect = async (_, { name, value }) => {
        if (value !== defaultValues[name]) {
            const isCoOwner = name === "co_owned_by";
            const payload = {owner_id: value, coowner: isCoOwner};
            try {
                await dispatch(record_assignment.assignOwners(record_id, payload));
                Notification.alert(
                    `Successfully updated ${
                        name === "owned_by" ? "owner" : "co-owner"
                    }`,
                    STATUS_TYPES.SUCCESS
                );
                if (refresh) {
                    refresh();
                }
            } catch (e) {
                Notification.alert(
                    `Failed to update ${
                        name === "owned_by" ? "owner" : "co-owner"
                    }`,
                    STATUS_TYPES.ERROR
                );
            }
        }
        else {
            resetField(name)
        }
    };

    const onChangeTeamsAndTerritories = async () => {
        if (isValid && isDirty) {
            const value = getValues().teams_territories
            const formattedTeams = value.teams.map((item) => {return item.id})
            const formattedTerritories = value.territories.map((item) => {return item.id})
            const payload = {teams: formattedTeams, territories: formattedTerritories};

            try {
                await dispatch(record_assignment.assignTeamsAndTerritories(record_id, payload))
                Notification.alert(
                    `Successfully updated teams and territories`,
                    STATUS_TYPES.SUCCESS
                );
                if (refresh) {
                    refresh();
                }
            } catch (e) {
                Notification.alert(
                    `Failed to update teams and territories`,
                    STATUS_TYPES.ERROR
                );
            }
        }
    }


    const onRemoveUser = async (_, { name }) => {        
        const isCoOwner = name === "co_owned_by";
        const payload = {coowner: isCoOwner};
        try {
            await dispatch(record_assignment.assignOwners(record_id, payload));
            Notification.alert(
                `Successfully removed ${
                    name === "owned_by" ? "owner" : "co-owner"
                }`,
                STATUS_TYPES.SUCCESS
            );
            if (refresh) {
                refresh();
            }
        } catch (e) {
            Notification.alert(
                `Failed to remove ${
                    name === "owned_by" ? "owner" : "co-owner"
                }`,
                STATUS_TYPES.ERROR
            );
        }

    };

    const clickContent = (owned_or_co) => {
        let itemValue = getValues(owned_or_co);
        return (
            <Segment loading={isUpdating} basic style={{ width: "300px", border: "none", padding: 0 }}>
                <Controller
                    name={owned_or_co}
                    control={control}
                    render={({ field: { name, value } }) => (
                        <Form.Field
                            name={name}
                            value={value}
                            width={10}
                            control={UserSearchField}
                            excluded_users={[value]}
                            onBlur
                            onChange={onChangeSelect}
                            onRemoveOneUser = {onRemoveUser}
                            ownedOrCoOwned = {owned_or_co}
                            placeholder="Select User"
                            one_user_display
                            display_selected_user
                            selected={itemValue === null ? [] : [itemValue]}
                        />
                    )}
                />
            </Segment>
        );
    };

    const hoverContent = (owned_or_co) => {
        const itemValue =
            owned_or_co === "owned_by" ? owned_by : co_owned_by;
        if (!itemValue) {
            return <div> No User Selected </div>;
        } else {
            const userId = itemValue?.id;
            return (
                <ContactCard
                    userInfo={
                        owned_or_co === "owned_by"
                            ? owned_by
                            : co_owned_by
                    }
                    toNavigate={() =>
                        navigate(`/settings/users/${userId}/general`)
                    }
                />
            );
        }
    };

    return (
        <Form size="small">
            <Header as="h5" color="primary">
                Assignment
            </Header>
            <Form.Group widths="equal">
                <Controller
                    name="teams_territories"
                    control={control}
                    render={({
                        field: { name, value, onChange },
                        fieldState: { error },
                    }) => (
                        <Form.Field
                            width={7}
                            control={TableDropdown}
                            label="Territories and Teams"
                            isSelectionVisible={
                                value.territories.length > 0 ||
                                value.teams.length > 0
                            }
                            value={value}
                            onChange={(_, newValue) => {
                                onChange({ target: { name, value: newValue } });
                            }}
                            SelectionRender={SelectionRender}
                            SearchResultsRender={SearchResultsRender}
                            onSearchAction={onSearchAction}
                            onFocusAction={onFocusAction}
                            searchPlaceholder="Search territories and teams"
                            errorMessage={error && error.message}
                            onChangeTeamsAndTerritories={onChangeTeamsAndTerritories}
                            isSmall
                        />
                    )}
                />
            </Form.Group>
            <Form.Group widths="equal">
                <Form.Field control="span" label="Owned By">
                    <OnHoverOnClick
                        hoverContent={hoverContent("owned_by")}
                        clickContent={clickContent("owned_by")}
                        trigger={owned_by ?                 
                            <DYLAvatar 
                            name={owned_by.first_name + " " + owned_by.last_name} 
                            disabled
                            size='45px'/>
                            : undefined}
                    />
                </Form.Field>
                <Form.Field control="span" label="Co-Owned By">
                    <OnHoverOnClick
                        position={"bottom right"}
                        hoverContent={hoverContent("co_owned_by")}
                        clickContent={clickContent("co_owned_by")}
                        trigger={co_owned_by ? <DYLAvatar 
                            name={co_owned_by.first_name + " " + co_owned_by.last_name} 
                            disabled
                            size='45px'/> : undefined}
                    />
                </Form.Field>
            </Form.Group>
            <Header as="h5" color="primary">
                Account Info
            </Header>
            <Form.Group widths="equal">
                <Form.Field label="Created" control="span">
                    {created}
                </Form.Field>
                <Form.Field control="span" label="Last Modified">
                    {last_modified}
                </Form.Field>
            </Form.Group>
        </Form>
    );
};
