import { Button, Modal } from "dyl-components";
import { Checkbox, Grid } from "semantic-ui-react";
import SubscriptionsTable from "./SubscriptionsTable";
import { BulkActionsContext } from "shared/context/BulkActionsProvider";
import SelectAllCheckbox from "shared/SelectAllCheckbox";
import { useSelector } from "react-redux";
import { useContext } from "react";

const RowCheckboxComponent = ({ id, disabled }) => {
    const [selectedItems, setSelectedItems] = useContext(BulkActionsContext);
    const isSelected = (id) => {
        return selectedItems.includes(id);
    };

    const onToggleItem = (subscription_id) => {
        const newSelectedItems = isSelected(subscription_id)
            ? selectedItems.filter((id) => subscription_id !== id)
            : [...selectedItems, subscription_id];
        setSelectedItems(newSelectedItems);
    };

    return (
        <Checkbox
            disabled={disabled}
            checked={isSelected(id)}
            onChange={() => {
                onToggleItem(id);
            }}
        />
    );
};

const EditSubscriptions = ({ open, onClose }) => {
    const { subscriptions } = useSelector((state) => ({
        subscriptions: state.subscription.products,
    }));

    const [selectedItems,setSelectedItems] = useContext(BulkActionsContext);
    const itemsThatCanBeRemoved = subscriptions.filter(subscription => !subscription.user);
    const isNotAllowedToRemove = selectedItems.length <= 0 || itemsThatCanBeRemoved.length === selectedItems.length;
    return (
        <Modal open={open} onClose={() => {
            onClose();
            setSelectedItems([]);
        }}>
            <Modal.Header>
                <Grid>
                    <Grid.Column width={4}>Edit Products</Grid.Column>
                    <Grid.Column width={4} style={{ marginRight: "1em" }} textAlign="right" floated="right">
                        {selectedItems.length} selected
                    </Grid.Column>
                </Grid>
            </Modal.Header>
            <Modal.Content>
                <SubscriptionsTable
                    selectAllCheckbox={
                        <SelectAllCheckbox
                            count={itemsThatCanBeRemoved.length}
                            data={itemsThatCanBeRemoved}
                            idProperty={"subscription_id"}
                            type={"Subscriptions"}
                        />
                    }
                    RowCheckboxComponent={RowCheckboxComponent}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button disabled={isNotAllowedToRemove} color='primary'>Remove and Save</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default EditSubscriptions;
