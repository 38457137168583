import { Grid, Header, Segment, Statistic, StatisticLabel } from "semantic-ui-react";

const PlanDetails = () => {
    return (
        <Segment>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Header color="primary">Plan Details</Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns="equal">
                    <Grid.Column>
                        <Statistic>
                            <StatisticLabel>Current Plan</StatisticLabel>
                        </Statistic>
                    </Grid.Column>
                    <Grid.Column>
                        <Statistic>
                            <StatisticLabel>Billed</StatisticLabel>
                        </Statistic>
                    </Grid.Column>
                    <Grid.Column>
                        <Statistic>
                            <StatisticLabel>Renewal Amount</StatisticLabel>
                        </Statistic>
                    </Grid.Column>
                    <Grid.Column>
                        <Statistic>
                            <StatisticLabel>Next Invoice</StatisticLabel>
                        </Statistic>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    )
}

export default PlanDetails;
