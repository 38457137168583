import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Header } from 'semantic-ui-react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { TableWithHeader } from 'dyl-components';

import CampaignToolbar from './subcomponents/CampaignsToolbar';
import CampaignsTable from './subcomponents/CampaignsTable';

import campaignsActions from 'actions/campaigns';

import './index.scss';

import {
    KEY_CREATED_ON, KEY_NAME, KEY_REACHED,
    KEY_CREATED_BY, KEY_TYPE, KEY_UNREACHED,
    KEY_LAST_MODIFIED_ON, KEY_CATEGORY_SUBCATEGORY, KEY_OWNER,
    KEY_LAST_MODIFIED_BY, KEY_STATUS, KEY_ACTIONS,
    KEY_START_END_DATE, KEY_SHARED,
    KEY_ID, KEY_TOTAL_CONTACTS, KEY_CAMPAIGN_CONVERSION
} from './subcomponents/constants';
import CampaignsContextProvider from './subcomponents/CampaignsContextProvider';

const Campaigns = () => {
    const dispatch = useDispatch();
    const [params] = useSearchParams();

    const searchQuery = params.get('search') || '';
    const [search, setSearch] = useState(searchQuery);

    const navigate = useNavigate();

    const onChangeSearch = (_, { value }) => {
        setSearch(value);
    }

    const cancelFunction = () => {
        setSearch("");
        const query = new URLSearchParams(params);
        query.delete('search');
        const query_string = query.toString();
        navigate(`/campaigns${query_string ? `?${query_string}` : ''}`,);
    };

    const columnDisplayOptions = [
        KEY_CREATED_ON, KEY_NAME, KEY_REACHED,
        KEY_CREATED_BY, KEY_TYPE, KEY_UNREACHED,
        KEY_LAST_MODIFIED_ON, KEY_CATEGORY_SUBCATEGORY, KEY_OWNER,
        KEY_LAST_MODIFIED_BY, KEY_STATUS, KEY_ACTIONS,
        KEY_START_END_DATE, KEY_SHARED,
        KEY_ID, KEY_TOTAL_CONTACTS, KEY_CAMPAIGN_CONVERSION
    ];

    const defaultColumnsDisplayed = [KEY_CREATED_ON, KEY_START_END_DATE, KEY_ID, KEY_NAME, KEY_TYPE, KEY_CAMPAIGN_CONVERSION, KEY_STATUS, KEY_SHARED, KEY_OWNER, KEY_ACTIONS];
    const columnsParameter = params.get('columns')?.split(',')?.filter(column => columnDisplayOptions.includes(column)) || [];
    const columns = columnsParameter.length ? columnsParameter : defaultColumnsDisplayed;

    const readCampaigns = () => {
        let columns = (params.get('columns')?.split(',') || defaultColumnsDisplayed).filter(
            column =>
                column !== KEY_CREATED_ON &&
                column !== KEY_START_END_DATE &&
                column !== KEY_ID &&
                column !== KEY_NAME &&
                column !== KEY_TYPE &&
                column !== KEY_CAMPAIGN_CONVERSION &&
                column !== KEY_STATUS &&
                column !== KEY_SHARED &&
                column !== KEY_REACHED &&
                column !== KEY_UNREACHED &&
                column !== KEY_ACTIONS
        );

        dispatch(campaignsActions.readCampaigns({
            ...Object.fromEntries(params),
            columns: columns.toString()
        }));
    }

    useEffect(() => {
        readCampaigns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    const [filters, setFilters] = useState({
        type: params.get('type')?.split(','),
        status: params.get('status')?.split(','),
        shared: params.get('shared')?.split(','),
        campaign_conversion: params.get('campaign_conversion')?.split(',')
    })

    const onFilter = async (_, { name, value }) => {
        setFilters({
            ...filters,
            [name]: value
        });
    }

    useEffect(() => {
        const query = new URLSearchParams(params);

        if (search.trim()) {
            query.set('search', search);
        } else {
            query.delete('search');
        }

        const { type, status, shared, campaign_conversion } = filters;
        if (type?.length > 0) {
            query.set('type', type.join(','));
        } else {
            query.delete('type');
        }
        if (campaign_conversion?.length > 0) {
            query.set('campaign_conversion', campaign_conversion.join(','));
        } else {
            query.delete('campaign_conversion');
        }
        if (status?.length > 0) {
            query.set('status', status.join(','));
        } else {
            query.delete('status');
        }
        if (shared?.length > 0) {
            query.set('shared', shared.join(','));
        } else {
            query.delete('shared');
        }

        const { filter } = filters;
        if (filter?.length > 0) {
            query.set('filter', filter);
        } else {
            query.delete('filter');
        }

        const query_string = query.toString();
        navigate(`/campaigns${query_string ? `?${query_string}` : ''}`,);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    const onSearchSubmit = (value) => {
        const query = new URLSearchParams(params);
        query.set('search', value.trim());
        query.set('page', 1);
        const query_string = query.toString();
        navigate(`/campaigns${query_string ? `?${query_string}` : ''}`);
    }

    const isColumnHidden = (column) => {
        return !columns.includes(column);
    }

    const onToggleColumn = async (column) => {
        const updatedColumns = isColumnHidden(column) ? [...columns, column] : columns.filter(visibleColumn => visibleColumn !== column);

        const updateColumnsParameter = () => {
            const query = new URLSearchParams(params);
            if (updatedColumns.length) {
                query.set('columns', updatedColumns.join(','));
            } else {
                query.delete('columns');
            }
            const query_string = query.toString();
            navigate(`/campaigns${query_string ? `?${query_string}` : ''}`);
        }

        updateColumnsParameter();
    }

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`/campaigns${query_string ? `?${query_string}` : ''}`);
    }

    return (

        <div className='CampaignsPage'>
            <Header className='CampaignsPage__PageHeader'>
                <Header.Content>Campaigns</Header.Content>
            </Header>
            <TableWithHeader
                header={(
                    <CampaignToolbar
                        search={searchQuery}
                        onChangeSearch={onChangeSearch}
                        onSearchSubmit={onSearchSubmit}
                        cancelFunction={cancelFunction}
                        isColumnHidden={isColumnHidden}
                        onToggleColumn={onToggleColumn}
                        onFilter={onFilter}
                    />
                )}
                table={(
                    <CampaignsContextProvider>
                        <CampaignsTable
                            isColumnHidden={isColumnHidden}
                            onFilter={onFilter}
                            onPageChange={onPageChange}
                            readCampaigns={readCampaigns}
                        />
                    </CampaignsContextProvider>
                )}
            />
        </div>
    );
}

export default Campaigns;
