import React, { useCallback, useEffect, useState } from 'react';
import Table from './subcomponents/Table';
import { TableWithHeader, STATUS_TYPES, Notification } from 'dyl-components';
import Toolbar from './subcomponents/Toolbar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BulkActionsProvider from 'shared/context/BulkActionsProvider';
import { useDispatch } from 'react-redux';
import pbxConfigActions from 'actions/pbx_config';
import AudioModal from 'shared/modals/Audio/Audio';

const AudioList = () => {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const dispatch = useDispatch();
    const searchQuery = params.get('search') || '';
    const [search, setSearch] = useState(searchQuery);
    const [isEditSoundsModal, setIsEditSoundsModal] = useState(false);
    const [isSoundModalOpen, setIsSoundModalOpen] = useState(false);

    const onChangeSearch = (_, { value }) => {
        setSearch(value);
    }
    
    const cancelFunction = () => {
        setSearch("");
        const query = new URLSearchParams(params);
        query.delete('search');
        const query_string = query.toString();
        navigate(`/settings/phone-management/audio${query_string ? `?${query_string}` : ''}`,);
    }

    const onSearchSubmit = (value) => {
        const query = new URLSearchParams(params);
        query.set('search', value.trim());
        query.set('page', 1);
        const query_string = query.toString();
        navigate(`/settings/phone-management/audio${query_string ? `?${query_string}` : ''}`);
    }

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`/settings/phone-management/audio${query_string ? `?${query_string}` : ''}`);
    }

    const [filters, setFilters] = useState({
        type: params.get('type')?.split(','),
    })

    const onFilter = async (_, { name, value }) => {
        setFilters({
            ...filters,
            [name]: value
        });
    }

    const onCloseSoundsModal = () => {
        setIsSoundModalOpen(false);
        setIsEditSoundsModal(false);
    }

    const onOpenEditSoundsModal = async (id) => {
        await dispatch(pbxConfigActions.getSound(id));
        setIsEditSoundsModal(true);
        setIsSoundModalOpen(true);
    } 

    const onDeleteSound = async (id) => {
        try {
            await dispatch(pbxConfigActions.deleteSound(id));
            Notification.alert("Successfully removed audio!", STATUS_TYPES.SUCCESS);
            onReload();
        } catch (e) {
            Notification.alert("Failed to remove audio", STATUS_TYPES.ERROR);
        }
    }

    const onReload = useCallback(() => {
        dispatch(pbxConfigActions.getSounds(params));
    }, [dispatch, params])

    useEffect(() => {
        const query = new URLSearchParams(params);
        if (search.trim()) {
            query.set('search', search);
        } else {
            query.delete('search');
        }
        const { type, order } = filters;
        if (type?.length > 0) {
            query.set('type', type.join(','));
        } else {
            query.delete('type');
        }
        if (order?.length > 0) {
            query.set('order', order.join(','));
        } else {
            query.delete('order');
        }
       
        const query_string = query.toString();
        navigate(`/settings/phone-management/audio${query_string ? `?${query_string}` : ''}`,);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    useEffect(() => {
        onReload();
    }, [onReload])

    return <>
        <div className='PhoneAudio'>
            <TableWithHeader
                header={(
                    <Toolbar
                        search={searchQuery}
                        onChangeSearch={onChangeSearch}
                        onSearchSubmit={onSearchSubmit}
                        cancelFunction={cancelFunction}
                        setIsSoundModalOpen={setIsSoundModalOpen}
                    />
                )}
                table={
                    <Table
                        onFilter={onFilter}
                        onPageChange={onPageChange}
                        onOpenEditSoundsModal={onOpenEditSoundsModal}
                        onDeleteSound={onDeleteSound}
                    />
                }
            />
        </div>
        <AudioModal open={isSoundModalOpen} onClose={onCloseSoundsModal}  onReload={onReload} isEditSoundsModal={isEditSoundsModal} /> 
    </>
}

export default () => (
    <BulkActionsProvider>
        <AudioList />
    </BulkActionsProvider>
);