import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function contactsReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CONTACTS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingContacts: true };
        case getNamedAction(ACTION_NAMES.CONTACTS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, contacts: (action.data.data || []).map(contact => ({ ...contact, isMarked: state.allPagesMarked })), isReadingContacts: false, queryParameters: { ...state.queryParameters, ...action.queryParameters }, count: action.data.count, allMarked: state.allPagesMarked };
        case getNamedAction(ACTION_NAMES.CONTACTS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingContacts: false };

        case getNamedAction(ACTION_NAMES.RECENT_CONTACTS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingRecentContacts: true };
        case getNamedAction(ACTION_NAMES.RECENT_CONTACTS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, recent_contacts: action.data.data, isReadingRecentContacts: false, queryParameters: { ...state.queryParameters, ...action.queryParameters }, count: action.data.count };
        case getNamedAction(ACTION_NAMES.RECENT_CONTACTS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingRecentContacts: false };

        case getNamedAction(ACTION_NAMES.CONTACT_AUTOCOMPLETE_FILTER, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingContacts: true };
        case getNamedAction(ACTION_NAMES.CONTACT_AUTOCOMPLETE_FILTER, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, contacts: action.data.data, isReadingContacts: false, filterQueryParameters: { ...state.filterQueryParameters, ...action.filterQueryParameters }, count: action.data.count };
        case getNamedAction(ACTION_NAMES.CONTACT_AUTOCOMPLETE_FILTER, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingContacts: false };

        case getNamedAction(ACTION_NAMES.CONTACTS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreating: true };
        case getNamedAction(ACTION_NAMES.CONTACTS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
        case getNamedAction(ACTION_NAMES.CONTACTS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreating: false };

        case getNamedAction(ACTION_NAMES.CONTACTS_BULK, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, bulkAction: action.body?.action };
        case getNamedAction(ACTION_NAMES.CONTACTS_BULK, CRUD_ACTION_TYPES.CREATE_SUCCESS):
        case getNamedAction(ACTION_NAMES.CONTACTS_BULK, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, bulkAction: null };

        

        case 'MARK_CONTACT':
            {
                const contacts = state.contacts.slice(0);
                let contactToMark = contacts.filter(contact => contact.id === action.id)[0];
                contactToMark.isMarked = !contactToMark.isMarked;
                return {
                    ...state, contacts, allPagesMarked: false,
                    ...(contacts.every(({ isMarked }) => !isMarked) ? { allMarked: false } : {}),
                    ...(contacts.every(({ isMarked }) => isMarked) ? { allMarked: true } : {})
                };
            }
        case 'MARK_ALL_CONTACTS':
            {
                const contacts = state.contacts.slice(0);
                let allMarked = !state.allMarked;
                contacts.forEach(lead => { lead.isMarked = allMarked });
                return { ...state, contacts, allMarked, allPagesMarked: false }
            }
        case 'MARK_CONTACTS_IN_EVERY_PAGE':
            {
                const contacts = state.contacts.slice(0);
                let allPagesMarked = !state.allPagesMarked;
                contacts.forEach(lead => { lead.isMarked = allPagesMarked });
                return { ...state, contacts, allPagesMarked, allMarked: allPagesMarked }
            }



        case getNamedAction(ACTION_NAMES.CONTACT_SEARCH, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isSearchingContacts: true, contacts_searched: [], contacts_searched_count: 0 };
        case getNamedAction(ACTION_NAMES.CONTACT_SEARCH, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isSearchingContacts: false, contacts_searched: action.data.data, contacts_searched_count: action.data.count };
        case getNamedAction(ACTION_NAMES.CONTACT_SEARCH, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isSearchingContacts: false, contacts_searched: [], contacts_searched_count: 0 };
            
        case getNamedAction(ACTION_NAMES.CONTACT_VIEW_LOOKUP, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, islookingupContact: true, contact_lookup: null };
        case getNamedAction(ACTION_NAMES.CONTACT_VIEW_LOOKUP, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, islookingupContact: false, contact_lookup: action.data };
        case getNamedAction(ACTION_NAMES.CONTACT_VIEW_LOOKUP, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, islookingupContact: false, contact_lookup: null };
            
        case "REMOVE_VIEW_LOOKUP":
            return { ...state, islookingupContact: false, contact_lookup: null };

        default:
            return state;
    }
}

export default contactsReducer;
