import React from 'react';
import { EmailTemplates, Notification, STATUS_TYPES } from "dyl-components";
import { connect } from 'react-redux';
import { Pagination, Grid } from 'semantic-ui-react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import templateCategoryActions from 'actions/template_category';

import EmailTemplateModal from 'shared/modals/EmailTemplateModal';
import emailTemplateActions from 'actions/email_templates';

import PreviewEmailTemplateModal from 'shared/modals/PreviewEmailTemplateModal';
import companyActions from 'actions/company';
import userActions from 'actions/user';
import uploadActions from 'actions/upload';
import emailAttachmentActions from 'actions/email_attachment';
import { FILE_CATEGORIES } from 'utils/FileUtils';

import { DateTimeUtils } from 'dyl-components';

import './index.scss';


const LIMIT = 25;

class EmailLibrary extends React.Component {
    state = {
        search: this.props.search,
        page: this.props.page,
        filters: [],

        isAddTemplateModalOpen: false,
        isEditTemplateModalOpen: false,
        templateBeingEdited: null,
        templateBeingPreviewed: null,

        templateBeingCopied: null,
        saving: false
    }

    componentDidMount() {
        this.props.onReadCategories();
        this.props.onReadTemplates({ search: this.props.search, page: this.props.page, limit: LIMIT });
        this.props.onReadCompanySignature();
        this.props.onReadUserSignature(this.props.user_id);
    }

    onChangeSearch = (_, { value: search }) => {
        this.setState({ search });
    }
    

    onFilter = (_, { name, value }) => {
        const queryParameters = { ...this.props.queryParameters, page: 1 };
   
        switch (name) {
            case 'categories':
                queryParameters.template_category_id = value;
                break;
            case 'filters':
                this.setState({ filters: value });
                return;
            default: return;
        }

        this.props.onReadTemplates(queryParameters);
    }

    onSearchSubmit = () => {
        this.props.navigate(`/settings/library/email-templates?search=${this.state.search.trim()}&page=1`);
        this.props.onReadTemplates({ ...this.props.queryParameters, search: this.state.search.trim(), page: 1});
    }

    onSearchCancel = () => {
        this.setState({ search: '' }, () => {
            this.props.navigate(`/settings/library/email-templates`);
            this.props.onReadTemplates({ ...this.props.queryParameters, search: this.state.search, page: 1 });
        });
    }

    onFavorite = (template_id) => {
        // TODO: not yet implemented
    }

    onDelete = async (template_id) => {
        try {
            await this.props.onDeleteTemplate(template_id);
            Notification.alert('Successfully deleted template', STATUS_TYPES.SUCCESS, true);
            this.props.onReadTemplates(this.props.queryParameters);
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to delete template', STATUS_TYPES.ERROR, true);
        }
    }

    onOpenAddTemplateModal = () => {
        this.setState({ isAddTemplateModalOpen: true })
    }

    onCloseAddTemplateModal = () => {
        this.setState({ isAddTemplateModalOpen: false, templateBeingCopied: null })
    }

    onCreate = async (newTemplate) => {
        try {
            this.setState({ saving: true });
            const [{ id: template_id }] = await this.props.onCreateTemplate({
                template_category_id: newTemplate.category,
                subject: newTemplate.subject,
                signature_choice: newTemplate.signature_preference,
                label: newTemplate.name,
                content: newTemplate.body,
                content_type: "html"
            });
            const files = newTemplate.attachments;
            await this.addAttachments(files, template_id);
            Notification.alert('Successfully created template!', STATUS_TYPES.SUCCESS);
            this.props.onReadTemplates({ limit: LIMIT, page: 1 });
            this.setState({ saving: false });
            return Promise.resolve();
        } catch (e) {
            this.setState({ saving: false });
            console.log(e);
            Notification.alert('Failed to create template', STATUS_TYPES.ERROR);
            return Promise.reject(e);
        }
    }

    addAttachments = async (files, template_id) => {
        if (files.length > 0) {
            const toUpload = files.filter(file => file.id === undefined);
            const toCopy = files.filter(file => file.id).map(file => ({
                file_id: file.file_id,
                name: file.name,
                size: file.size,
            }));
            const uploadedFiles = await this.props.upload(toUpload, FILE_CATEGORIES.EMAIL);
            const newFiles = toUpload.map((_, idx) => {    
                return {
                    name: files[idx].name,
                    file_id: uploadedFiles[idx],
                    size: files[idx].size
                }})

            return this.props.addAttachments(
                [...newFiles, ...toCopy], 
                this.props.user_id, 
                template_id
            );
        }
        return Promise.resolve();
    }

    onEdit = (template) => {
        this.setState({ templateBeingEdited: template.id });
    }

    onPreviewFromEdit = (template) => {
        this.setState({ templateBeingPreviewed: template });
    }

    onPreview = (template) => {
        this.setState({
            templateBeingPreviewed: template
        }, () => {
            this.props.onReadTemplate(template.id).then((data) => {
                const { content = "", attachments, subject, signature_choice } = data;
                this.setState({
                    templateBeingPreviewed: {
                        subject,
                        body: content,
                        signature_preference: signature_choice,
                        attachments: attachments.data.map(attachment => ({
                            path: attachment.name,
                            id: attachment.id,
                            file_id: attachment.file_id,
                            name: attachment.name
                        }))
                    }
                })
            });
        });
    }

    onCopy = (template) => {
        this.setState({ templateBeingCopied: template.id, isAddTemplateModalOpen: true });
    }

    onCloseEditTemplateModal = () => {
        this.setState({ templateBeingEdited: null });
    }

    onClosePreviewEmailTemplateModal = () => {
        this.setState({ templateBeingPreviewed: null });
    }

    onUpdate = async (template_id, updatedTemplate, originalTemplateAttachments) => {
        try {
            this.setState({ saving: true });
            await this.props.onUpdateTemplate(template_id, {
                template_category_id: updatedTemplate.category,
                subject: updatedTemplate.subject,
                signature_choice: updatedTemplate.signature_preference,
                label: updatedTemplate.name,
                content: updatedTemplate.body,
                content_type: "html"
            });
            const files = updatedTemplate.attachments;
            const filesToAdd = files.filter(file => file.id === undefined);
            const removedFiles = originalTemplateAttachments.filter(attachment => !files.find((file) => file.id === attachment.id)) || [];
            await Promise.all([
                this.addAttachments(filesToAdd, template_id),
                this.removeAttachments(removedFiles, template_id)
            ])
            Notification.alert('Successfully updated template!', STATUS_TYPES.SUCCESS);
            this.props.onReadTemplates({ limit: LIMIT, page: this.props.page });
            this.setState({ saving: false });
            return Promise.resolve();
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to update template', STATUS_TYPES.ERROR);
            this.setState({ saving: false });
            return Promise.reject(e);
        }
    }

    removeAttachments = (files, template_id) => {
        return Promise.all(files.map(file => this.props.removeAttachment(file.id, template_id, this.props.user_id)));
    }

    onPageChange = (_, { activePage }) => {
        const queryParameters = { ...this.props.queryParameters }
        const query_string = activePage.toString();
        this.props.navigate(`/settings/library/email-templates?page=${query_string ? `${query_string}` : ''}`);
        this.props.onReadTemplates({ ...queryParameters, page: activePage });
    }

    getTemplatePreview = () => {
        const { templateBeingPreviewed } = this.state;
        const { body, signature_preference } = templateBeingPreviewed;
        const { user_signature, company_signature } = this.props;
        let signature;
        switch (signature_preference) {
            case 'my_signature':
                signature = user_signature;
                break;
            case 'company_signature':
                signature = company_signature;
                break;
            default:
                signature = '';
        }
        return `${body} <br> ${signature}`;
    }

    render() {
        return (
            <React.Fragment>
                <EmailTemplates
                    categoryOptions={this.props.categoryOptions}
                    categories={this.props.queryParameters.template_category_id}
                    filters={this.state.filters}
                    search={this.props.search}
                    onChangeSearch={this.onChangeSearch}
                    onSearchSubmit={this.onSearchSubmit}
                    onSearchCancel={this.onSearchCancel}
                    onChangeSelectedCategories={this.onFilter}
                    onChangeSelectedFilters={this.onFilter}
                    templates={this.props.templates}
                    onFavorite={this.onFavorite}
                    onDelete={this.onDelete}
                    onOpenAddTemplateModal={this.onOpenAddTemplateModal}
                    onEdit={this.onEdit}
                    onPreview={this.onPreview}
                    onCopy={this.onCopy}

                    page={this.props.queryParameters.page}
                    count={this.props.template_count}
                    isReading={this.props.isReading}
                />
                {this.state.isAddTemplateModalOpen && (
                    <EmailTemplateModal
                        open={this.state.isAddTemplateModalOpen}
                        onClose={this.onCloseAddTemplateModal}
                        onCreate={this.onCreate}
                        categoryOptions={this.props.categoryOptions}
                        isSaving={this.state.saving}
                        onPreview={this.onPreviewFromEdit}
                        hasUserSignature={this.props.hasUserSignature}
                        hasCompanySignature={this.props.hasCompanySignature}
                        duplicate_id={this.state.templateBeingCopied}
                    />
                )}
                {this.state.templateBeingEdited && (
                    <EmailTemplateModal
                        open={this.state.templateBeingEdited}
                        onClose={this.onCloseEditTemplateModal}
                        onUpdate={this.onUpdate}
                        categoryOptions={this.props.categoryOptions}
                        id={this.state.templateBeingEdited}
                        isSaving={this.state.saving}
                        onPreview={this.onPreviewFromEdit}
                        hasUserSignature={this.props.hasUserSignature}
                        hasCompanySignature={this.props.hasCompanySignature}
                    />
                )}
                {this.state.templateBeingPreviewed && (
                    <PreviewEmailTemplateModal
                        open={this.state.templateBeingPreviewed}
                        onClose={this.onClosePreviewEmailTemplateModal}
                        subject={this.state.templateBeingPreviewed.subject}
                        content={this.getTemplatePreview()}
                        attachments={this.state.templateBeingPreviewed.attachments}
                        isReadingTemplate={this.props.isReadingTemplate}
                    />
                )}
                {this.props.template_count > LIMIT &&
                    <Grid>
                        <Grid.Row>
                            <Grid.Column textAlign='right' style={{ paddingRight: 0 }}>
                                <Pagination
                                    boundaryRange={0}
                                    activePage={this.props.queryParameters.page}
                                    ellipsisItem={null}
                                    siblingRange={2}
                                    totalPages={Math.ceil(this.props.template_count / this.props.queryParameters.limit)}
                                    onPageChange={this.onPageChange}
                                    disabled={this.props.isReading}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const { signatures: user_signatures } = state.user;
    const { signatures: company_signatures } = state.company;

    let user_signature = {};
    let company_signature = {};
    let hasUserSignature = user_signatures && user_signatures.length > 0;
    let hasCompanySignature = company_signatures && company_signatures.length > 0;

    if (hasUserSignature) {
        user_signature = user_signatures.filter(signature => signature.main).length > 0 ? user_signatures.filter(signature => signature.main)[0] : user_signatures[0];
    }

    if (hasCompanySignature) {
        company_signature = company_signatures.filter(signature => signature.main).length > 0 ? company_signatures.filter(signature => signature.main)[0] : company_signatures[0];
    }

    const isProcessingAttachments = state.upload.isUploadingFile || state.email_attachment.isCreatingAttachment;
    const isUpdatingTemplate =
        state.email_template.templateBeingUpdated ||
        state.email_attachment.attachmentBeingDeleted ||
        isProcessingAttachments;

    const isCreatingTemplate =
        state.email_templates.isCreatingTemplate || isProcessingAttachments;

    return ({
        templates: state.email_templates.templates.map(({ id, label, template_category_id, subject, signature_choice, content, has_attachments, isFavorite, created }) => ({
            id,
            name: label,
            subject,
            signature_preference: signature_choice,
            body: content || '',
            isFavorite,
            has_attachments,
            created,
            created_formatted: DateTimeUtils.formatEpoch(created, DateTimeUtils.WORD_DATETIME_FORMAT),
        })),
        template_count: state.email_templates.count,
        isReading: state.email_templates.isReadingTemplates || state.template_category.isReadingCategories,
        isCreatingTemplate,
        isReadingTemplate: state.email_template.isReadingTemplate,

        isUpdatingTemplate,
        templateBeingDeleted: state.email_template.templateBeingDeleted,
        attachments: state.email_attachment.attachments,

        queryParameters: state.email_templates.queryParameters,

        categoryOptions: state.template_category.categories.map(category => ({
            key: category.id,
            value: category.id,
            text: category.name
        })),
        category_count: state.template_category.count,
        user_id: state.auth.user_id,

        user_signature: user_signature.content || '',
        company_signature: company_signature.content || '',

        hasUserSignature,
        hasCompanySignature
    })
};

const mapDispatchToProps = dispatch => ({
    onReadCategories: () => {
        return dispatch(templateCategoryActions.read({ category: 'email' }));
    },
    onReadTemplates: (queryParameters) => {
        return dispatch(emailTemplateActions.getTemplates(queryParameters));
    },
    onReadTemplate: (id) => {
        return dispatch(emailTemplateActions.getTemplate(id));
    },
    onCreateTemplate: (payload) => {
        return dispatch(emailTemplateActions.createTemplates([payload]));
    },
    onUpdateTemplate: (id, payload) => {
        return dispatch(emailTemplateActions.updateTemplate(id, payload));
    },
    onDeleteTemplate: (id) => {
        return dispatch(emailTemplateActions.deleteTemplate(id));
    },
    onReadCompanySignature: () => {
        return dispatch(companyActions.readSignatures());
    },
    onReadUserSignature: (user_id) => {
        return dispatch(userActions.readSignatures({ user_id }));
    },
    upload: (files, category) => {
        return dispatch(uploadActions.uploadFiles(files, category))
    },
    addAttachments: (attachments, user_id, template_id) => {
        return dispatch(emailAttachmentActions.createAttachments(attachments, { user_id }, template_id))
    },
    removeAttachment: (attachment_id, email_template_id, user_id) => {
        return dispatch(emailAttachmentActions.deleteAttachment(attachment_id, { user_id, email_template_id }));
    }
})

const EmailLibraryContainer = (props) => {
    const navigate = useNavigate();
    const [query] = useSearchParams();
    const search = query.get('search') || '';
    const page = query.get('page') || 1;

    return <EmailLibrary {...props} navigate={navigate} search={search} page={page} />
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailLibraryContainer);
