import { Button, generateResolver, Modal, yup } from "dyl-components";
import { FormProvider, useController, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Form, Grid, Header, Segment } from "semantic-ui-react";
import PriceField from "shared/forms/ProductForm/PriceField";
import BankPaymentMethod from "shared/Payment/BankPaymentMethod";
import OutstandingInvoicesTable from "./OutstandingInvoicesTable";
import BulkActionsProvider from "shared/context/BulkActionsProvider";
import applyCurrencySchema from "shared/schemas/products/applyCurrencySchema";

const FundAccountForm = ({ isOpen, onClose }) => {
    const customer_id = useSelector((state) => state.auth.customer_id);

    const { invoices } = useSelector(() => ({
        invoices: [],
        count: 0,
    }));

    const methods = useForm({
        mode: "onChange",
        defaultValues: {
            amount: 0,
            fundToApply: null,
            invoicesToPay: []
        },
        resolver: generateResolver({
            amount: applyCurrencySchema(yup.number()).required("This field is required"),
            payment_method: yup.string().required("This field is required"),
            invoicesToPay: yup.array().when("fundToApply", {
                is: value => invoices.length && value !== "sms_reload_balance",
                then: schema => schema.min(1, "This field is required")
            })
        }),
    });

    const {
        formState: { isValid, isDirty },
        handleSubmit,
        control,
        reset,
    } = methods;

    // TODO: replace this with actual value
    const isProcessingPaymentDetails = false;

    const onProcess = (data) => {
        console.log(data);
    };

    const { field: fundToApplyField } = useController({
        control,
        name: "fundToApply",
    });

    const { isReadingPaymentInfo } = useSelector((state) => ({
        isReadingPaymentInfo:
            state.payment.isReadingCustomerFormToken ||
            state.payment.isReadingCustomerProfile,
    }));

    return (
        <Modal
            open={isOpen}
            onClose={() => {
                onClose();
                reset();
            }}
            size="big"
        >
            <Modal.Header>Fund Account</Modal.Header>
            <Modal.Content scrolling>
                <FormProvider {...methods}>
                    <Form>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header color="primary">
                                        Outstanding Invoice
                                    </Header>
                                    <BulkActionsProvider>
                                        <OutstandingInvoicesTable />
                                    </BulkActionsProvider>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header color="primary">
                                        Payment Method
                                    </Header>
                                    {!isReadingPaymentInfo ? (
                                        <BankPaymentMethod
                                            account_id={customer_id}
                                        />
                                    ) : (
                                        <Segment loading basic />
                                    )}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns="equal">
                                <Grid.Column>
                                    <PriceField
                                        label={
                                            <Header color="primary">
                                                Amount
                                            </Header>
                                        }
                                        name={"amount"}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Header color="primary">Apply To</Header>
                                    <Form.Group widths={"equal"}>
                                        <Form.Radio
                                            label="Account Balance"
                                            onChange={() => {
                                                fundToApplyField.onChange({
                                                    target: {
                                                        name: fundToApplyField.name,
                                                        value: "account_balance",
                                                    },
                                                });
                                            }}
                                            checked={
                                                fundToApplyField.value ===
                                                "account_balance"
                                            }
                                            disabled={invoices.length}
                                        />
                                        <Form.Radio
                                            label="SMS Reload Balance"
                                            onChange={() => {
                                                fundToApplyField.onChange({
                                                    target: {
                                                        name: fundToApplyField.name,
                                                        value: "sms_reload_balance",
                                                    },
                                                });
                                            }}
                                            checked={
                                                fundToApplyField.value ===
                                                "sms_reload_balance"
                                            }
                                        />
                                    </Form.Group>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </FormProvider>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    disabled={
                        !isValid || !isDirty || isProcessingPaymentDetails
                    }
                    type="submit"
                    color="primary"
                    onClick={handleSubmit(onProcess)}
                    loading={isProcessingPaymentDetails}
                >
                    Process
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default FundAccountForm;
