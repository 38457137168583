import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Header, Icon } from 'semantic-ui-react';
import { useSearchParams, useNavigate, useParams } from 'react-router-dom';
import { TableWithHeader } from 'dyl-components';

import CampaignsTable from './subcomponents/CampaignsTable';

import contactCampaignActions from 'actions/contact_campaigns';

import './index.scss';

import CampaignsContextProvider from './subcomponents/CampaignsContextProvider';
import AddToCampaignModal from 'shared/modals/AddToCampaignModal';

const CampaignsTab = () => {
    const dispatch = useDispatch();
    const { contact_id } = useParams();
    const [params] = useSearchParams();

    const navigate = useNavigate();

    const routeRoot = `/contact/${contact_id}/campaign`

    const readCampaigns = () => {
        dispatch(contactCampaignActions.readCampaigns(contact_id, {
            ...Object.fromEntries(params)
        }));
    }

    useEffect(() => {
        readCampaigns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    const [filters, setFilters] = useState({
        type: params.get('type')?.split(','),
        status: params.get('status')?.split(','),
        shared: params.get('shared')
    })

    const onFilter = async (_, { name, value }) => {
        setFilters({
            ...filters,
            [name]: value
        });
    }

    useEffect(() => {
        const query = new URLSearchParams(params);

        const { status, shared } = filters;
        if (status?.length > 0) {
            query.set('status', status.join(','));
        } else {
            query.delete('status');
        }
        if (shared?.length > 0) {
            query.set('shared', shared.join(','));
        } else {
            query.delete('shared');
        }

        const { filter } = filters;
        if (filter?.length > 0) {
            query.set('filter', filter);
        } else {
            query.delete('filter');
        }

        const query_string = query.toString();
        navigate(`${routeRoot}${query_string ? `?${query_string}` : ''}`,);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`${routeRoot}${query_string ? `?${query_string}` : ''}`);
    }

    const [isAddToCampaignModalOpen, setIsAddToCampaignModalOpen] = useState(false);
    const openAddToCampaignModal = () => {
        setIsAddToCampaignModalOpen(true);
    }

    return (
        <div className='CampaignsTab'>
            <TableWithHeader
                header={(
                    <Header>
                        <span>
                            <Icon style={{ marginTop: "-0.4em" }} name='plus' color='blue' link circular inverted size='tiny' onClick={openAddToCampaignModal} />  Campaign
                        </span>
                    </Header>
                )}
                table={(
                    <CampaignsContextProvider>
                        <CampaignsTable
                            onFilter={onFilter}
                            onPageChange={onPageChange}
                            readCampaigns={readCampaigns}
                        />
                    </CampaignsContextProvider>
                )}
            />
            <AddToCampaignModal 
                contactToAdd={Number(contact_id)}
                onClose={() => {setIsAddToCampaignModalOpen(false)}}
                open={isAddToCampaignModalOpen}
                refresh={() => {readCampaigns()}}
            />
        </div>
    );
}

export default CampaignsTab;
