import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import UserExtensionsTable from './subcomponents/UserExtensionsTable';
import BulkActionsProvider from 'shared/context/BulkActionsProvider';
import { Pagination, Segment } from 'semantic-ui-react';
import './index.scss';
import Toolbar from './subcomponents/Toolbar';
import pbxConfigActions from 'actions/pbx_config'
import UserExtensionModal from 'shared/modals/UserExtensionModal';

const LIST_VIEW_URL = "/settings/phone-management/extensions/users"
const LIMIT = 25;

const Users = () => {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const dispatch = useDispatch();
    const searchQuery = params.get('search') || '';
    const page = params.get("page") || "";
    const order = params.get("order") || ""
    const [search, setSearch] = useState(searchQuery);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { userExtensionsCount: count, isReadingUserExtensions } = useSelector((state) => state.pbx_config);

    const onChangeSearch = (_, { value }) => {
        setSearch(value);
    }
    
    const cancelFunction = () => {
        setSearch("");
        const query = new URLSearchParams(params);
        query.delete('search');
        const query_string = query.toString();
        navigate(`${LIST_VIEW_URL}${query_string ? `?${query_string}` : ''}`,);
    }

    const onSearchSubmit = (value) => {
        const query = new URLSearchParams(params);
        query.set('search', value.trim());
        query.set('page', 1);
        const query_string = query.toString();
        navigate(`${LIST_VIEW_URL}${query_string ? `?${query_string}` : ''}`);
    }

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`${LIST_VIEW_URL}${query_string ? `?${query_string}` : ''}`);
    }

    const onReload = () => {
        dispatch(pbxConfigActions.getUserExtensions(params))
    }

    const onOpenModal = async (extension) => {
        await dispatch(pbxConfigActions.getExtension(extension));
        setIsModalOpen(true);
    }

    const onCloseModal = () => {
        setIsModalOpen(false);
    }

    useEffect(() => {
        const query = new URLSearchParams(params);
        if (search.trim()) {
            query.set('search', search);
        } else {
            query.delete('search');
        }
        if (order.trim()) {
            query.set('order', order);
        } else {
            query.delete('order');
        }
        const query_string = query.toString();
        navigate(`${LIST_VIEW_URL}${query_string ? `?${query_string}` : ''}`,);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        dispatch(pbxConfigActions.getUserExtensions(params))
    }, [params, dispatch])

    return (
        <div className='UserExtensions'>
            <Toolbar
                onChangeSearch={onChangeSearch}
                cancelFunction={cancelFunction}
                onSearchSubmit={onSearchSubmit}
                search={searchQuery}
                isReadingUserExtensions={isReadingUserExtensions}
            />
            <UserExtensionsTable isFiltering={!!search} onReload={onReload} onOpenModal={onOpenModal} />
            {count > 0 && (
                <Segment basic textAlign="right">
                    <Pagination
                        boundaryRange={0}
                        activePage={page}
                        ellipsisItem={null}
                        siblingRange={2}
                        totalPages={Math.ceil(count / LIMIT)}
                        onPageChange={onPageChange}
                        disabled={isReadingUserExtensions}
                    />
                </Segment>
            )}
            <UserExtensionModal open={isModalOpen} onClose={onCloseModal} onReload={onReload} />
        </div>
    )
}

export default () => (
    <BulkActionsProvider>
        <Users />
    </BulkActionsProvider>
);

