import React, { useEffect } from "react";
import { Grid, Icon, Menu, Segment } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router";
import { NavLink } from "react-router-dom";

import userActions from "actions/user";
import ProfileHeader from "./ProfileHeader";

import "./index.scss";

const Profile = ({ isReadingUser, user_id }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
 
    const { id } = useParams();
    const { pathname } = useLocation();
    const end = pathname.split("/").pop();
    const root = id ? `/settings/users/${id}` : `/profile`;

    const preferencesTabs = [
        { menuItem: "Integration", path: "integration" },
        { menuItem: "Notification", path: "notification" },
        { menuItem: "Chat", path: "chat" },
    ];

    const isPreferencesSubTabOpen = preferencesTabs.findIndex((tab) => tab.path === end) !== -1 || pathname.includes("integration");

    useEffect(() => {
        dispatch(userActions.readLocations(user_id));
        dispatch(userActions.readProfileLogo({ thumbnail: true, user_id }));
    }, [dispatch, user_id]);

    const PrefMenu = (
        <Menu.Item
            onClick={() => {
                navigate(`${root}/integration`);
            }}
            key="Preferences"
        >
            Preferences
            {isPreferencesSubTabOpen && <Icon name="caret down" />}
        </Menu.Item>
    )

    const panes = [
        { menuItem: "General", path: "general" },
        { menuItem: "Team(s)", path: "teams" },
        { menuItem: PrefMenu, path: "integration"},
        ...(isPreferencesSubTabOpen ? preferencesTabs : []),
        { menuItem: "Permission", path: "permission" },
    ];

    return (
        <Segment.Group
            style={{
                marginLeft: "-1em",
                marginRight: "-1em",
                ...(id ? { border: "none", boxShadow: "none" } : {}),
            }}
        >
            <Segment basic={id} loading={isReadingUser}>
                <ProfileHeader user_id={user_id} />
            </Segment>
            <Segment basic={id}>
                <Grid>
                    <Grid.Column width={3}>
                        <Menu
                            className={`Tabs Tabs${
                                isPreferencesSubTabOpen
                                    ? "--preferencesTabOpen"
                                    : ""
                            }`}
                            secondary
                            fluid
                            vertical
                            color="blue"
                        >
                            {panes.map((pane) =>
                                typeof pane.menuItem === "string" ? (
                                    <Menu.Item
                                        key={pane.path}
                                        className={({ isActive }) =>
                                            isActive ? "active" : ""
                                        }
                                        as={NavLink}
                                        to={`${root}/${pane.path}`}
                                    >   
                                        {pane.menuItem}
                                    </Menu.Item>
                                ) : (  
                                    pane.menuItem
                                )
                            )}
                        </Menu>
                    </Grid.Column>
                    <Grid.Column width={13}>
                        <Segment loading={isReadingUser} basic>
                            <Outlet />
                        </Segment>
                    </Grid.Column>
                </Grid>
            </Segment>
        </Segment.Group>
    );
};

const ProfileContainer = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const { pathname } = useLocation();
    const { isReadingUser, firstNavigation } = useSelector( (state) => state.user );
    const { user_id } = useSelector((state) => state.auth);
    const record_id = Number(id || user_id); //Viewing another user profile /settings/users/:id/general or logged in user profile /profile/general
    
    useEffect(() => {
        if (firstNavigation) {
            dispatch(userActions.viewUserProfile(record_id));
        }
    }, [navigate, pathname, record_id, firstNavigation, dispatch]);

    return (
        <div>
            <Profile
                editable={props.editable}
                isReadingUser={isReadingUser}
                showUploadModal={props.showUploadModal}
                user_id={record_id}
            />
        </div>
    );
};
export default ProfileContainer;