import contactPhoneActions from "actions/contact_phone";
import contactEmailActions from "actions/contact_email";
import contactLocationActions from "actions/contact_location";
import orderActions from "actions/order";
import contactDuplicatesActions from "actions/contact_duplicates";
import {
    ButtonLink,
    Notification,
    Person,
    STATUS_TYPES,
    VALIDATORS,
    generateResolver,
    yup,
} from "dyl-components";
import { useContext, useEffect } from "react";
import { Controller, useController, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Form, Grid, Header, Label, Segment, Select } from "semantic-ui-react";
import { STATES } from "shared/constants/STATES";
import { PhoneUtil, StringUtils } from "utils";
import LocationUtils from "utils/LocationUtils";
import { QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";
import { Link } from "react-router-dom";
import contactsActions from "actions/contacts";

const PHONE_TYPE_OPTIONS = ["home", "cell", "work", "fax", "other"].map(
    (value) => ({
        key: value,
        value,
        text: StringUtils.capitalize(value),
    })
);
const EMAIL_TYPE_OPTIONS = ["work", "personal", "other"].map((value) => ({
    key: value,
    value,
    text: StringUtils.capitalize(value),
}));

const OrderAddress = ({ type, defaultValues, onSave, account_id }) => {
    const {
        control,
        formState: { isValid, isDirty, errors },
        setError,
        handleSubmit,
        trigger
    } = useForm({
        mode: "onChange",
        defaultValues: {
            ...(type === "billing"
                ? {
                      save_address: false,
                  }
                : {
                    first_name: defaultValues?.ship_to?.first_name,
                    last_name: defaultValues?.ship_to?.last_name,
                    suffix: defaultValues?.ship_to?.suffix
                }),
            ...defaultValues,
            contact_id: (() => {
                if (type === "shipping") {
                    return defaultValues?.contact_id === null ? "shipping-contact" : defaultValues.contact_id;
                }
                return defaultValues.contact_id;
            })()
        },
        resolver: generateResolver({
            contact_id: yup.mixed().required("This field is required"),
            address: yup.object({
                label: yup.string().required("This field is required"),
                street: yup
                    .string()
                    .maxlength(100)
                    .required("This field is required"),
                additional_street: yup.string().maxlength(12),
                city: yup
                    .string()
                    .maxlength(60)
                    .required("This field is required"),
                state: yup.string().required("This field is required"),
                zip: VALIDATORS.US_POSTAL_CODE().required(
                    "This field is required"
                ),
            }),
            phone: VALIDATORS.PHONE_NUMBER().required(
                "This field is required"
            ),
            phone_type: yup.string().oneOf(PHONE_TYPE_OPTIONS.map(({ value }) => value), "This field is required").required(
                "This field is required"
            ),
            email: VALIDATORS.EMAIL_ADDRESS().required(
                "This field is required"
            ),
            email_type: yup.string().oneOf(EMAIL_TYPE_OPTIONS.map(({ value }) => value), "This field is required").required(
                "This field is required"
            ),
            ...(type === "shipping"
                ? {
                      
                      first_name: VALIDATORS.FIRST_NAME()
                          .no_whitespace_only()
                          .required("This field is required"),
                      last_name: VALIDATORS.LAST_NAME()
                          .no_whitespace_only()
                          .required("This field is required"),
                      suffix: VALIDATORS.SUFFIX(),
                  }
                : {}),
        }),
    });

    const { field: customerField } = useController({
        control,
        name: "contact_id",
    });

    const { field: firstNameField, fieldState: { error: firstNameFieldError } } = useController({
        control,
        name: "first_name"
    })

    const { field: lastNameField, fieldState: { error: lastNameFieldError } } = useController({
        control,
        name: "last_name"
    });

    const { field: suffixField, fieldState: { error: suffixFieldError } } = useController({
        control,
        name: "suffix"
    });

    const { field: phoneField } = useController({
        control,
        name: "phone",
    });

    const { field: phoneTypeField, fieldState: { error: phoneTypeFieldError } } = useController({
        control,
        name: "phone_type",
    });

    const { field: emailField } = useController({
        control,
        name: "email",
    });

    const { field: emailTypeField, fieldState: { error: emailTypeFieldError } } = useController({
        control,
        name: "email_type",
    });

    const { field: addressField } = useController({
        control,
        name: "address",
    });

    const dispatch = useDispatch();

    const isContactSelected = customerField.value !== `shipping-contact` && Number(customerField.value) !== Number(account_id);

    useEffect(() => {
        if (customerField.value !== `shipping-contact`) {
            dispatch(contactPhoneActions.readOptions(customerField.value)).then(
                (response) => {
                    const phones = response.data || [];
                    const mainPhone =
                        phones.find((phone) => phone.main) || phones[0];
                    phoneField.onChange({
                        target: {
                            name: phoneField.name,
                            value: mainPhone?.phone || defaultValues.phone,
                        },
                    });
                    phoneTypeField.onChange({
                        target: {
                            name: phoneTypeField.name,
                            value:
                                (mainPhone?.phone_type ||
                                    defaultValues?.phone_type)?.toLowerCase(),
                        },
                    });
                }
            );
            dispatch(contactEmailActions.readOptions(customerField.value)).then(
                (response) => {
                    const emails = response.data || [];
                    const mainEmail =
                        emails.find((email) => email.main) || emails[0];
                    emailField.onChange({
                        target: {
                            name: emailField.name,
                            value: mainEmail?.email || defaultValues.email,
                        },
                    });
                    emailTypeField.onChange({
                        target: {
                            name: emailTypeField.name,
                            value:
                                (mainEmail?.email_type ||
                                    defaultValues?.email_type)?.toLowerCase(),
                        },
                    });
                }
            );
            dispatch(
                contactLocationActions.readOptions(customerField.value)
            ).then((response) => {
                const locations = (response.data || []).filter(
                    ({ street, city, state, zip }) =>
                        street && city && state && zip
                );
                const { street, additional_street, city, state, zip, label } =
                    locations?.find((location) => location.main) ||
                        locations[0] || {
                            label: "",
                            additional_street: "",
                            city: "",
                            state: "",
                            street: "",
                            zip: "",
                        };
                addressField.onChange({
                    target: {
                        name: addressField.name,
                        value: {
                            label,
                            street,
                            additional_street,
                            city,
                            state,
                            zip,
                        },
                    },
                });
            });
            if (type === "shipping" && isContactSelected) {
                const selectedOption = options.find(option => option.value === customerField.value);
                firstNameField.onChange({ target: { name: firstNameField.name, value: selectedOption?.first_name || "" } });
                lastNameField.onChange({ target: { name: lastNameField.name, value: selectedOption?.last_name || "" } });
                suffixField.onChange({ target: { name: suffixField.name, value: selectedOption?.suffix  || "" } });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, customerField.value]);

    const order = useSelector((state) => state.order.order);
    const isReadingContactOptions = useSelector(
        (state) => state.account.isReadingContactsForPinning
    );
    const options = useSelector((state) => {
        const { phoneOptions, emailOptions, locationOptions } = {
            phoneOptions: state.contact_phone.phoneOptions,
            emailOptions: state.contact_email.emailOptions,
            locationOptions: state.contact_location.locationOptions,
        };
        return [
            { id: order?.account?.id, name: order?.account?.name, email: "" },
            ...state.account.contactsForPinning.map((contact) => ({
                id: contact.id,
                name: [contact.first_name, contact.last_name, contact.suffix].filter(part => part).join(" "),
                email: contact.email?.email || "",
                first_name: contact.first_name,
                last_name: contact.last_name,
                suffix: contact.suffix
            })),
            ...(type === "shipping"
                ? [
                      {
                          id: `shipping-contact`,
                          name: `+ Shipping Contact`,
                          email: "",
                      },
                  ]
                : []),
        ].map((record) => ({
            key: record.id,
            value: record.id,
            text: record.name,
            ...(record.id !== "shipping-contact"
                ? {
                      first_name: record.first_name || "",
                      last_name: record.last_name || "",
                      suffix: record.suffix || "",
                      content: (
                          <Person username={record.name} email={record.email} />
                      ),
                      locations:
                          locationOptions[record.id]?.options
                              ?.filter(
                                  ({ street, city, state, zip }) =>
                                      street && city && state && zip
                              )
                              .map(
                                  ({
                                      label,
                                      street,
                                      additional_street,
                                      city,
                                      state,
                                      zip,
                                      id,
                                  }) => ({
                                      key: id,
                                      value: {
                                          label,
                                          street,
                                          additional_street,
                                          city,
                                          state,
                                          zip,
                                      },
                                      text: `${
                                          additional_street
                                              ? `${additional_street} `
                                              : ""
                                      }
        ${street ? `${street} ` : ""}${city ? `${city}, ` : ""}
        ${state ? `${state} ` : ""}
        ${zip || ""}`,
                                  })
                              ) || [],
                      phones:
                          phoneOptions[record.id]?.options?.map((phone) => ({
                              key: phone.id,
                              value: phone.phone,
                              text: phone.phone,
                              content: PhoneUtil.formatPhoneNumber(phone.phone),
                          })) || [],
                      emails:
                          emailOptions[record.id]?.options?.map((email) => ({
                              key: email.id,
                              value: email.email,
                              text: email.email,
                          })) || [],
                  }
                : {
                      content: <ButtonLink>{record.name}</ButtonLink>,
                      locations: [],
                      phones: [],
                      emails: [],
                  }),
        }));
    });
    const selectedOption = options.find(
        (option) =>
            option.value ===
            (customerField.value !== "shipping-contact"
                ? Number(customerField.value)
                : "shipping-contact")
    );

    const { quoteBuilderConfig } = useContext(QuoteBuilderContext);

    const {
        isReadingPhoneOptions,
        isReadingEmailOptions,
        isReadingLocationOptions,
        isUpdatingAddress,
    } = useSelector((state) => ({
        isReadingPhoneOptions:
            state.contact_phone.phoneOptions[customerField.value]?.isReading,
        isReadingEmailOptions:
            state.contact_email.emailOptions[customerField.value]?.isReading,
        isReadingLocationOptions:
            state.contact_location.locationOptions[customerField.value]
                ?.isReading,
        isUpdatingAddress:
            state.order.orderBeingUpdated ||
            state.contact_location.isCreatingContactLocation ||
            state.contact_phone.isCreatingPhone ||
            state.contact_email.isCreatingEmail ||
            state.contacts.isCreating,
    }));

    const onUpdateAddress = async (value) => {
        try {
            const emailPayload = {
                main: true,
                email: value?.email,
                type: value?.email_type,
            };
            const phonePayload = {
                main: true,
                phone: value?.phone,
                type: value?.phone_type,
            }
            const locationPayload = value?.address;
            const contact_id = await (async () => {
                if (value?.contact_id !== "shipping-contact") {
                    if (!Boolean(selectedOption?.locations?.length)) {
                        await dispatch(
                            contactLocationActions.addContactLocation(
                                [locationPayload],
                                null,
                                Number(customerField.value)
                            )
                        );
                    }
                    if (!Boolean(selectedOption?.phones?.length)) {
                        await dispatch(
                            contactPhoneActions.addContactPhone(
                                [phonePayload],
                                null,
                                Number(customerField.value)
                            )
                        );
                    }
                    if (!Boolean(selectedOption?.emails?.length)) {
                        await dispatch(
                            contactEmailActions.addContactEmail(
                                [emailPayload],
                                null,
                                Number(customerField.value)
                            )
                        );
                    }
                    return Number(value?.contact_id);
                }
                if (value?.save_address) {
                    return (await dispatch(contactsActions.createContact([
                        {
                            first_name: value?.first_name,
                            last_name: value?.last_name,
                            suffix: value?.suffix,
                            phone: [phonePayload],
                            email: [emailPayload],
                            location: [locationPayload]
                        }
                    ], { account_id })))[0];
                }
                return `shipping-contact`;
            })();
            await dispatch(
                orderActions.updateAddresses(
                    account_id,
                    {
                        [type]:
                            type === "billing"
                                ? {
                                      ...value,
                                      contact_id: Number(contact_id),
                                  }
                                : contact_id === "shipping-contact"
                                ? {
                                      location: {
                                          address: value?.address,
                                          email: value?.email,
                                          phone: value?.phone,
                                          email_type: value?.email_type,
                                          phone_type: value?.phone_type
                                      },
                                      ship_to: {
                                        first_name: value.first_name,
                                        last_name: value.last_name,
                                        suffix: value.suffix
                                      }
                                  }
                                : {
                                      location: {
                                          ...value,
                                          contact_id,
                                      },
                                      ship_to: {
                                        first_name: selectedOption?.first_name || value.first_name,
                                        last_name: selectedOption?.last_name || value.last_name,
                                        suffix: selectedOption?.suffix || value?.suffix
                                      },
                                  },
                    },
                    null,
                    `${Number(quoteBuilderConfig?.id)}/location`
                )
            );
            return onSave({
                ...value,
                contact_id: contact_id === `shipping-contact` ? null : contact_id,
                ship_to: {
                    first_name: value?.first_name,
                    last_name: value?.last_name,
                    suffix: value?.suffix
                },
            });
        } catch (e) {
            console.log(e);
            Notification.alert(
                `Failed to update ${type} address`,
                STATUS_TYPES.ERROR
            );
        }
    };

    const checkDuplicates = (queryParameters) => {
        return dispatch(
            contactDuplicatesActions.checkDuplicates(queryParameters)
        );
    };

    const hasErrors = Object.keys(errors).length > 0;

    return (
        <>
            <Header>{StringUtils.capitalize(type)}</Header>
            <Form
                loading={isUpdatingAddress}
                size="mini"
                style={{
                    width: "50em",
                    maxHeight: "25em",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    marginRight: "1em",
                }}
            >
                {type === "shipping" && (
                    <Form.Checkbox
                        label="Shipping same as billing address"
                        onChange={(_, { checked }) => {
                            if (checked) {
                                customerField.onChange({
                                    target: {
                                        name: customerField.name,
                                        value: account_id
                                    }
                                })
                            }
                        }}
                    />
                )}
                <Form.Select
                    width={8}
                    options={options}
                    label="Customer/Contact Name"
                    required
                    value={customerField.value}
                    onChange={(_, { value }) => {
                        customerField.onChange({
                            target: { name: customerField.name, value },
                        });
                        if (value === Number(account_id)) {
                            firstNameField.onChange({
                                target: { name: firstNameField.name, value: "" },
                            });
                            lastNameField.onChange({
                                target: { name: lastNameField.name, value: "" },
                            });
                            suffixField.onChange({
                                target: { name: suffixField.name, value: "" },
                            });
                        }
                        if (value === `shipping-contact`) {
                            firstNameField.onChange({
                                target: { name: firstNameField.name, value: "" },
                            });
                            lastNameField.onChange({
                                target: { name: lastNameField.name, value: "" },
                            });
                            suffixField.onChange({
                                target: { name: suffixField.name, value: "" },
                            });
                            phoneTypeField.onChange({
                                target: {
                                    name: phoneTypeField.name,
                                    value: null
                                },
                            })
                            phoneField.onChange({
                                target: {
                                    name: phoneField.name,
                                    value: "",
                                },
                            });
                            emailField.onChange({
                                target: {
                                    name: emailField.name,
                                    value: "",
                                },
                            });
                            emailTypeField.onChange({
                                target: {
                                    name: emailTypeField.name,
                                    value: null
                                }
                            })
                            addressField.onChange({
                                target: {
                                    name: addressField.name,
                                    value: {
                                        label: "",
                                        street: "",
                                        additional_street: "",
                                        city: "",
                                        state: "",
                                        zip: "",
                                    },
                                },
                            });
                        }
                    }}
                    {...(selectedOption
                        ? {
                              text:
                                  selectedOption.value !== `shipping-contact`
                                      ? selectedOption.content
                                      : "New Shipping Contact",
                          }
                        : {})}
                    selectOnBlur={false}
                    loading={isReadingContactOptions}
                    {...(type === "billing" ? { open: false, icon: null } : {})}
                />
                {isReadingEmailOptions ||
                isReadingPhoneOptions ||
                isReadingLocationOptions ? (
                    <Segment basic loading />
                ) : (
                    <Grid>
                        {type === "shipping" && (
                            <Grid.Row columns={"equal"}>
                                <Grid.Column>
                                    <Form.Input
                                        value={firstNameField.value}
                                        onChange={(_, { value }) => {
                                            firstNameField.onChange({
                                                target: { name: firstNameField.name, value },
                                            });
                                        }}
                                        required
                                        label="First Name"
                                        error={firstNameFieldError?.message}
                                        placeholder="Enter first name"
                                        fluid
                                        readOnly={isContactSelected}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Input
                                        value={lastNameField.value}
                                        onChange={(_, { value }) => {
                                            lastNameField.onChange({
                                                target: { name: lastNameField.name, value },
                                            });
                                        }}
                                        required
                                        label="Last Name"
                                        error={lastNameFieldError?.message}
                                        placeholder="Enter last name"
                                        fluid
                                        readOnly={isContactSelected}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Input
                                        value={suffixField.value}
                                        onChange={(_, { value }) => {
                                            suffixField.onChange({
                                                target: { name: suffixField.name, value },
                                            });
                                        }}
                                        label="Suffix"
                                        placeholder="Suff."
                                        error={suffixFieldError?.message}
                                        fluid
                                        width={12}
                                        readOnly={isContactSelected}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        )}
                        <Grid.Row columns="equal">
                            <Grid.Column>
                                <Form.Field
                                    control="div"
                                    label="Phone"
                                    required
                                >
                                    <Grid columns="equal">
                                        <Grid.Column width={6}>
                                            <Form.Select
                                                name={phoneTypeField.name}
                                                value={phoneTypeField.value}
                                                onChange={(_, { value }) => {
                                                    phoneTypeField.onChange({
                                                        target: {
                                                            name: phoneTypeField.name,
                                                            value,
                                                        },
                                                    });
                                                }}
                                                placeholder="Type"
                                                options={PHONE_TYPE_OPTIONS}
                                                selectOnBlur={false}
                                                fluid
                                                {...(selectedOption?.phones
                                                    ?.length && phoneTypeField?.value
                                                    ? {
                                                          open: false,
                                                          icon: null,
                                                      }
                                                    : {})}
                                                error={phoneTypeFieldError?.message}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Controller
                                                name={`phone`}
                                                control={control}
                                                render={({
                                                    field: {
                                                        name,
                                                        value,
                                                        onChange,
                                                    },
                                                    fieldState: { error },
                                                }) => (
                                                    <Form.Input
                                                        name={name}
                                                        value={value}
                                                        onChange={async (
                                                            _,
                                                            { value }
                                                        ) => {
                                                            trigger(name);
                                                            await onChange({
                                                                target: {
                                                                    name,
                                                                    value,
                                                                },
                                                            });
                                                            if (
                                                                Number(
                                                                    customerField?.value
                                                                ) !==
                                                                    Number(
                                                                        account_id
                                                                    ) &&
                                                                value !== "" &&
                                                                (await VALIDATORS.PHONE_NUMBER().isValid(
                                                                    value
                                                                ))
                                                            ) {
                                                                const duplicatePhones =
                                                                    await checkDuplicates(
                                                                        {
                                                                            phones: [
                                                                                value,
                                                                            ],
                                                                        }
                                                                    );
                                                                const possibleDuplicate =
                                                                    duplicatePhones[0]
                                                                        ?.contact_id;
                                                                const hasNoDuplicates =
                                                                    possibleDuplicate ===
                                                                        undefined ||
                                                                    Number(
                                                                        possibleDuplicate
                                                                    ) ===
                                                                        Number(
                                                                            customerField.value
                                                                        );
                                                                if (
                                                                    !hasNoDuplicates
                                                                ) {
                                                                    await setError(
                                                                        name,
                                                                        {
                                                                            type: "unique_phone",
                                                                            message:
                                                                                (
                                                                                    <Label color="red">
                                                                                        Phone
                                                                                        matches
                                                                                        an{" "}
                                                                                        <Link
                                                                                            style={{
                                                                                                color: "#2B78FF",
                                                                                            }}
                                                                                            target={
                                                                                                "_blank"
                                                                                            }
                                                                                            to={`/contact/${possibleDuplicate}`}
                                                                                        >
                                                                                            existing
                                                                                            record
                                                                                        </Link>
                                                                                    </Label>
                                                                                ),
                                                                        }
                                                                    );
                                                                }
                                                            }
                                                        }}
                                                        placeholder="Enter phone"
                                                        error={error?.message}
                                                        fluid
                                                        readOnly={
                                                            selectedOption
                                                                ?.phones?.length
                                                        }
                                                    />
                                                )}
                                            />
                                        </Grid.Column>
                                    </Grid>
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field
                                    control="div"
                                    label="Email"
                                    required
                                >
                                    <Grid columns="equal">
                                        <Grid.Column width={6}>
                                            <Form.Select
                                                name={emailTypeField.name}
                                                value={emailTypeField.value}
                                                onChange={(_, { value }) => {
                                                    emailTypeField.onChange({
                                                        target: {
                                                            name: emailTypeField.name,
                                                            value,
                                                        },
                                                    });
                                                }}
                                                placeholder="Type"
                                                options={EMAIL_TYPE_OPTIONS}
                                                selectOnBlur={false}
                                                fluid
                                                {...(selectedOption?.emails
                                                    ?.length && emailTypeField?.value
                                                    ? {
                                                          open: false,
                                                          icon: null,
                                                      }
                                                    : {})}
                                                error={emailTypeFieldError?.message}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Controller
                                                name={`email`}
                                                control={control}
                                                render={({
                                                    field: {
                                                        name,
                                                        value,
                                                        onChange,
                                                    },
                                                    fieldState: { error },
                                                }) => (
                                                    <Form.Input
                                                        name={name}
                                                        value={value}
                                                        onChange={async (
                                                            _,
                                                            { value }
                                                        ) => {
                                                            trigger(name);
                                                            await onChange({
                                                                target: {
                                                                    name,
                                                                    value,
                                                                },
                                                            });
                                                            if (
                                                                Number(
                                                                    customerField?.value
                                                                ) !==
                                                                    Number(
                                                                        account_id
                                                                    ) &&
                                                                value !== "" &&
                                                                (await VALIDATORS.EMAIL_ADDRESS().isValid(
                                                                    value
                                                                ))
                                                            ) {
                                                                const duplicateEmails =
                                                                    await checkDuplicates(
                                                                        {
                                                                            emails: [
                                                                                value,
                                                                            ],
                                                                        }
                                                                    );
                                                                const possibleDuplicate =
                                                                    duplicateEmails[0]
                                                                        ?.contact_id;
                                                                const hasNoDuplicates =
                                                                    possibleDuplicate ===
                                                                        undefined ||
                                                                    Number(
                                                                        possibleDuplicate
                                                                    ) ===
                                                                        Number(
                                                                            customerField.value
                                                                        );
                                                                if (
                                                                    !hasNoDuplicates
                                                                ) {
                                                                    await setError(
                                                                        name,
                                                                        {
                                                                            type: "unique_email",
                                                                            message:
                                                                                (
                                                                                    <Label color="red">
                                                                                        Email
                                                                                        matches
                                                                                        an{" "}
                                                                                        <Link
                                                                                            style={{
                                                                                                color: "#2B78FF",
                                                                                            }}
                                                                                            target={
                                                                                                "_blank"
                                                                                            }
                                                                                            to={`/contact/${possibleDuplicate}`}
                                                                                        >
                                                                                            existing
                                                                                            record
                                                                                        </Link>
                                                                                    </Label>
                                                                                ),
                                                                        }
                                                                    );
                                                                }
                                                            }
                                                        }}
                                                        placeholder="Enter email"
                                                        error={error?.message}
                                                        fluid
                                                        readOnly={
                                                            selectedOption
                                                                ?.emails?.length
                                                        }
                                                    />
                                                )}
                                            />
                                        </Grid.Column>
                                    </Grid>
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Form.Field
                                    control={"div"}
                                    required
                                    label="Address"
                                >
                                    {selectedOption?.locations?.length ? (
                                        <Controller
                                            control={control}
                                            name={"address"}
                                            render={({
                                                field: {
                                                    name,
                                                    value,
                                                    onChange,
                                                },
                                            }) => (
                                                <Select
                                                    options={
                                                        selectedOption.locations
                                                    }
                                                    loading={
                                                        isReadingLocationOptions
                                                    }
                                                    value={value}
                                                    onChange={(
                                                        _,
                                                        { value }
                                                    ) => {
                                                        onChange({
                                                            target: {
                                                                name,
                                                                value,
                                                            },
                                                        });
                                                    }}
                                                    selectOnBlur={false}
                                                    fluid
                                                    {...(type === "billing"
                                                        ? {
                                                              open: false,
                                                              icon: null,
                                                          }
                                                        : {})}
                                                />
                                            )}
                                        />
                                    ) : (
                                        <Grid columns="equal">
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Controller
                                                        name={`address.label`}
                                                        control={control}
                                                        render={({
                                                            field: {
                                                                name,
                                                                onChange,
                                                                value,
                                                            },
                                                            fieldState: {
                                                                error
                                                            }
                                                        }) => (
                                                            <Form.Select
                                                                name={name}
                                                                value={value}
                                                                onChange={(
                                                                    _,
                                                                    { value }
                                                                ) => {
                                                                    onChange({
                                                                        target: {
                                                                            name,
                                                                            value,
                                                                        },
                                                                    });
                                                                }}
                                                                placeholder="Select Address Label"
                                                                selectOnBlur={
                                                                    false
                                                                }
                                                                options={LocationUtils.getAddressLabelOptions()}
                                                                fluid
                                                                error={error?.message}
                                                            />
                                                        )}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Controller
                                                        name={`address.street`}
                                                        control={control}
                                                        render={({
                                                            field: {
                                                                name,
                                                                onChange,
                                                                value,
                                                            },
                                                            fieldState: {
                                                                error,
                                                            },
                                                        }) => (
                                                            <Form.Input
                                                                name={name}
                                                                value={value}
                                                                onChange={(
                                                                    _,
                                                                    { value }
                                                                ) => {
                                                                    onChange({
                                                                        target: {
                                                                            name,
                                                                            value,
                                                                        },
                                                                    });
                                                                }}
                                                                placeholder="Street Address"
                                                                error={
                                                                    error?.message
                                                                }
                                                                fluid
                                                            />
                                                        )}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Controller
                                                        name={`address.additional_street`}
                                                        control={control}
                                                        render={({
                                                            field: {
                                                                name,
                                                                onChange,
                                                                value,
                                                            },
                                                            fieldState: {
                                                                error,
                                                            },
                                                        }) => (
                                                            <Form.Input
                                                                name={name}
                                                                value={value}
                                                                onChange={(
                                                                    _,
                                                                    { value }
                                                                ) => {
                                                                    onChange({
                                                                        target: {
                                                                            name,
                                                                            value,
                                                                        },
                                                                    });
                                                                }}
                                                                placeholder="Apt, unit building, floor #"
                                                                error={
                                                                    error?.message
                                                                }
                                                                fluid
                                                            />
                                                        )}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Controller
                                                        name={`address.city`}
                                                        control={control}
                                                        render={({
                                                            field: {
                                                                name,
                                                                onChange,
                                                                value,
                                                            },
                                                            fieldState: {
                                                                error,
                                                            },
                                                        }) => (
                                                            <Form.Input
                                                                name={name}
                                                                value={value}
                                                                onChange={(
                                                                    _,
                                                                    { value }
                                                                ) => {
                                                                    onChange({
                                                                        target: {
                                                                            name,
                                                                            value,
                                                                        },
                                                                    });
                                                                }}
                                                                placeholder="City"
                                                                error={
                                                                    error?.message
                                                                }
                                                                fluid
                                                            />
                                                        )}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Controller
                                                        name={`address.state`}
                                                        control={control}
                                                        render={({
                                                            field: {
                                                                name,
                                                                onChange,
                                                                value,
                                                            },
                                                            fieldState: {
                                                                error,
                                                            },
                                                        }) => (
                                                            <Form.Select
                                                                name={name}
                                                                value={value}
                                                                onChange={(
                                                                    _,
                                                                    { value }
                                                                ) => {
                                                                    onChange({
                                                                        target: {
                                                                            name,
                                                                            value,
                                                                        },
                                                                    });
                                                                }}
                                                                placeholder="State"
                                                                search
                                                                options={STATES.map(
                                                                    (
                                                                        state
                                                                    ) => ({
                                                                        ...state,
                                                                        value: state.key,
                                                                    })
                                                                )}
                                                                error={
                                                                    error?.message
                                                                }
                                                                fluid
                                                            />
                                                        )}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Controller
                                                        name={`address.zip`}
                                                        control={control}
                                                        render={({
                                                            field: {
                                                                name,
                                                                onChange,
                                                                value,
                                                            },
                                                            fieldState: {
                                                                error,
                                                            },
                                                        }) => (
                                                            <Form.Input
                                                                name={name}
                                                                value={value}
                                                                onChange={(
                                                                    _,
                                                                    { value }
                                                                ) => {
                                                                    onChange({
                                                                        target: {
                                                                            name,
                                                                            value,
                                                                        },
                                                                    });
                                                                }}
                                                                error={
                                                                    error?.message
                                                                }
                                                                placeholder="Zipcode"
                                                                fluid
                                                            />
                                                        )}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            {customerField.value ===
                                                "shipping-contact" &&
                                                !Boolean(
                                                    selectedOption?.locations
                                                        ?.length
                                                ) && (
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <Controller
                                                                control={
                                                                    control
                                                                }
                                                                name={
                                                                    "save_address"
                                                                }
                                                                render={({
                                                                    field: {
                                                                        name,
                                                                        value,
                                                                        onChange,
                                                                    },
                                                                }) => (
                                                                    <Form.Checkbox
                                                                        label={`Save address on account`}
                                                                        checked={
                                                                            value
                                                                        }
                                                                        onChange={(
                                                                            _,
                                                                            {
                                                                                checked,
                                                                            }
                                                                        ) => {
                                                                            onChange(
                                                                                {
                                                                                    target: {
                                                                                        name,
                                                                                        value: checked,
                                                                                    },
                                                                                }
                                                                            );
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                )}
                                        </Grid>
                                    )}
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </Form>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Button
                            floated="right"
                            color="primary"
                            disabled={
                                !isValid ||
                                hasErrors ||
                                (type !== "billing" && !isDirty) ||
                                isUpdatingAddress
                            }
                            onClick={handleSubmit(onUpdateAddress)}
                            loading={isUpdatingAddress}
                        >
                            Save
                        </Form.Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
};

export default OrderAddress;
