import React from 'react';
import { Sidebar } from 'semantic-ui-react';

import { OfficeView, Favorites, Recents } from 'pages';
import { ChatRoom, CloseableContent } from 'dyl-components';

import './index.scss';

export default function SecondarySideBar({
    isSidebarOpen,
    isActiveSecondarySidebar,
    onCloseSecondarySidebar
}) {
    return (
        <React.Fragment>
            <Sidebar
                animation='push'
                icon='labeled'
                vertical
                visible={isActiveSecondarySidebar('favorites')}
                className={`SideBar2 SideBar2--${isSidebarOpen ? 'open' : 'closed'}`}
                style={{ width: 380 }}
            >
                <div className='SidebarContainer'>
                    <CloseableContent onClose={onCloseSecondarySidebar}>
                        {isActiveSecondarySidebar("favorites") ? <Favorites /> : <></>}
                    </CloseableContent>
                </div>
            </Sidebar>

            <Sidebar
                animation='push'
                icon='labeled'
                vertical
                visible={isActiveSecondarySidebar('recents')}
                className={`SideBar2 SideBar2--${isSidebarOpen ? 'open' : 'closed'}`}
                style={{ width: 380 }}
            >
                <div className='SidebarContainer'>
                    <CloseableContent onClose={onCloseSecondarySidebar}>
                        {isActiveSecondarySidebar("recents") ? <Recents /> : <></>}
                    </CloseableContent>
                </div>
            </Sidebar>

            <Sidebar
                animation='push'
                icon='labeled'
                vertical
                visible={isActiveSecondarySidebar('view')}
                className={`SideBar2 SideBar2--wide SideBar2--${isSidebarOpen ? 'open' : 'closed'}`}>
                <OfficeView isActive={isActiveSecondarySidebar('view')} onClose={onCloseSecondarySidebar}/>
            </Sidebar>

            <Sidebar
                animation='push'
                icon='labeled'
                vertical
                visible={isActiveSecondarySidebar('chat')}
                className={`SideBar2 SideBar2--wide SideBar2--${isSidebarOpen ? 'open' : 'closed'}`}>
                <ChatRoom />
            </Sidebar>
        </React.Fragment>
    )
}
