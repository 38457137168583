import { RecordsTable } from "dyl-components";
import { useSelector } from "react-redux";
import OutstandingInvoicesTableHeader from "./OutstandingInvoicesTableHeader";
import OutstandingInvoice from "./OutstandingInvoice";
import QuoteBuilderModal from "shared/modals/QuoteBuilderModal";
import QuoteBuilderProvider from "shared/context/QuoteBuilderProvider";

const OutstandingInvoicesTable = () => {
    //TODO: replace with actual data;
    const { invoices, count } = useSelector(() => ({
        invoices: [],
        count: 0,
    }));

    const isReadingInvoices = false;
    const customer_id = useSelector((state) => state.auth.customer_id);

    return (
        <QuoteBuilderProvider>
            <RecordsTable
                tableBodyClassName="OutstandingInvoicesTable"
                isTableLoading={isReadingInvoices}
                isTableEmpty={count === 0}
                TableHeader={<OutstandingInvoicesTableHeader />}
                TableRows={invoices.map((invoice) => (
                    <OutstandingInvoice
                        key={invoice.id}
                        id={invoice.id}
                        amount_due={invoice.amount_due}
                        date={invoice.date}
                        name={invoice.name}
                        status={invoice.status}
                    />
                ))}
                recordsCount={count}
                hasPagination={false}
                recordsName="Invoice"
                emptySubtitle=" "
                emptyListTitle={"No Open Invoices"}
            />
            <QuoteBuilderModal 
                account_id={customer_id}
            />
        </QuoteBuilderProvider>
    );
};

export default OutstandingInvoicesTable;
