import { yup } from "dyl-components";

export const upsellSchema = {
    cart: yup
        .array()
        .min(1, "There should be at least one item selected")
        .test("at_least_one_item_selected", "There should be at least one item selected", (cart, context) => {
            const { pricing_schedule } = context.parent;
            if (!pricing_schedule) {
                return true;
            }
            return cart.filter(item => {
                return !item.pricing_schedule || item?.schedules?.includes(pricing_schedule);
            }).length
        })
        .test(
            "should_have_matching_pricing_schedule",
            "Pricing schedules for all recurring products should match",
            (items) => {
                return [...new Set(items.filter(item => item.schedules?.length).map(item => {
                    return item.pricing_schedule
                }))].length <= 1;
            }
        ),
}
