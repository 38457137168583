import React from 'react';
import { CompanyProfile } from 'pages';

const PARENT_NAME = "account_management";

export const ACCOUNT_MANAGEMENT_SETTINGS = [
    { 
        path: `company-profile`, 
        icon: 'fas fa-building', 
        header: 'Company Profile', 
        description: 'Complete your company contact info and email signature.',
        page: CompanyProfile,
        type: `${PARENT_NAME}.company_profile`
    },
    { 
        path: `billing/overview`, 
        icon: 'fas fa-credit-card', 
        header: 'Billing', 
        description: 'Access your billing information.',
        page: null,
        type: `${PARENT_NAME}.billing`
    },
    { 
        path: `usage-stats`, 
        icon: 'fas fa-chart-line', 
        header: 'Usage Stats', 
        description: 'View your usage stats.',
        page: React.Fragment,
        type: `${PARENT_NAME}.usage_stats`
    }
];
