import React from 'react';
import { Segment, Grid, TextArea, Form, Button, Dropdown, Icon, Dimmer, Loader, Popup, Transition } from 'semantic-ui-react';
import TemplateSelector, { TEMPLATE_TYPES } from 'shared/forms/TemplateSelector';
import text_templatesActions from 'actions/text_templates';

import './index.scss';
import { connect } from 'react-redux';
import textActions from 'actions/text';
import { STATUS_TYPES, Notification } from 'dyl-components';
import { DateTimeUtils } from 'dyl-components';
import messageEditorActions from 'actions/message_editor';
import ScheduleLaterModal from 'shared/modals/ScheduleLaterModal';
import ShortcodeButtons from 'shared/ShortcodeButtons';

const LIMIT = 160;

const MessageEditor = ({
    name,
    value,
    onChange,
    selectedTemplate,
    onClearMessage,
    onSelectTemplateChange,
    isReadingTemplate,
    onSendSMSText,
    isSendingSMSText,
    isReadingPhones,
    isReadingContact,
    fileInputRef = React.createRef(),
    onSelectFile,
    fileName,
    isScheduleLaterModalOpen,
    onOpenScheduleLaterModal,
    onCloseScheduleLaterModal,
    isShortcodeOpen,
    onToggleShortCode,
    onAddShortcode,
    currentlyFocusedElement,
    limit = LIMIT
}) => (
        <Segment className={`MessageEditor MessageEditor--${isShortcodeOpen ? 'expanded' : ''}`} loading={isReadingContact || isReadingPhones || isSendingSMSText}>
            <Grid columns='equal' padded stackable>
                <Grid.Column width={11}  >
                    <Grid.Row style={{ paddingBottom: 10 }}>
                        <Form>
                            <TextArea
                                as={'textarea'}
                                className='MessageEditor__TextArea'
                                name={name}
                                value={value}
                                onChange={onChange}
                            />
                        </Form>
                    </Grid.Row>
                    <Grid>
                        <Grid.Row columns='2'>
                            <Grid.Column>
                                <span onClick={onToggleShortCode} style={{ fontSize: 10, cursor: 'pointer', color: '#2979ff', lineHeight: '18px', fontWeight: 400 }}>
                                    {isShortcodeOpen ? 'Hide' : 'Shortcodes'}
                                </span>
                            </Grid.Column>
                            <Grid.Column textAlign='right'>
                                <span style={{ fontSize: 10, color: '#8181a5', lineHeight: '18px', fontWeight: 400 }}>
                                    {limit} characters limit
                            </span>
                            </Grid.Column>
                        </Grid.Row>

                        <Transition visible={isShortcodeOpen} animation='slide down' duration='150' unmountOnHide>
                            <Grid.Row>
                                <Grid.Column>
                                    <ShortcodeButtons onAddShortcode={onAddShortcode} currentlyFocusedElement={currentlyFocusedElement} />
                                </Grid.Column>
                            </Grid.Row>
                        </Transition>
                    </Grid>
                    <Dimmer active={isReadingTemplate} inverted>
                        <Loader active inverted />
                    </Dimmer>
                </Grid.Column>
                <Grid.Column width={5} >
                    <Grid.Row style={{ paddingBottom: 8 }}>
                        <Button.Group className='MessageEditor__TemplatesAttachments' style={{ width: 174 }}>
                            <TemplateSelector
                                header='Template'
                                type={TEMPLATE_TYPES.SMS}
                                selectedTemplate={selectedTemplate}
                                onChange={onSelectTemplateChange}
                            />
                        </Button.Group>
                    </Grid.Row>
                    <Grid.Row style={{ paddingBottom: 28 }}>
                        <Popup
                            content='We support JPG, PNG, GIF, PJP, JFIF, SVG, BMP, SVGZ, TIFF, WEBP, ICO, XBM, DIB, TIF, PJPEG, AVIF uploads'
                            trigger={
                                <Button.Group className='MessageEditor__TemplatesAttachments' style={{ width: 174 }}>
                                    <Button>
                                        <Icon name='attach' style={{ paddingRight: 34 }} />
                                    Attachment:
                                    {fileName}
                                    </Button>
                                    <Dropdown
                                        className='button icon'
                                        floating
                                        options={[
                                            { key: 'new_attachment', text: 'Attach New File', value: 'new_attachment', onClick: () => { fileInputRef.current.click() } },
                                            { key: 'saved_image', text: 'SELECT a Saved File', value: 'saved_image', },
                                        ]}
                                        trigger={<React.Fragment />}
                                    />

                                </Button.Group>}
                            position='right center'
                            inverted
                        />
                        <input
                            ref={fileInputRef}
                            name="file"
                            type="file"
                            hidden
                            accept="image/*"
                            onChange={onSelectFile}
                        />

                    </Grid.Row>
                    <Grid.Row style={{ display: 'inline-flex' }}>
                        <Button icon={<Icon className='far fa-eraser' />} onClick={onClearMessage} className='MessageEditor__Delete' />
                        <Button.Group color='primary' style={{ height: 36, borderRadius: 4, width: 110 }}>
                            <Button className='MessageEditor__Send' onClick={onSendSMSText} loading={isSendingSMSText}>Send</Button>
                            {/* <Dropdown
                                className='button icon'
                                floating
                                options={[
                                    { key: 'now', text: 'Send Now', value: 'now' }]}
                                trigger={<React.Fragment />}
                            /> */}
                        </Button.Group>
                        <ScheduleLaterModal open={isScheduleLaterModalOpen} onClose={onCloseScheduleLaterModal} />
                    </Grid.Row>
                </Grid.Column>
            </Grid>
        </Segment>
    );

class MessageEditorContainer extends React.Component {

    state = {
        isScheduleLaterModalOpen: false,
        isShortcodeOpen: false,
        currentlyFocusedElement: null
    }

    onAddShortcode = (_, value) => {
        const { message } = this.props;
        this.props.onChangeMessage(_, { value: message.concat(value) });
    }

    onSelectTemplateChange = (_, { value }) => {
        this.props.onReadTemplate(value)
            .then(({ message, id }) => {
                this.props.onSelectTemplateChange(message, id);
            }).catch(error => {
                console.log(error);
            });
    }

    onSelectFile = e => {
        const file = e.target.files[0];
        if (file) {
            this.props.onSelectFile(file);
        }
    };

    onSendSMSText = () => {
        let to = "NA";
        if (this.props.to) {
            to = `${this.props.to}`
        }
        const from = `${this.props.from_phone}`
        return (() => {
            if (!this.props.textPhones.some(textPhone => textPhone && `${textPhone.phone}` === from)) {
                return this.props.onAddPhone([
                    { carrier: 'bandwidth', messaging_type: 'p2p', phone: from }
                ]).then(() => {
                    return this.props.onReadTextPhones();
                });
            }
            return Promise.resolve();
        })().then(() => {
            const { file, message } = this.props;
            const textBody = {
                from,
                to,
                message,
                contact_id: this.props.contact_id
            }
            if (file) {
                return this.props.onSendMMSText(file, textBody)
            }
            return this.props.onSendSMSText(textBody);
        }).then(() => {
            this.props.onReadMessages(this.props.contact_id, this.props.to);
            this.props.onClearMessage();
        }).catch(error => {
            console.log(error);
            Notification.alert('Failed to send message', STATUS_TYPES.ERROR, true);
        })
    }

    onOpenScheduleLaterModal = () => {
        this.setState({
            isScheduleLaterModalOpen: true
        })
    }

    onCloseScheduleLaterModal = () => {
        this.setState({
            isScheduleLaterModalOpen: false
        })
    }

    onToggleShortCode = () => {
        this.setState(prevState => ({
            isShortcodeOpen: !prevState.isShortcodeOpen
        }))
    }

    render() {
        return (
            <MessageEditor
                name='message'
                onSelectTemplateChange={this.onSelectTemplateChange}
                selectedTemplate={this.props.selectedTemplate}
                value={this.props.message}
                onChange={this.props.onChangeMessage}
                limit={LIMIT - this.props.message.length}
                onClearMessage={this.props.onClearMessage}
                onSendSMSText={this.onSendSMSText}
                isSendingSMSText={this.props.isSendingSMSText}
                isReadingPhones={this.props.isReadingPhones}
                isReadingContact={this.props.isReadingContact}
                isReadingTemplate={this.props.isReadingTemplate}
                fileName={this.props.file ? this.props.file.name : ''}
                onSelectFile={this.onSelectFile}
                isScheduleLaterModalOpen={this.state.isScheduleLaterModalOpen}
                onOpenScheduleLaterModal={this.onOpenScheduleLaterModal}
                onCloseScheduleLaterModal={this.onCloseScheduleLaterModal}
                isShortcodeOpen={this.state.isShortcodeOpen}
                currentlyFocusedElement={this.state.currentlyFocusedElement}
                onAddShortcode={this.onAddShortcode}
                onToggleShortCode={this.onToggleShortCode}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        isReadingTemplate: state.text_template.isReadingTemplate,
        phones: state.contact_phone.phone,
        isSendingSMSText: state.text.isSendingSMSText || state.image.isUploadingMMSPayload || state.text.isSendingSMSText || state.text.isCreatingPhone,
        contact_id: Number(state.contact.contact_id),
        textPhones: state.text.phones,
        isReadingContact: state.contact.isReadingContact,
        isReadingPhones: state.text.isReadingPhones,
        to: state.text_ui.to,

        message: state.message_editor.message,
        selectedTemplate: state.message_editor.selectedTemplate,
        from_phone: state.message_editor.from_phone,
        file: state.message_editor.file,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onReadTemplate: (templateId) => {
            return dispatch(text_templatesActions.getTemplate(templateId));
        },
        onSendSMSText: (smsBody) => {
            return dispatch(textActions.sendSMSText(smsBody));
        },
        onSendMMSText: (attachment, textBody) => {
            //deprecated
            return dispatch(textActions.uploadMMSPayload(attachment))
                .then(({ id, url }) => {
                    return dispatch(textActions.sendMMSText({
                        ...textBody, attachments: [
                            { url, id }
                        ]
                    }));
                })
        },
        onAddPhone: (phones) => {
            return dispatch(textActions.createPhone(phones));
        },
        onReadTextPhones: () => {
            return dispatch(textActions.getPhones());
        },
        onReadMessages: (contact_id, contact_phone) => {
            const queryParameters = { contact_phone, start: DateTimeUtils.getLast(3, 'years'), end: DateTimeUtils.getCurrentDate(DateTimeUtils.DATE_FORMAT, true, false) };
            dispatch(textActions.getMessages(contact_id, queryParameters));
            dispatch(textActions.getFilteredMessages(contact_id, queryParameters));
        },
        onSelectTemplateChange: (message, id) => {
            dispatch(messageEditorActions.onSelectTemplateChange(message, id));
        },
        onChangeMessage: (_, { value }) => {
            dispatch(messageEditorActions.onChangeMessage(value));
        },
        onClearMessage: () => {
            dispatch(messageEditorActions.onClearMessage());
        },
        onSelectFile: (file) => {
            dispatch(messageEditorActions.onSelectFile(file));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageEditorContainer);
