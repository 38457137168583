import tasksActions from 'actions/tasks';
import { TimelineTabForm, TimelineTabHeader, DateTimeUtils } from 'dyl-components';
import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Header, Icon, Loader } from 'semantic-ui-react';
import './index.scss';
import taskActions from 'actions/task';

import TaskTimeline from './subcomponents/Timeline';
import TaskForm from './subcomponents/TaskForm';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useTaskForm from 'utils/useTaskForm';

const TasksTab = ({
    onReadTasks,

    onReadTask,

    counts,
    tasks,
    isReadingTasks,

    isReadingTask,
    task_labels,
    isReadingTaskLabels,

    organizer,
    organizer_email,
    organizer_id,

    isDeletingTask,
    isUpdatingTask,

    refreshTasksSidePanel,

    accountContactIds,
    contactName
}) => {

    const [params] = useSearchParams();
    const status = params.get('status') || 'all';

    const { pathname } = useLocation();
    const record_type = pathname.split('/')[1];
    let { account_id, contact_id } = useParams();
    const associated_account = useSelector(state => state.contact.account_id);
    const record_id = Number(contact_id || account_id); //only for routing

    const isContact = record_type === 'contact';
    account_id = !isContact ? Number(account_id) : associated_account !== 0 ? associated_account : 0;
    contact_id = [Number(contact_id)];
    const contact_ids = isContact ? contact_id : accountContactIds.map(({ id }) => id);     //only for contact relations



    const {
        control,
        watch,
        isValid,
        isDirty,
        trigger,
        setValue,
        getValues,
        clearErrors,
        setError,
        reset,
        taskBeingEdited,
        loadTask,
        addTask,
        state,
        setState,
        onDelete,
        onUpdate,
        formatTaskBeingEdited,
        isAllowedToModify
    } = useTaskForm({isContact});

    useEffect(() => {
        onReadTasks(contact_id, account_id, isContact, {
            ...Object.fromEntries(params),
            status: status === 'overdue' ? 'missed' : status
        });
        if (isContact) {
            refreshTasksSidePanel(contact_id, account_id, isContact);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onReadTasks, params, status]);

    const navigate = useNavigate();

    const routeRoot = `/${record_type}/${record_id}/tasks`;

    const handleItemClick = (_, { name }) => {
        const query = new URLSearchParams();
        query.set('status', name);
        const query_string = query.toString();
        navigate(`${routeRoot}${query_string ? `?${query_string}` : ''}`);
    }

    const onToggleFormTab = () => {
        setState({
            isFormTabOpen: !state.isFormTabOpen,
            formKey: 'CREATE_FORM'
        })
        loadTask(null)
    }

    const onEditTask = (id) => {
        onReadTask(id).then((task) => {
            const taskBeingEdited = formatTaskBeingEdited(id, task);
            setState({
                ...state,
                formKey: 'EDIT_FORM',
                created_user_id: task.created_user_id
            });
            loadTask(taskBeingEdited);
        })
    }

    const onRefresh = () => {
        onReadTasks(contact_id, account_id, isContact);
        loadTask(null);
        navigate(`${routeRoot}`);
        navigate(`${routeRoot}?status=all`);
    }

    return (
        <React.Fragment>
            <TimelineTabForm
                timelineHeader={
                    <Header className='TasksTab__Header' as='h2'>
                        {isContact && <Icon className='TimelineTab__add' name='plus' color='blue' link circular inverted size='tiny' onClick={onToggleFormTab} />}
                        Tasks
                    </Header>
                }
                timelineSubHeader={
                    <TimelineTabHeader
                        activeItem={status}
                        titleCounts={counts}
                        onClick={handleItemClick}
                    />
                }
                timelineBody={
                    <TaskTimeline
                        expanded={state.isFormTabOpen}
                        tasks={tasks}
                        onEditTask={onEditTask}
                        isReadingTasks={isReadingTasks}
                        isContact={isContact}
                    />
                }
                formHeader={
                    isContact || taskBeingEdited.id ? (
                        <Header className='TasksForm__Header'>
                            {!isReadingTask ? taskBeingEdited.id ? `${isAllowedToModify ? 'Edit' : 'View'} '${taskBeingEdited.name}'` : 'New Task' : 'Loading...'}
                        </Header>
                    ) : null
                }
                formBody={
                    isContact || taskBeingEdited.id ? (
                        !isReadingTask ? (
                            <TaskForm
								relatedTo={state.relatedTo}
                                key={state.formKey}
                                mode={state.formKey}
                                isAllowedToModify={isAllowedToModify}
                                onClose={onToggleFormTab}
                                refresh={() => {
                                    onReadTasks(contact_id, account_id, isContact);
                                }}
                                {...taskBeingEdited}
                                onSave={(values) => addTask(values, onRefresh, contactName)}
                                onDelete={(values) => onDelete(values, onRefresh, contactName)}
                                onUpdate={(values) => onUpdate(values, onRefresh, contactName)}
                                isSaving={state.saving}
                                isDeleting={isDeletingTask}
                                isReading={isReadingTask}
								contact_ids={contact_ids}
                                taskTypes={[
                                    {
                                        key: 'To-Do', value: 'To-Do', text: (
                                            <div>
                                                <Icon className='fad fa-check-circle' color='blue' /> To Do
                                            </div>
                                        )
                                    },
                                    {
                                        key: 'Call', value: 'Call', text: (
                                            <div>
                                                <Icon flipped className='fas fa-circle-phone' color='blue' /> Call
                                            </div>
                                        )
                                    },
                                    {
                                        key: 'Text', value: 'Text', text: (
                                            <div>
                                                <Icon className='fas fa-comment' color='blue' /> Text
                                            </div>
                                        )
                                    },
                                    {
                                        key: 'Email', value: 'Email', text: (
                                            <div>
                                                <Icon className='fas fa-circle-envelope' color='blue' /> Email
                                            </div>
                                        )
                                    }
                                ]}
                                task_labels={task_labels}
                                isReadingLabels={isReadingTaskLabels}
                                searchValue={organizer}
                                recordType={record_type}
                                organizer={organizer}
                                organizer_email={organizer_email}
                                organizer_id={organizer_id}
                                control={control}
                                watch={watch}
                                isValid={isValid}
                                isDirty={isDirty}
                                trigger={trigger}
                                setValue={setValue}
                                getValues={getValues}
                                clearErrors={clearErrors}
                                setError={setError}
                                reset={reset}
                            />
                        ) : (
                            <Loader active />
                        )
                    ) : null
                }
                //primary-table-6
                color='#214BD6'
                loading={isReadingTasks || isUpdatingTask}
                expanded={state.isFormTabOpen}
                {...(isContact ? {} : { timelineSectionWidth: 9 })}
            />
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    const { first_name = '', last_name = '', email: organizer_email } = state.users.userProfile;
    const contactName = `${state.contact.contact?.first_name} ${state.contact.contact?.last_name}`;
    return ({
        organizer_id: state.auth.user_id,
        organizer: `${first_name} ${last_name}`,
        organizer_email,
        isReadingTasks: state.tasks.isReadingTasksTab,
        isUpdatingTask: state.task.isUpdatingTask,
        isCreatingTask: state.tasks.isCreatingTask,
        contact_id: Number(state.contact.contact_id),
        contactName,
        queryParameters: state.tasks.queryParameters,
        counts: [
            { title: 'all', count: state.tasks.all },
            { title: 'complete', count: state.tasks.complete },
            { title: 'overdue', count: state.tasks.missed },
            { title: 'upcoming', count: state.tasks.upcoming }
        ],
        tasks: state.tasks.tasks_tab_tasks,
        taskTypes: state.task_types.taskTypes,
        isReadingTaskTypes: state.task_types.isReadingTaskTypes,
        user_id: state.auth.user_id,
        isReadingTask: state.task.isReadingTask,
        isDeletingTask: state.task.isDeletingTask,
        task_labels: state.task.task_type_labels.map(({ name, id }) => ({
            key: id, value: id, text: name
        })),
        isReadingTaskLabels: state.task.isReadingTaskTypeLabels,
        accountContactIds: state.account.contactIds.map(({ id, first_name, last_name, suffix = '', email }) => ({
            id,
            first_name,
            last_name,
            suffix,
            email
        })),
        accountContacts: state.account.contactIds
    });
};

const mapDispatchToProps = dispatch => ({
    refreshTasksSidePanel: (contact_ids, account_id, isContact) => {
        const unixCurrentDate = DateTimeUtils.getCurrentDate(null, true, false)
        const end = DateTimeUtils.addOneYear(unixCurrentDate);
        const start = DateTimeUtils.subtractOneYear(unixCurrentDate);
        dispatch(tasksActions.readTasks({ 
            status: 'all', 
            ...(isContact ? {contact_ids} : {}),
            ...(!isContact ? {account_id} : {}),
            limit: 25,
            start,
            end,
            deleted: false 
        }))
    },
    onReadTasks: (contact_ids, account_id, isContact, query = {}) => {
        if (contact_ids?.length) {
            const unixCurrentDate = DateTimeUtils.getCurrentDate(null, true, false)
            const end = DateTimeUtils.addOneYear(unixCurrentDate);
            const start = DateTimeUtils.subtractOneYear(unixCurrentDate);
            dispatch(tasksActions.loadTasksTab({ 
                ...query, 
                ...(isContact ? {contact_ids} : {}),
                ...(!isContact ? {account_id} : {}),
                limit: 25,
                start, 
                end,
                deleted: false 
            }))
        }
    },
    onUpdateTask: (id, payload) => {
        return dispatch(taskActions.updateTask(id, payload));
    },
    onCompleteTask: (id, payload) => {
        return dispatch(taskActions.completeTask(id, payload));
    },
    onAddTask: (tasks) => {
        return dispatch(tasksActions.createTask(tasks));
    },
    onReadTask: (id) => {
        return dispatch(taskActions.readTask(id));
    },
    onDeleteTask: (id) => {
        return dispatch(taskActions.deleteTask(id));
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(TasksTab);
