import routes from "actions/routes";
import uploadActions from "actions/upload";
import ACTION_NAMES from "actions/ACTION_NAMES";
import {
    generateReadActionCreator,
    generateCreateActionCreator,
    generateReadOneActionCreator,
    generateUpdateActionCreator,
    generateDeleteActionCreator
} from "actions/crudActionCreators";

export default {
    readForms: generateReadActionCreator(routes.API_WEBFORMS, ACTION_NAMES.WEBFORMS),
    createForms: generateCreateActionCreator(routes.API_WEBFORMS, ACTION_NAMES.WEBFORMS),
    readForm: generateReadOneActionCreator(routes.API_WEBFORMS, ACTION_NAMES.WEBFORMS),
    updateForm: generateUpdateActionCreator(routes.API_WEBFORMS, ACTION_NAMES.WEBFORMS),
    deleteForm: generateDeleteActionCreator(routes.API_WEBFORMS, ACTION_NAMES.WEBFORMS),
    updateStatus: generateUpdateActionCreator(routes.API_WEBFORMS, ACTION_NAMES.WEBFORM_STATUS, () => {}, () => {}, 'active'),
    
    publishForm: (id, { name, form_html, cdn_id }) => async dispatch => {
        const formFile = new File(form_html.split("\n"), name + ".html",{type:"text/html"});
        let dispatchResult;
        try {
            dispatchResult = dispatch(uploadActions.uploadFiles([formFile], "html", [cdn_id]));
        } catch (e) {
            console.log("ERROR", e)
        }
        return dispatchResult;
    }
};
