import { DateInput, DateTimeUtils, LinkedAccount, Modal } from "dyl-components";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { Dropdown, Form, Grid, Icon } from "semantic-ui-react";
import TermField from "./TermField";
import { useState } from "react";

const PaymentInformation = () => {
    const { control } = useFormContext();
    const order = useSelector((state) => state.order.order);
    const [isTermTypesInfoShowing, setIsTermTypesInfoShowing] = useState(false);
    return (
        <>
            <Form.Group widths={3}>
                <Controller
                    control={control}
                    name="invoice_name"
                    render={({
                        field: { name, value, onChange },
                        fieldState: { error },
                    }) => (
                        <Form.Input
                            label="Invoice Name"
                            value={value}
                            name={name}
                            onChange={(_, { value }) => {
                                onChange({ target: { name, value } });
                            }}
                            error={error?.message}
                        />
                    )}
                />
            </Form.Group>
            <Form.Group widths={"equal"}>
                <Form.Field label="Customer Name" required control="div">
                    <Dropdown
                        placeholder="Select Customer"
                        selectOnBlur={false}
                        selection
                        options={[]}
                        value={null}
                        fluid
                        required
                        disabled
                        {...(order?.account
                            ? {
                                  text: (
                                      <LinkedAccount
                                          account={order?.account?.name}
                                          maxWidth={"8.25em"}
                                      />
                                  ),
                              }
                            : {})}
                    />
                </Form.Field>
                <Form.Field label="Date" control="div">
                    <DateInput
                        dateFormat={DateTimeUtils.WORD_DATE_FORMAT}
                        value={DateTimeUtils.getCurrentDate(
                            DateTimeUtils.WORD_DATE_FORMAT
                        )}
                        disabled
                    />
                </Form.Field>
                <Controller
                    control={control}
                    name="due_on"
                    render={({
                        field: { name, value, onChange },
                        fieldState: { error },
                    }) => (
                        <Form.Field
                            control={DateInput}
                            dateFormat={DateTimeUtils.WORD_DATE_FORMAT}
                            value={value}
                            label="Due On"
                            required
                            onChange={(_, { value }) => {
                                onChange({ target: { name, value } });
                            }}
                            error={error?.message}
                        />
                    )}
                />
            </Form.Group>
            <Form.Group widths={3}>
                <Controller
                    control={control}
                    name="term"
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Field
                            control={TermField}
                            label={
                                <label>
                                    Term
                                    <Icon
                                        onClick={() => {setIsTermTypesInfoShowing(true)}}
                                        style={{ float: "right" }}
                                        name="fas fa-info-circle"
                                        color="primary"
                                        link
                                    />
                                </label>
                            }
                            required
                            value={value}
                            onChange={(_, { value }) => {
                                onChange({ target: { name, value } });
                            }}
                        />
                    )}
                />
            </Form.Group>
            <Modal open={isTermTypesInfoShowing} onClose={() => {setIsTermTypesInfoShowing(false)}}>
                <Modal.Header>Term Types</Modal.Header>
                <Modal.Content>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <strong>Default:</strong> Default takes into account of different billing schedules
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <strong>Trial:</strong> Trial with credit card
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <strong>Net 15, 30, or 60:</strong> Payment expected within 15, 30, or 60 days after the invoice date
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Content>
            </Modal>
        </>
    );
};

export default PaymentInformation;
