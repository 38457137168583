import { Table } from "dyl-components";
import { Table as SemanticTable } from "semantic-ui-react";

const SubscriptionsTableHeader = ({ selectAllCheckbox }) => {
    return (
        <Table.Row>
            {selectAllCheckbox && (
                <SemanticTable.HeaderCell width={1}>
                    {selectAllCheckbox}
                </SemanticTable.HeaderCell>
            )}
            <SemanticTable.HeaderCell width={5}>
                Products
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={5}>
                User
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={5}>
                Price
            </SemanticTable.HeaderCell>
        </Table.Row>
    )
}

export default SubscriptionsTableHeader;
