import SubscriptionProducts from "pages/SubscriptionProducts";
import { Grid } from "semantic-ui-react";
import AccountBalance from "./AccountBalance";
import PlanDetails from "./PlanDetails";

const BillingOverview = () => {
    return (
        <Grid>
            <Grid.Row columns="equal">
                <Grid.Column>
                    <PlanDetails />
                </Grid.Column>
                <Grid.Column width={6}>
                    <AccountBalance />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <SubscriptionProducts isAdmin />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default BillingOverview;
