import React, { useContext, useEffect, useState } from 'react';
import { Table as SemanticTable, Grid, Segment, Pagination, Header, Icon } from 'semantic-ui-react';

import { ClearableCheckboxDropdown, EmptyListPlaceholder, Modal, SearchBar, Table } from 'dyl-components';
import { Contact } from './Contact';
import accountActions from 'actions/account';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import LinkContactsForm from 'shared/forms/LinkContactsForm';
import ContactProvider, { ContactContext } from 'shared/context/ContactProvider';
import SendEmailModal from 'shared/modals/SendEmailModal';
import { NotesModal } from 'shared/modals';

import oauthActions from 'actions/oauth';

import './index.scss';
import { AccountUtils, StringUtils } from 'utils';

const mapStateToProps = state => ({
    contacts: state.account.contacts,
    contacts_count: state.account.contacts_count,
    isReading: state.account.isReadingContacts,
    primary_contact: state.account.account?.primary_contact,
    account: state.account.account
})

const mapDispatchToProps = dispatch => ({
    readLinkedContacts: (id, queryParameters) => {
        dispatch(accountActions.readContacts(id, queryParameters));
    }
});

const LIMIT = 25;

const LinkedContacts = ({
    contacts,

    contacts_count,
    isReading,
    readLinkedContacts,

    primary_contact,

    account
}) => {
    const dispatch = useDispatch();

    const { account_id } = useParams();

    const routeRoot = `/account/${account_id}/contacts`;

    const [params] = useSearchParams();

    const searchQuery = params.get('search') || '';
    const [search, setSearch] = useState(searchQuery);

    const onChangeSearch = (_, { value }) => {
        setSearch(value);
    }

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(oauthActions.getIntegrations({ scopes: 'email' }));
    }, [dispatch]);

    useEffect(() => {
        readLinkedContacts(account_id, {
            ...Object.fromEntries(params),
            filter: params.get('role')
        });
    }, [account_id, readLinkedContacts, params]);

    const onSearchSubmit = (value) => {
        const query = new URLSearchParams(params);
        query.set('search', value.trim());
        query.set('page', 1);
        const query_string = query.toString();
        navigate(`${routeRoot}${query_string ? `?${query_string}` : ''}`);
    }

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`${routeRoot}${query_string ? `?${query_string}` : ''}`);
    }

    const onClearSearch = () => {
        setSearch('');
        navigate(`${routeRoot}`);
    }

    const [isLinkContactsModalOpen, setLinkContactsModalOpen] = useState(false);

    const openLinkContactsModal = () => {
        setLinkContactsModalOpen(true);
    }

    const closeLinkContactsModal = () => {
        setLinkContactsModalOpen(false);
    }

    const [filters, setFilters] = useState({
        role: params.get('role')?.split(',')
    })

    const onFilter = async (_, { name, value }) => {
        setFilters({
            ...filters,
            [name]: value
        });
    }

    useEffect(() => {
        const query = new URLSearchParams(params);
        if (search.trim()) {
            query.set('search', search);
        } else {
            query.delete('search');
        }
        const { role } = filters;
        if (role?.length > 0) {
            query.set('role', role.join(','));
        } else {
            query.delete('role');
        }
        const query_string = query.toString();

        navigate(`${routeRoot}${query_string ? `?${query_string}` : ''}`,);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    const [contactBeingEdited, setContactBeingEdited] = useState(null);

    const onEdit = (id) => {
        setContactBeingEdited(id);
    }

    const onCancelEdit = () => {
        setContactBeingEdited(null);
    }

    const { config: { modalType, contact }, onCloseModal } = useContext(ContactContext);

    return (
        <>
            <Grid className='LinkedContacts'>
                <Grid.Row verticalAlign='middle'>
                    <Grid.Column floated='left' width={4}>
                        <Header as='h2'>
                            <span>
                                <Icon className='LinkedContacts__add-button' name='plus' color='blue' link circular inverted size='tiny' onClick={openLinkContactsModal} />  {StringUtils.capitalize(account?.account_type || '')} Contacts
                            </span>
                        </Header>
                        <Modal open={isLinkContactsModalOpen} onClose={closeLinkContactsModal}>
                            <LinkContactsForm
                                account_id={account_id}
                                onClose={closeLinkContactsModal}
                            />
                        </Modal>
                    </Grid.Column>
                    <Grid.Column className={`LinkedContacts__search-bar${search.length <= 1 ? ' LinkedContacts__search-bar--no-text' : ''}`} floated='right' width={4}>
                        <SearchBar
                            isSearching={isReading}
                            search={search}
                            searchFcn={onSearchSubmit}
                            cancelFcn={onClearSearch}
                            placeholder={'Search by Contact Name'}
                            onChange={onChangeSearch}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Segment.Group>
                            <Segment loading={isReading} basic style={{ padding: 0 }}>
                                <Table striped={false}>
                                    <SemanticTable.Header className='Table__header--primary'>
                                        <Table.Row>
                                            <SemanticTable.HeaderCell width={1} />
                                            <SemanticTable.HeaderCell width={5}>Name</SemanticTable.HeaderCell>
                                            <SemanticTable.HeaderCell width={3}>
                                                <ClearableCheckboxDropdown
                                                    text='Role'
                                                    name='role'
                                                    onChange={(_, { value }) => { onFilter(_, { name: 'role', value }) }}
                                                    value={params.get('role')?.split(',')}
                                                    options={AccountUtils.roleOptions}
                                                    disabled={isReading}
                                                    loading={isReading}
                                                    closeOnLoad={true}
                                                />
                                            </SemanticTable.HeaderCell>
                                            <SemanticTable.HeaderCell width={3}>Module/Pipeline</SemanticTable.HeaderCell>
                                            <SemanticTable.HeaderCell width={2}>Convert</SemanticTable.HeaderCell>
                                        </Table.Row>
                                    </SemanticTable.Header>
                                    {contacts && (
                                        <Table.Body>
                                            {contacts.map(contact => (
                                                <Contact
                                                    {...contact}
                                                    primary={contact.id === primary_contact?.id}
                                                    contactBeingEdited={contactBeingEdited}
                                                    onCancelEdit={onCancelEdit}
                                                    onEdit={() => { onEdit(contact.id); }}
                                                    key={contact.id} />
                                            ))}
                                        </Table.Body>
                                    )}
                                </Table>
                            </Segment>
                            {!isReading && (
                                <React.Fragment>
                                    {!contacts?.length && !params.get('search') && (
                                        <EmptyListPlaceholder
                                            name='Contact' onClick={openLinkContactsModal} position='top-left' />
                                    )}
                                    {!contacts?.length && params.get('search') && (
                                        <Segment>
                                            <Header as='h3'>
                                                No Results Found
                                            </Header>
                                        </Segment>
                                    )}
                                </React.Fragment>
                            )}
                        </Segment.Group>
                        {contacts_count > 0 && (
                            <Segment basic style={{ padding: 0 }} textAlign='right'>
                                <Pagination
                                    boundaryRange={0}
                                    activePage={params.get('page') || 1}
                                    ellipsisItem={null}
                                    siblingRange={2}
                                    totalPages={Math.ceil(contacts_count / LIMIT)}
                                    onPageChange={onPageChange}
                                    disabled={isReading}
                                />
                            </Segment>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <SendEmailModal
                open={modalType === "email"}
                onClose={() => { onCloseModal() }}
                contact_id={contact}
            />
            <NotesModal
                open={modalType === "note"}
                onClose={() => { onCloseModal() }}
                contact={contact}
            />
        </>
    );
};

const LinkedContactsContainer = (props) => {
    return (
        <ContactProvider>
            <LinkedContacts {...props} />
        </ContactProvider>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkedContactsContainer);
