import React, { useState, useEffect } from 'react';
import Avatar from 'react-avatar';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Header, Icon } from 'semantic-ui-react';
import { generateResolver } from 'dyl-components';

import { UploadPhotoModal } from 'shared/modals';
import { useParams } from 'react-router';

import uploadActions from 'actions/upload';
import userActions from 'actions/user';
import FileUtils, { FILE_CATEGORIES } from 'utils/FileUtils';
import { useForm } from 'react-hook-form';

import './index.scss';

const ProfileHeader = ({
    name,
    profilePicture,
    allowEdit,
    showUploadModal,
    onRemoveUserProfileImage,
    checkImage
}) => {
    const { id } = useParams();
    return (
        <Grid stackable className="ProfileHeader">
            <Grid.Row columns='equal' verticalAlign='middle'>
                <Grid.Column width={1}>
                    <div className={`ProfileHeader__avatar`}>
                        <Avatar name={name} maxInitials={2} src={profilePicture} round size='5.625em' />
                        <div className={`${allowEdit ? 'ProfileHeader__avatar-overlay' : ''}`}>
                            {allowEdit &&
                                <Icon
                                    className={`ProfileHeader__avatar-action-icon ${checkImage ? "ProfileHeader__avatar-action-icon-left" : "ProfileHeader__avatar-action-icon-center"}`}
                                    name="camera"
                                    size="big"
                                    inverted
                                    onClick={showUploadModal} />}
                            {allowEdit && checkImage &&
                                <Icon
                                    className={"ProfileHeader__avatar-action-icon ProfileHeader__avatar-action-icon-right"}
                                    name="trash"
                                    size="big"
                                    inverted
                                    onClick={onRemoveUserProfileImage} />}
                        </div>
                    </div>
                </Grid.Column>
                <Grid.Column {...(id ? { style: { paddingLeft: '2em' } } : {})}>
                    <Header className="ProfileHeader__name">{name}</Header>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

const ProfileHeaderContainer = ({user_id}) => {
    const dispatch = useDispatch();
    const {
        profilePicture,
        name,
        isCurrentUserProfile,
        isAllowedToEditOtherUsersProfilePictures
    } = useSelector((state) => {
        const { first_name = '', last_name = '' } = state.user.userProfile;
        return ({
            profilePicture: state.user.image ? state.user.image  : '',
            name: `${first_name} ${last_name}`,
            isCurrentUserProfile: user_id === state.auth.user_id,
            isAllowedToEditOtherUsersProfilePictures: false // should be replaced by a real permission if we want to use this feature
        });
    })
    const [state, setState] = useState({
        newProfilePicture: '',
        isUploadPhotoModalOpen: false
    });

    const [checkImage, setImageCheck] = useState(false);
    const [isDeletable, setIsDeletable] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.src = profilePicture
        img.onload = () => {
            setImageCheck(true);
            setIsDeletable(true);
        }
        img.onerror = () => {
            setImageCheck(false);
            setIsDeletable(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profilePicture])


    const [isUploading, setIsUploading] = useState(false);

    const { control, formState: { isValid }, getValues, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            attachments: []
        },
        resolver: generateResolver({
            attachments: FileUtils.getFilesizeValidator(FILE_CATEGORIES.PROFILE)
        })
    });

    const onUpload = (file) => {
        return dispatch(uploadActions.uploadProfile(file, FILE_CATEGORIES.PROFILE, "user"));
    };

    const onReadUserProfileImage = ({ user_id, thumbnail }) => {
        return dispatch(userActions.readProfileLogo({ thumbnail, user_id }));
    };

    const onRemoveUserProfileImage = ({ user_id }) => {
        return dispatch(userActions.deleteProfileLogo('', { user_id }))
    }

    const onFileChange = () => {
        setState({
            newProfilePicture: getValues("attachments").length > 0 ? getValues("attachments")[0] : null
        });
    };

    const showUploadModal = () => {
        setState({
            isUploadPhotoModalOpen: true
        });
    };

    const hideUploadModal = () => {
        setState({
            newProfilePicture: '',
            isUploadPhotoModalOpen: false
        });
        reset();
        if(!isDeletable){
            setImageCheck(false);
        }
    };

    const localOnUpload = async () => { 
        setIsUploading(true);
        onUpload(state.newProfilePicture)
            .then(() => {
                hideUploadModal();
                onReadUserProfileImage({ user_id, thumbnail: true });
                setIsUploading(false);
            })
    };

    const localOnRemoveUserProfileImage = async () => {
        onRemoveUserProfileImage({ user_id })
    };

    return (
        <React.Fragment>
            <ProfileHeader
                name={name}
                profilePicture={profilePicture}
                showUploadModal={showUploadModal}
                allowEdit={isCurrentUserProfile || isAllowedToEditOtherUsersProfilePictures}
                onRemoveUserProfileImage={localOnRemoveUserProfileImage}
                checkImage={checkImage}
            />
            <UploadPhotoModal
                onClose={hideUploadModal}
                open={state.isUploadPhotoModalOpen}
                newImage={state.newProfilePicture && URL.createObjectURL(state.newProfilePicture)}
                fileChange={onFileChange}
                onUpload={localOnUpload}

                altImage={<div className="ProfileHeader__alt-image">
                    <Avatar name={name} maxInitials={2} round size="160px" textSizeRatio={0.25} />
                </div>}
                isUploading={isUploading}
                oldImage={profilePicture}
                control={control}
                isValid={isValid}
                getValues={getValues}
                checkImage={checkImage}
                setImageCheck={setImageCheck}
            />
        </React.Fragment>
    )
}

export default ProfileHeaderContainer;
