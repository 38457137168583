import React, { useContext, useEffect, useState } from 'react';
import { TableWithHeader } from 'dyl-components';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ContactsToolbar from './subcomponents/ContactsToolbar';
import ContactsTable from './subcomponents/ContactsTable';
import { useSelector, useDispatch } from 'react-redux';
import usersActions from 'actions/users';
import textActions from 'actions/text';
import oauthActions from 'actions/oauth';
import emailTemplatesActions from 'actions/email_templates';
import companyActions from 'actions/company';
import userActions from 'actions/user';

import './index.scss';
import BulkActionsProvider, { BulkActionsContext } from 'shared/context/BulkActionsProvider';
import campaignContactsActions from 'actions/campaign_contacts';

const CampaignContactsList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [params] = useSearchParams();
    const { id } = useParams();

    const routeRoot = `/campaigns/${id}/contacts`;

    const searchQuery = params.get('search') || '';
    const [search, setSearch] = useState(searchQuery);

    const { user_id } = useSelector(state => ({
        user_id: state.auth.user_id
    }));

    const onChangeSearch = (_, { value }) => {
        setSearch(value);
    }

    const cancelFunction = () => {
        setSearch("");
        const query = new URLSearchParams(params);
        query.delete('search');
        const query_string = query.toString();
        navigate(`${routeRoot}${query_string ? `?${query_string}` : ''}`);
    };

    const [, setSelectedContacts, , setAreContactsInAllPagesSelected] = useContext(BulkActionsContext);

    const location = useLocation();

    const readContacts = () => {
        dispatch(usersActions.readUsers());

        const { status, ...queryParameters } = {
            page: 1,
            limit: 25,
            ...Object.fromEntries(params)
        }

        dispatch(campaignContactsActions.readCampaignContacts(id, {
            ...queryParameters,
            ...((() => {
                const statusFilter = status?.split(",") || [];
                if (statusFilter.length === 1) {
                    return { active: statusFilter[0] === "active" };
                }
                return {}
            })())
        }));
        dispatch(textActions.getPhones());
        dispatch(oauthActions.getIntegrations({ scopes: 'email', include_disabled: false }));
        dispatch(emailTemplatesActions.getTemplates({ page: 1, limit: 60 }))
        dispatch(companyActions.readSignatures());
        dispatch(userActions.viewUserProfile(user_id))
        dispatch(userActions.readSignatures({ user_id }));
        setSelectedContacts([]);
        setAreContactsInAllPagesSelected(false);
    }

    useEffect(() => {
        if (!location.state?.isTogglingPanel) {
            readContacts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    const [filters, setFilters] = useState({

    })

    const onFilter = async (_, { name, value }) => {
        setFilters({
            ...filters,
            [name]: value
        });
    }

    useEffect(() => {
        const query = new URLSearchParams(params);
        if (search.trim()) {
            query.set('search', search);
        } else {
            query.delete('search');
        }
        const query_string = query.toString();
        navigate(`${routeRoot}${query_string ? `?${query_string}` : ''}`,);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`${routeRoot}${query_string ? `?${query_string}` : ''}`);
    }

    const onSearchSubmit = (value) => {
        const query = new URLSearchParams(params);
        query.set('search', value.trim());
        query.set('page', 1);
        const query_string = query.toString();
        navigate(`${routeRoot}${query_string ? `?${query_string}` : ''}`);
    }

    return (
        <div className='CampaignContactsList'>
            <TableWithHeader
                header={(
                    <ContactsToolbar
                        search={searchQuery}
                        onChangeSearch={onChangeSearch}
                        onSearchSubmit={onSearchSubmit}
                        cancelFunction={cancelFunction}
                        readContacts={readContacts}
                    />
                )}
                table={(
                    <ContactsTable
                        onFilter={onFilter}
                        onPageChange={onPageChange}
                        readContacts={readContacts}
                    />
                )}
            />
        </div>
    );
}

export default function ContactsContainer() {
    return (
        <BulkActionsProvider>
            <CampaignContactsList />
        </BulkActionsProvider>
    )
};
