import { Table } from "dyl-components";
import { useSelector } from "react-redux";
import { Table as SemanticTable } from "semantic-ui-react";
import SelectAllCheckbox from "shared/SelectAllCheckbox";

const OutstandingInvoicesTableHeader = () => {
    // TODO: replace with actual data
    const { invoices } = useSelector(() => ({
        invoices: [],
    }));

    return (
        <Table.Row>
            <SemanticTable.HeaderCell width={1}>
                <SelectAllCheckbox
                    count={invoices.length}
                    data={invoices}
                    idProperty={"invoice_id"}
                    type={"Invoices"}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={4}>
                Invoice ID
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={4}>
                Invoice Name
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={4}>
                Invoice Date
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={4}>
                Amount Due
            </SemanticTable.HeaderCell>
        </Table.Row>
    )
}

export default OutstandingInvoicesTableHeader;
