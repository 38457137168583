import React from 'react';
import { Modal, FileInput, Notification, STATUS_TYPES } from 'dyl-components';
import { Form, Input, Segment, Icon } from 'semantic-ui-react';
import DisplayPicture from 'shared/DisplayPicture';
import FileUtils, { FILE_CATEGORIES } from 'utils/FileUtils';
import { Controller } from 'react-hook-form';


const UploadPhotoModal = React.forwardRef((({ open, onClose, fileChange, newImage, oldImage, onUpload, isUploading, altImage, circular = true, control, isValid, getValues, checkImage, setImageCheck }, fileInputRef, dropzoneRef = React.createRef()) => {
return (
    <Modal open={open} onClose={onClose} size='small'>
        <Modal.Header>
            Upload Photo
        </Modal.Header>
        <Modal.Content>
            {(altImage || newImage || oldImage) &&
                <React.Fragment>
                    {(newImage || oldImage) ? <DisplayPicture src={checkImage ? newImage || oldImage : null} circular={circular} /> :
                        <center style={{ marginBottom: 20 }}>
                            {altImage}
                        </center>
                    }
                </React.Fragment>
            }
            <Form>
                <Controller
                    name="attachments"
                    defaultValue={[]}
                    control={control}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <Form.Field
                            control={Input}
                            error={error && error.message && {
                                pointing: 'below'
                            }}
                            className='Template__attachments-section'
                        >
                            <Segment style={{width: '100%', padding: 0}} basic>
                            <div>
                                <Icon disabled={value.length > 0} onClick={() => { dropzoneRef.current.open() }} color='blue' name='plus circle' link /> <b>Attach {value.length > 0 && 'more '}files</b>
                            </div>
                            <div className='Template__attachments' style={{marginBottom: 10}}>
                                <FileInput
                                    onChange={(_, { value }) => {
                                        if(value.length > 1){
                                            Notification.alert("Only accepts one .png, .jpg, or .jpeg", STATUS_TYPES.ERROR, true);
                                        } else {
                                            onChange({ target: { name, value }
                                            })
                                            fileChange();
                                            setImageCheck(true);
                                        }
                                    }}
                                    onRemove={(_, { value }) => { 
                                        onChange({ target: { name, value } }); 
                                    }}
                                    onReject={(rejected) => {
                                        if (rejected.length > 0) {
                                            Notification.alert("Only accepts .png, .jpg, & .jpeg files.", STATUS_TYPES.ERROR, true);
                                        }
                                    }}
                                    files={value}
                                    name="files"
                                    accept="image/png, image/jpeg, image/jpg"
                                    icon='image'
                                    size="mini"
                                    dropzoneRef={dropzoneRef}
                                    showIcon
                                    hasBrowse
                                />
                            </div>
                            <i>Remaining: {FileUtils.getRemainingSize(value, FILE_CATEGORIES.PROFILE)} MB </i> {error?.message && `(${error.message})`}
                            </Segment>
                        </Form.Field>
                    )}
                />            
            </Form>
        </Modal.Content>
        <Modal.Actions
            hasSaveButton
            onSave={onUpload}
            saveDisabled={newImage === null || newImage === '' || !isValid || getValues("attachments").length === 0}
            saveOptions={{loading: isUploading}}
        />
    </Modal>
)}));

export default UploadPhotoModal;
