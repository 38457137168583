import { ButtonLink } from "dyl-components";
import { useState } from "react";
import { Grid, Header, Segment } from "semantic-ui-react";
import FundAccountForm from "./FundAccountForm";

import paymentActions from "actions/payment";
import { hostedProfileSettings } from "shared/Payment/hostedProfileSettings";
import { useDispatch, useSelector } from "react-redux";

const AccountBalance = () => {
    const [isFundingAccount, setIsFundingAccount] = useState(false);
    const dispatch = useDispatch();
    const customer_id = useSelector((state) => state.auth.customer_id);

    const onLoadPaymentMethods = () => {
        dispatch(paymentActions.resetFormToken());
        dispatch(paymentActions.getProfiles(customer_id)).then((response) => {
            if (!response.profile.paymentProfiles) {
                dispatch(
                    paymentActions.generateAcceptCustomerFormToken(
                        {
                            hostedProfileSettings: hostedProfileSettings,
                        },
                        null,
                        customer_id
                    )
                );
            }
        });
    };

    return (
        <Segment>
            <Grid>
                <Grid.Row columns="equal">
                    <Grid.Column>
                        <Header color="primary">Account Balance</Header>
                    </Grid.Column>
                    <Grid.Column>
                        <Header color="primary">SMS Reload Balance</Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <ButtonLink
                            onClick={() => {
                                setIsFundingAccount(true);
                                onLoadPaymentMethods();
                            }}
                            noPadding
                        >
                            Fund Account
                        </ButtonLink>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <FundAccountForm
                isOpen={isFundingAccount}
                onClose={() => {
                    setIsFundingAccount(false);
                }}
            />
        </Segment>
    );
};

export default AccountBalance;
