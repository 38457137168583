import axiosInstance from 'actions/axiosInstance';
import routes from 'actions/routes';
import CRUD_ACTION_TYPES, { getNamedAction } from 'actions/CRUD_ACTION_TYPES';
import ACTION_NAMES from 'actions/ACTION_NAMES';
import { FileUtils } from 'utils';
import axios from 'axios';

export default {
    uploadFiles: (files, category, ids = []) => dispatch => {
        const body = files.map((file, idx)=> {
            if (file.size > FileUtils.fileSize[category]) {
                return Promise.reject("Max File Size");
            }
            const filename = file.name.replace(" ", "_");
            return {
                filename,
                "content_length": file.size,
                "content_type": file.type,
                "id": ids[idx]
            }
        })
        dispatch({type: getNamedAction(ACTION_NAMES.UPLOAD_FILE, CRUD_ACTION_TYPES.CREATE_REQUEST)});
        return axiosInstance.instance1.post(routes.API_UPLOAD_URLS, body, {params: { category }})
            .then((response) => {
                const ids = [];
                // Upload each file asyncronusly
                const proms = response.data.map(async (info, idx) => {
                    ids.push(info.id);
                    const fileData = new Uint8Array(await files[idx].arrayBuffer())
                    const cache = {};
                    if (category === 'html') {
                        cache['Cache-Control'] =  'private, no-store, no-cache, must-revalidate';
                    }
                    return axios.put(info.url,fileData, {headers: {
                        'Content-Type': files[idx].type,
                        ...cache,
                    }})
                });
                // After all files are uploaded then return callback and dispatch file to uploaded
                Promise.all(proms).then(() => {
                    dispatch({ type: getNamedAction(ACTION_NAMES.UPLOAD_FILE, CRUD_ACTION_TYPES.CREATE_SUCCESS)});
                }).catch((error) => {
                    console.log(error);
                    dispatch({
                        type: getNamedAction(ACTION_NAMES.UPLOAD_FILE, CRUD_ACTION_TYPES.CREATE_FAILURE)
                    });
                    return Promise.reject(error.response?.data);
                })
            // list of file ids to be returned
            return ids;
            }).catch((error) => {
                console.log(error);
                dispatch({
                    type: getNamedAction(ACTION_NAMES.UPLOAD_FILE, CRUD_ACTION_TYPES.CREATE_FAILURE)
                });
                return Promise.reject(error.response?.data);
            })
    },
    uploadProfile: (file, category, type) => async dispatch => {
        //can only upload one image at a time
        if (file.size > FileUtils.fileSize[category]) {
            return Promise.reject("Max File Size");
        }

        let formData = new FormData();
        formData.append("file", file, file.name);

        dispatch({type: getNamedAction(ACTION_NAMES.UPLOAD_FILE, CRUD_ACTION_TYPES.CREATE_REQUEST)});
        return await axiosInstance.upload_instance.post(
            type === "user" ? routes.UPLOAD_PROFILE : routes.UPLOAD_LOGO, formData,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                    "x-token": (await axiosInstance.instance1.get(`/v1/jwt`)).data.token
                },
            }
            ).then((response) => {
                return response?.data
            }).catch((error) => {
                console.log(error);
                dispatch({
                    type: getNamedAction(ACTION_NAMES.UPLOAD_FILE, CRUD_ACTION_TYPES.CREATE_FAILURE)
                });
                return Promise.reject(error.response?.data);
            })
    }
}
