import React, { useContext, useState } from 'react';
import { Table, EllipsisDropdown, DateTimeUtils, Person, ControlledPopup } from 'dyl-components';
import { Checkbox, DropdownItem, Header, Dropdown, Popup } from 'semantic-ui-react';

import '../index.scss';
import DestinationGroupsSubrow from './DestinationGroupsSubrow';
import { BulkActionsContext } from 'shared/context/BulkActionsProvider';

const DestinationGroupsRow = ({ destinationGroup = {}, onDelete, onSelect }) => {
    const { created, created_user, label, items, id, in_use } = destinationGroup;
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [selectedDestinationGroups, setSelectedDestinationGroups] = useContext(BulkActionsContext);

    const isSelected = (destination_group_id) => {
        return selectedDestinationGroups.includes(destination_group_id);
    };

    const onToggleExtension = (destination_group_id) => {
        const newSelectedDestinationGroups = isSelected(destination_group_id)
            ? selectedDestinationGroups.filter((id) => destination_group_id !== id)
            : [...selectedDestinationGroups, destination_group_id];
            setSelectedDestinationGroups(newSelectedDestinationGroups);
    };

    const handleToggle = async () => {
        setIsCollapsed(!isCollapsed)
    };

    return (
        <Table.CollapsibleRow
            onToggle={handleToggle}
            collapsed={isCollapsed}
            togglePos={1}
            toggleHidden={false}
            subrowContent={[
                <DestinationGroupsSubrow 
                    destinations={items} 
                />
            ]}
        >
            <Table.Cell>
                <Checkbox 
                    checked={() => isSelected(id)} 
                    onChange={() => onToggleExtension(id)} 
                />
            </Table.Cell>
            <Table.Cell>
                {created && (
                    <>
                        <div><b>{DateTimeUtils.formatEpoch(created, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                        <div>{DateTimeUtils.formatEpoch(created, DateTimeUtils.TIME_FORMAT)}</div>
                    </>
                )}
            </Table.Cell>
            <Table.Cell>
                {created_user && (
                    <Person username={created_user.username} email={created_user.email} />
                )}
            </Table.Cell>
            <Table.Cell>
                {label}
            </Table.Cell>
            <Table.Cell>
                <EllipsisDropdown>
                    <DropdownItem 
                        text={"Edit"}
                        icon={'fa-solid fa-pencil'}
                        onClick={() => onSelect(id)}
                    />
                    <ControlledPopup 
                        header={
                            <Header as='h4' textAlign='center'>
                                Are you sure?
                                <Header.Subheader style={{ marginTop: 5 }}>
                                    You cannot undo delete actions.
                                </Header.Subheader>
                            </Header>
                        }

                        trigger={
                            in_use ? (
                                <Popup
                                    content="Destination group in use"
                                    position="right center"
                                    inverted
                                    on={'hover'}
                                    trigger={
                                        <Dropdown.Item
                                            className={`destination-group-${
                                                in_use ? "disable" : ""
                                            }`}
                                            text="Delete"
                                            icon="fa-solid fa-trash"
                                        />
                                    }
                                />
                            ) : (
                                <Dropdown.Item
                                    text="Delete"
                                    icon="fa-solid fa-trash"
                                />
                            )
                        }
                        onConfirm={() => onDelete(id)}
                        inverted
                    />
                </EllipsisDropdown>
            </Table.Cell>
        </Table.CollapsibleRow>
    );
}

export default DestinationGroupsRow;


