import { Button, Form, Grid, Header, Icon, Segment } from "semantic-ui-react";
import PaymentInformation from "./PaymentInformation";
import { Modal, Notification, STATUS_TYPES, Step } from "dyl-components";
import { Controller, useFormContext } from "react-hook-form";
import { useContext, useState } from "react";
import { QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";
import PaymentMethod from "./PaymentMethod";

import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import invoiceActions from "actions/invoice";

const STEPS = [
    {
        icon: <Icon className="fas fa-box-dollar" size="large" />,
        title: "Order",
        completed: true,
    },
    {
        icon: <Icon className="fas fa-file-invoice-dollar" size="large" />,
        title: "Checkout",
        active: true,
    },
];

const Payment = ({ account_id }) => {
    const {
        formState: { control, isValid, isDirty },
        handleSubmit
    } = useFormContext();
    const { onEditOrder, quoteBuilderConfig, onViewInvoice, onRefreshAccountInformation } = useContext(QuoteBuilderContext);

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

    const onChangeSelectedPaymentMethod = (method) => {
        setSelectedPaymentMethod(method);
    };

    const dispatch = useDispatch();
    const order_id = quoteBuilderConfig?.id;

    const paymentMethods = useSelector(state => state.payment.profile?.paymentProfiles || []);

    const opportunity_id = useSelector(state => state.order.order?.opportunity_id);

    const onProcess = async (data) => {
        const otherInfo = (() => {
            if (selectedPaymentMethod === "custom-ach") {
                return {
                    custom: data.custom_ach
                }
            }
            const paymentInfo = paymentMethods.find(paymentMethod => data.payment_method === paymentMethod.customerPaymentProfileId);
            const { creditCard, bankAccount } = paymentInfo.payment;
            const payment = (() => {
                if (creditCard) {
                    const { cardType, issuerNumber, ...otherInfo } = creditCard;
                    return {
                        creditCard: otherInfo
                    };
                }
                return {
                    bankAccount
                };
            })();
            return {
                customerPaymentProfileId: data.payment_method,
                paymentInfo: payment,
                payment: {
                    processor: "authorize.net"
                }
            }
        })();
        const payload = {
            ...otherInfo,
            account_id,
            opportunity_id,
            name: data.invoice_name,
            note: data.buyer_notes,
        }
        try {
            const invoice_id = await dispatch(invoiceActions.create(payload, null, order_id));
            Notification.alert("Successfully processed order!", STATUS_TYPES.SUCCESS);
            onViewInvoice(invoice_id);
            onRefreshAccountInformation();
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to process order", STATUS_TYPES.ERROR);
        }
    }

    const isProcessingPaymentDetails = useSelector(state => state.invoice.isCreating);

    return (
        <>
            <Modal.Content scrolling>
                <Form size="small" noValidate loading={isProcessingPaymentDetails}>
                    <Segment size="tiny" basic>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <Step.Group horizontal>
                                        {STEPS.map(({ icon, ...step }) => (
                                            <Step {...step} key={step.name}>
                                                {icon}
                                            </Step>
                                        ))}
                                    </Step.Group>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header color="primary">
                                        Invoice Information
                                    </Header>
                                    <PaymentInformation />
                                    <Header color="primary">
                                        Payment Method
                                    </Header>
                                    <PaymentMethod
                                        onChangeSelectedPaymentMethod={
                                            onChangeSelectedPaymentMethod
                                        }
                                        selectedPaymentMethod={
                                            selectedPaymentMethod
                                        }
                                        account_id={account_id}
                                    />
                                    <Controller
                                        control={control}
                                        name="buyer_notes"
                                        render={({
                                            field: { name, value, onChange },
                                        }) => (
                                            <Form.TextArea
                                                name={name}
                                                value={value}
                                                onChange={(_, { value }) => {
                                                    onChange({
                                                        target: { name, value },
                                                    });
                                                }}
                                                label="Buyer Notes"
                                            />
                                        )}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    basic
                    onClick={() => {
                        onEditOrder(order_id, account_id);
                    }}
                    type="button"
                    color="primary"
                    disabled={isProcessingPaymentDetails}
                >
                    Back to Order
                </Button>
                <Button
                    disabled={!isValid || !isDirty || isProcessingPaymentDetails}
                    type="submit"
                    color="primary"
                    onClick={handleSubmit(onProcess)}
                    loading={isProcessingPaymentDetails}
                >
                    Process
                </Button>
            </Modal.Actions>
        </>
    );
};

export default Payment;
