import { DateTimeUtils, VALIDATORS, yup } from "dyl-components";
import { cartItemSchema } from "../cart/cartSchema";

export const quoteSchema = {
    contact_address: yup.object({
        street: yup
            .string()
            .maxlength(100)
            .required("This field is required"),
        additional_street: yup.string().maxlength(12),
        city: yup
            .string()
            .maxlength(60)
            .required("This field is required"),
        state: yup.string().required("This field is required"),
        zip: VALIDATORS.US_POSTAL_CODE().required(
            "This field is required"
        ),
    }),
    contact_id: yup.number().required("This field is required"),
    from_user_id: yup.number().required("This field is required"),
    name: VALIDATORS.BUSINESS_NAME()
        .no_whitespace_only()
        .required("This field is required"),
    quote_status: yup.string().required("This field is required"),
    expires: yup
        .string()
        .required("This field is required")
        .test("valid_date", "Invalid date", (value) => {
            return value
                ? DateTimeUtils.isValid(
                      value,
                      DateTimeUtils.WORD_DATE_FORMAT
                  )
                : true;
        })
        .test(
            "cannot_select_past_dates",
            "Expiration date should not be a past date",
            function (value) {
                if (!!!value) {
                    return true;
                }
                const currentDate = DateTimeUtils.getCurrentDate(
                    DateTimeUtils.WORD_DATE_FORMAT
                );
                return !DateTimeUtils.dateIsBeforeAnotherDate(
                    value,
                    currentDate,
                    "days",
                    DateTimeUtils.WORD_DATE_FORMAT
                );
            }
        )
        .test(
            "cannot_go_beyond_a_date",
            "Expiration date should be at most 6 months from now",
            function (value) {
                if (!!!value) {
                    return true;
                }
                const maxDate = DateTimeUtils.changeFormat(
                    DateTimeUtils.getNext(6, "month", false, true),
                    DateTimeUtils.DATE_FORMAT,
                    DateTimeUtils.WORD_DATE_FORMAT
                );
                return DateTimeUtils.dateIsBeforeAnotherDate(
                    value,
                    maxDate,
                    "days",
                    DateTimeUtils.WORD_DATE_FORMAT
                );
            }
        ),
    cart: yup
        .array()
        .min(1, "There should be at least one item selected")
        .of(yup.object(cartItemSchema))
        .test(
            "no_items_in_cart",
            "There should be at least one item in the cart",
            (items) => {
                return !items.every((item) => item.removed);
            }
        )
        .test(
            "should_have_matching_pricing_schedule",
            "Pricing schedules for all recurring products should match",
            (items, context) => {
                if (!context.parent.pricing_schedule) {
                    const recurringPricingSchedules = [...new Set(items.filter(item => Boolean(item?.model.includes("recurring"))).map(item => {
                        return item.pricing_schedule;
                    }))];
                    return recurringPricingSchedules.length <= 1;
                }
                return items.filter(item => !Boolean(item.removed)).every(item => {
                    if (!Boolean(item?.model.includes("recurring"))) {
                        return true;
                    }
                    return item?.schedules.includes(context.parent.pricing_schedule);
                })
            }
        )
}
