import { Grid, Header } from "semantic-ui-react";
import React from "react";
import { MathUtils, StringUtils } from "utils";

const SubscriptionTotals = ({ nextInvoiceDate, subtotal, totalDiscount, totalFee, totalTax, total }) => {
    return (
        <Grid divided="vertically">
            <Grid.Row>
                <Grid.Column>
                    <Header>Next Invoice: {nextInvoiceDate}</Header>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="2">
                <Grid.Column>
                    <Header size="small">Subtotal</Header>
                </Grid.Column>
                <Grid.Column textAlign="right">
                    <Header color="primary" size="small">
                        $
                        {StringUtils.formatDecimal(
                            `${MathUtils.roundDecimals(subtotal)}`
                        , true)}
                    </Header>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Grid>
                        <Grid.Row columns="2">
                            <Grid.Column>
                                <Header size="small">Discount</Header>
                            </Grid.Column>
                            <Grid.Column textAlign="right">
                                <Header color="primary" size="small">
                                    -${StringUtils.formatDecimal(
                                        `${MathUtils.roundDecimals(totalDiscount)}`
                                    , true)}
                                </Header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns="2">
                            <Grid.Column>
                                <Header size="small">Total Fee</Header>
                            </Grid.Column>
                            <Grid.Column textAlign="right">
                                <Header color="primary" size="small">
                                    $
                                    {StringUtils.formatDecimal(
                                        `${MathUtils.roundDecimals(totalFee)}`
                                    , true)}
                                </Header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns="2">
                            <Grid.Column>
                                <Header size="small">Estimated Tax</Header>
                            </Grid.Column>
                            <Grid.Column textAlign="right">
                                <Header color="primary" size="small">
                                    $
                                    {StringUtils.formatDecimal(
                                        `${MathUtils.roundDecimals(totalTax)}`
                                    , true)}
                                </Header>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="2">
                <Grid.Column>
                    <Header size="small">Total</Header>
                </Grid.Column>
                <Grid.Column textAlign="right">
                    <Header color="primary" size="small">
                        $
                        {StringUtils.formatDecimal(
                            `${MathUtils.roundDecimals(total)}`
                        , true)}
                    </Header>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default SubscriptionTotals;
