// Override this if testing locally
const FRONT_END_HOST = process.env.REACT_APP_FRONT_END_HOST || process.env.FRONT_END_HOST || 'http://app.getdyl.com';
const VIEW_HOST = process.env.REACT_APP_VIEW_HOST || process.env.VIEW_HOST;
const UPLOAD_HOST = process.env.REACT_APP_UPLOAD_HOST || process.env.UPLOAD_HOST;

console.log('FRONT_END_HOST', FRONT_END_HOST);
console.log('FRONT_END_HOST_ENV', process.env.REACT_APP_FRONT_END_HOST);
console.log('VIEW_HOST', VIEW_HOST);
console.log('UPlOAD_HOST', UPLOAD_HOST);

export default {
    FRONT_END_HOST, 
    VIEW_HOST,
    UPLOAD_HOST
};

