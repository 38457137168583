const initialState = {
    isCreating: false,
    quoteBeingRead: null,
    quote: { quote_summary: [] },
    quoteError: false,
    quoteBeingUpdated: null,

    recentQuote: null,
    recentQuoteBeingRead: null,

    opportunityQuotes: {},

    quoteVersionBeingRead: null,
    quoteVersionError: false,
    quoteVersion: null,
    
    isCreatingQuoteVersion: false,
    quoteContactInfoBeingRead: null,

    quoteVersions: [],
    quoteVersionsBeingRead: null,

    quoteVersionsSummary: {},

    opportunityBeingUpdated: null,

    orderQuote: null,
    opportunityBeingUpsold: null,
    opportunityQuoteOptions: [],
    isReadingOpportunityQuoteOptions: false
}

export default initialState;
