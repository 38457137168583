import { DateTimeUtils } from "dyl-components";
import { getDefaultCartItems } from "shared/schemas/cart/cartSchema";
import { ObjectUtils } from "utils";

export const getQuoteDefaultValues = ({ quote, account_id }) => {
    const { street, city, state, zip } = quote.contact_info?.address || {};

    const cartItems = getDefaultCartItems(quote.quote_summary);
    const schedules = [...new Set(cartItems.filter(item => item.schedules.length).map(item => item.pricing_schedule))];
    return {
        quote_status: quote.quote_status,
        cart: cartItems,
        name: quote.name,
        from_email: quote.from_information?.email,
        from_phone: quote.from_information?.phone,
        from_user_id: quote.from_information?.from_user_id,
        profile_info: quote.from_information?.profile_info,
        contact_email: quote.contact_info?.email,
        contact_phone: quote.contact_info?.phone,
        contact_id: quote.contact_info?.contact_id,
        contact_address:
            street && city && state && zip
                ? quote.contact_info?.address
                : {
                      address: {
                          additional_street: "",
                          city: "",
                          state: "",
                          street: "",
                          zip: "",
                      },
                      contact_id: account_id,
                      email: "",
                      phone: "",
                  },
        expires:
            quote.expires > 0
                ? DateTimeUtils.changeFormat(
                      DateTimeUtils.convertUnixTimeToDate(quote.expires, "UTC"),
                      "",
                      DateTimeUtils.WORD_DATE_FORMAT,
                      "UTC"
                  )
                : null,
        notes: quote.note,
        pricing_schedule: schedules.length === 1 ? schedules[0] : null
    };
};

export const getOrderDefaultValues = ({ order, account_id, account_term }) => {
    const shippingAddress = order?.shipping_address?.location?.address;
    const cartItems = getDefaultCartItems(order?.order_items, account_term);
    const schedules = [...new Set(cartItems.filter(item => item.schedules.length).map(item => item.pricing_schedule))];
    return {
        isShippingSameAsBilling:
            shippingAddress &&
            order?.shipping_address?.location?.contact_id ===
                order?.billing_address?.contact_id &&
            ObjectUtils.deepEqual(
                shippingAddress,
                order?.billing_address?.address
            ),
        order_status: order?.order_status || "draft",
        order_name: order?.name,
        billing_address: Object.keys(order?.billing_address || {}).length
            ? order?.billing_address
            : {
                  address: {
                      additional_street: "",
                      city: "",
                      state: "",
                      street: "",
                      zip: "",
                  },
                  contact_id: account_id,
                  email: "",
                  email_type: "",
                  phone: "",
                  phone_type: ""
              },
        shipping_address: Boolean(order?.shipping_address?.location?.address)
            ? {
                  address: order?.shipping_address?.location?.address,
                  phone: order?.shipping_address?.location?.phone,
                  phone_type: order?.shipping_address?.location?.phone_type,
                  email: order?.shipping_address?.location?.email,
                  email_type: order?.shipping_address?.location?.email_type,
                  ...(!Boolean(order?.shipping_address?.location?.contact_id)
                      ? (() => {
                            const { first_name, last_name, suffix } = order?.shipping_address?.ship_to;
                            return {
                                contact_id: `shipping-contact`,
                                first_name,
                                last_name,
                                suffix,
                            };
                        })()
                      : {
                            ship_to: order?.shipping_address?.ship_to,
                            contact_id:
                                order?.shipping_address?.location?.contact_id,
                        }),
              }
            : {
                  address: {
                      additional_street: "",
                      city: "",
                      state: "",
                      street: "",
                      zip: "",
                  },
                  contact_id: account_id,
                  email: "",
                  phone: "",
              },
        cart: cartItems,
        pricing_schedule: account_term || schedules.length === 1 ? schedules[0] : null
    };
};
