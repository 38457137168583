import { DateInput, DatesRangeInput, TimeInput, VALIDATORS, yup, NestedDropdown, VALIDATION_PATTERNS } from 'dyl-components';
import { Checkbox, Dropdown, Icon, Input} from 'semantic-ui-react';
import React from 'react';

import { STATES } from 'shared/constants/STATES';
import FIELD_TYPE, { FIELD } from 'shared/constants/FIELD_TYPE';
import { ObjectUtils, PhoneUtil, StringUtils } from 'utils';

const generateAddressDylFieldValidators = index => {
    return {
        [`person.location.${index}.label`]: VALIDATORS.TITLE(),
        [`person.location.${index}.address`]: VALIDATORS.STREET_ADDRESS(),
        [`regex:(.*), (.*) (\\d{5}):person.location.${index}.city:person.location.${index}.state:person.location.${index}.zip`]: VALIDATORS.SIMPLE_INPUT(), //might need validation update to except commas?
        [`person.location.${index}.city`]: VALIDATORS.STREET_ADDRESS(),
        [`person.location.${index}.state`]: yup.string().oneOf(STATES.map(({ key }) => key)).oneOf(STATES.map(({ text }) => text)),
        [`person.location.${index}.zip`]: VALIDATORS.US_POSTAL_CODE()
    }
}

const generateDropdownValidators = (field, field_type, options) => {
    if(!options){
        return
    } 
    if(field_type === "dependency"){
        return {
            [field]: yup.string().oneOf(options.map(option => [option.text, option.options.map(sub_option => sub_option.text)].flat()).flat()),
        }
    }
    return {
        [field]: yup.string().oneOf(options.map(option => option.text)),
    }
}

const DYL_FIELD_VALIDATORS = (field, field_type, options) => {
    return {
        "person.first_name": VALIDATORS.FIRST_NAME(),
        "person.last_name": VALIDATORS.LAST_NAME(),
        "person.custom_data.children.buisness_details.0.fields.department": VALIDATORS.DEPARTMENT(),
        "person.custom_data.fields.school": VALIDATORS.SCHOOL_OR_DEGREE(),
        "business_name": VALIDATORS.BUSINESS_NAME(),

        ...generateAddressDylFieldValidators(1),
        ...generateAddressDylFieldValidators(2),
        ...generateAddressDylFieldValidators(3),

        ...generateDropdownValidators(field, field_type, options),
    }
}

const DYL_TYPE_VALIDATORS = {
    [FIELD.TEXT]: VALIDATORS.SIMPLE_INPUT(),
    [FIELD.TEXTAREA]: VALIDATORS.DESCRIPTION(),
    [FIELD.NUMBER]: yup.number(),
    [FIELD.PERCENT]: VALIDATORS.PERCENTAGE(),
    [FIELD.CURRENCY]: yup.number(),
    [FIELD.URL]: VALIDATORS.WEBSITE(),
    [FIELD.EMAIL]: VALIDATORS.EMAIL_ADDRESS(),
    [FIELD.PHONE]: VALIDATORS.PHONE_NUMBER(),

    [FIELD.DATE]: VALIDATORS.DATE_FORMAT(), 
    [FIELD.TIME]: VALIDATORS.TIME_FORMAT(),
    [FIELD.DATE_RANGE]: VALIDATORS.DATE_FORMAT(),
    [FIELD.TIME_RANGE]: VALIDATORS.TIME_FORMAT(),

    // generateDropdownValidators() takes care of below via DYL_FIELD_VALIDATORS, if options are avalible
    // [FIELD.STATE]: VALIDATORS.STATE(),
    // [FIELD.PICKLIST]: VALIDATORS.SIMPLE_INPUT(),
    // [FIELD.DROPDOWN]: VALIDATORS.SIMPLE_INPUT(),
    // [FIELD.TAGS]: VALIDATORS.SIMPLE_INPUT(),
    // [FIELD.DEPENDENCY]: VALIDATORS.SIMPLE_INPUT()

    //TODO: loop thru?
    // [FIELD.CHECKBOX]: //boolean check
    // [FIELD.RADIO]: //boolean check
}

const DYL_ASSIGNMENTS_TYPE_VALIDATORS = {
    [FIELD.TEXT]: VALIDATORS.SIMPLE_INPUT(),
    [FIELD.TEXTAREA]: VALIDATORS.DESCRIPTION(),
    [FIELD.NUMBER]: yup.number(),
    [FIELD.PERCENT]: VALIDATORS.PERCENTAGE(),
    [FIELD.CURRENCY]: yup.number(),
    [FIELD.URL]: VALIDATORS.WEBSITE(),
    [FIELD.EMAIL]: VALIDATORS.EMAIL_DOMAIN(),
    [FIELD.PHONE]: VALIDATORS.PHONE_NUMBER(),

    [FIELD.DATE]: VALIDATORS.DATE_FORMAT(), 
    [FIELD.TIME]: VALIDATORS.TIME_FORMAT(),
    [FIELD.DATE_RANGE]: VALIDATORS.DATE_FORMAT(),
    [FIELD.TIME_RANGE]: VALIDATORS.TIME_FORMAT(),
}

export const ASSIGNMENTS_EXISTS_OPTIONS = [
    {text: "Exists", key: "exists", value: "exists"},
    {text: "Not Exists", key: "not_exists", value: "not_exists"},
]

const ASSIGNMENTS_TAGS_OPTIONS = [
    {text: "Equal", key: "equal", value: "equal"},
    {text: "Not Equal", key: "not_equal", value: "not_equal"},
    {text: "One Of", key: "one_of", value: "one_of"},
    {text: "Not One Of", key: "not_one_of", value: "not_one_of"},
]

export const NO_ASSIGNMENTS_EXISTS_FIELDS = [
    "person.last_name", "person.first_name", "opportunity.name"
]

export const ASSIGNMENT_RANGE_OPERATORS = ["is_within_the_range", "is_outside_range"];

export const ASSIGNMENT_RANGE_FIELD_TYPES = [FIELD.DATE, FIELD.DATE_RANGE, FIELD.TIME, FIELD.TIME_RANGE, FIELD.CURRENCY, FIELD.USER, FIELD.NUMBER, FIELD.PERCENT];

export const ASSIGNMENT_DATE_FIELD_TYPES = [FIELD.DATE, FIELD.DATE_RANGE];

const createDylOperatorsOptions = () => {
    const otherFieldsOptions = {
        [FIELD.TAGS]: ASSIGNMENTS_TAGS_OPTIONS,
        [FIELD.PICKLIST]: [
            ...ASSIGNMENTS_TAGS_OPTIONS,
            ...ASSIGNMENTS_EXISTS_OPTIONS
        ],
        [FIELD.CHECKBOX]: [
            {text: "Yes", key: "yes", value: "yes"},
            {text: "No", key: "no", value: "no"},
        ],
        [FIELD.EMAIL]: [
            {text: "Email Domain", key: "email_domain", value: "email_domain"},
            ...ASSIGNMENTS_EXISTS_OPTIONS
        ],
        [FIELD.PHONE]: [
            {text: "Area Code", key: "area_code", value: "area_code"},
            ...ASSIGNMENTS_EXISTS_OPTIONS
        ],
        [FIELD.URL]: ASSIGNMENTS_EXISTS_OPTIONS
    }

    //Radio, Dropdown, Rating, State, Dependency
    const keys_1 = [FIELD.RADIO, FIELD.DROPDOWN, FIELD.RATING, FIELD.STATE, FIELD.DEPENDENCY];
    const object_1 = Object.fromEntries(keys_1.map(key => [key, [
        {text: "Equal", key: "equal", value: "equal"},
        {text: "Not Equal", key: "not_equal", value: "not_equal"},
        {text: "One Of", key: "one_of", value: "one_of"},
        {text: "Not One Of", key: "not_one_of", value: "not_one_of"},
    ]]).filter((key) => { return key[0] === FIELD.STATE ? key : key[1].push(...ASSIGNMENTS_EXISTS_OPTIONS)}))

    //Picklist
    const keys_2 = [FIELD.PICKLIST, FIELD.TAGS];
    const object_2 = Object.fromEntries(keys_2.map(key => [key, [
        {text: "Contains All Values", key: "contains_all_values", value: "contains_all_values"},
        {text: "Doesn't Contain All Values", key: "doesnt_contain_all_values", value: "doesnt_contain_all_values"},
        {text: "Contains Any Value", key: "contains_any_value", value: "contains_any_value"},
        {text: "Doesn't Contain Any Value", key: "doesnt_contain_any_value", value: "doesnt_contain_any_value"},
        ...ASSIGNMENTS_EXISTS_OPTIONS
    ]]))

    //Date/Time, Date, Date Range, Time, Time Range, Currency, User, Number, Percent
    const keys_3 = ASSIGNMENT_RANGE_FIELD_TYPES;
    const object_3 = Object.fromEntries(keys_3.map(key => [key, [
        {text: "Is Greater Than", key: "is_greater_than", value: "is_greater_than"},
        {text: "Greater Than Or Equal", key: "greater_than_or_equal", value: "greater_than_or_equal"},
        {text: "Less Than", key: "less_than", value: "less_than"},
        {text: "Less Than Or Equal", key: "less_than_or_equal", value: "less_than_or_equal"},
        {text: "Is Within The Range", key: "is_within_the_range", value: "is_within_the_range"},
        {text: "Is Outside Range", key: "is_outside_range", value: "is_outside_range"},
        ...ASSIGNMENTS_EXISTS_OPTIONS
    ]]))

    //Text, Text Area
    const keys_4 = [FIELD.TEXT, FIELD.TEXTAREA];
    const object_4 = Object.fromEntries(keys_4.map(key => [key, [
        {text: "Contains Text", key: "contains_text", value: "contains_text"},
        {text: "Doesn't Contain Text", key: "doesnt_contain_text", value: "doesnt_contain_text"},
        {text: "Starts With", key: "starts_with", value: "starts_with"},
        {text: "Doesn't Start With", key: "doesnt_start_with", value: "doesnt_start_with"},
        ...ASSIGNMENTS_EXISTS_OPTIONS
    ]]))

    return {...object_1, ...object_2, ...object_3, ...object_4, ...otherFieldsOptions};
}

const ASSIGNMENT_VALUES_SCHEMA = async (value, context) => {
    try {
        const { type: when_name } = context
        if (when_name === "custom_child_dependency_validation" && !value) {
            return true;
        }
        const {field, type, options} = context.parent
        let evalValue = value;
        if (type === "tagsinput" || type === "picklist" || type === "dropdown" || type === "dependency" || type === "rating") {
            evalValue = options.find(option => option.value === (VALIDATION_PATTERNS.number.test(value) ? Number(value) : value))?.text || "";
        } 
        const validator = DYL_FIELD_VALIDATORS(field, type, options)[field] || DYL_ASSIGNMENTS_TYPE_VALIDATORS[type] || yup.mixed().notRequired();
        validator.validateSync(evalValue);
        return true;
    } catch (error) {
        return context.createError({ message: error.message });
    }
}

export const ASSIGMENT_RULES_MULTIPLE_TYPES = [FIELD.RADIO, FIELD.DROPDOWN, FIELD.RATING, FIELD.STATE, FIELD.DEPENDENCY, FIELD.TAGS];

export const ASSIGNMENT_RULES_OPERATOR_SINGLE = ["equal", "not_equal"];

export const ASSIGNMENTS_OPERATORS_OPTIONS = createDylOperatorsOptions();

export const ASSIGNMENTS_DYL_FIELDS_SCHEMA = yup.array().of(yup.object().shape({
    conditions: yup.array().of(yup.object().shape({
        field: yup.string(),
        type: yup.string(),
        operator: yup.string(),
        parent: yup.string(),
        value: yup.string().when(['field', 'operator', 'type'], {
            is: (field, operator, type) => {
                const fieldConditions = field !== 'criteria' && field !== "composite_phone" && field !== "composite_location" && field !== "composite_email";
                const operatorConditions = operator !== "exists" && operator !== "not_exists";
                const isCheckbox = type === "checkbox";
                return fieldConditions && operatorConditions && !isCheckbox;
            },
            then: () => yup.mixed().when(['type', 'operator'], {
                is: (type, operator) => ASSIGMENT_RULES_MULTIPLE_TYPES.includes(type) && !ASSIGNMENT_RULES_OPERATOR_SINGLE.includes(operator),
                then: () => yup.array().test('array_length', 'This field is required', val => val.length >= 1).typeError("Multiple required").of(yup.mixed().required("This field is required").test({
                    name: 'custom_field_validation',
                    test: ASSIGNMENT_VALUES_SCHEMA
                })),
                otherwise: () => yup.string().when('operator', {
                    is: (operator) => operator === "area_code",
                    then: () => VALIDATORS.AREA_CODE().required("This field is required"),
                    otherwise: () => yup.string().when('operator', {
                        is: (operator) => ASSIGNMENT_RANGE_OPERATORS.includes(operator),
                        then: () => yup.string().when('type', {
                            is: (type) => ASSIGNMENT_DATE_FIELD_TYPES.includes(type),
                            then: () => VALIDATORS.DATE_FORMAT(),
                            otherwise: () => yup.string().only_numbers().required("This field is required"),
                        }),
                        otherwise: () => yup.string().required("This field is required").test({
                            name: 'custom_field_validation',
                            test: ASSIGNMENT_VALUES_SCHEMA
                        }),
                    })
                })
            }),
            otherwise: () => yup.mixed().notRequired()
        }),
        maxValue: yup.string().when('operator', {
            is: (operator) => ASSIGNMENT_RANGE_OPERATORS.includes(operator),
            then: () => yup.string().when('type', {
                is: (type) => ASSIGNMENT_DATE_FIELD_TYPES.includes(type),
                then: () => VALIDATORS.DATE_FORMAT().test(
                    "is-date-greater",
                    "This value must be greater than minimum value",
                    (value, context) => {
                        const { value: min } = context?.parent;
                        return value > min;
                    }
                ),
                otherwise: () => yup.string().required("This field is required").only_numbers().test(
                    "is-greater-than-value",
                    "This value must be greater than minimum value",
                    (value, context) => {
                        const { value: min } = context?.parent;
                        return Number(value) > Number(min);
                    }
                ),
            }),
            otherwise: () => yup.string().nullable()
        })
    })).test({
        name: "conditions_validation",
        test: (value) => {
            return Array.isArray(value) && value.length > 0;
        },
        message: "Conditions are required"
    })
})).test({
    name: "segments_validation",
    test: (value) => {
        return Array.isArray(value) && value.length > 0;
    },
    message: "Segments are required"
})

export const ROUTINGS_DYL_FIELDS_SCHEMA = yup.array().of(yup.object().shape({
    name: VALIDATORS.TEAM_NAME().required('This field is required'),
    period: yup.string().when("distribution_method", {
        is: (field) => field === "wg",
        then: schema => schema.required("This field is required")
            .test({
                name: 'period_validation',
                test: async (value, context) => {
                    try {
                        return yup.string().oneOf(["daily", "weekly", "monthly"]).validateSync(value);
                    } catch (error) {
                        return context.createError({ message: error.message });
                    }
                }
            })
    }),
    users: yup.mixed().required("This field is required").test({
        name: 'user_field_validation',
        test: async (value, context) => {
            try {
                return value.length > 0 ? true : false;
            } catch (error) {
                return context.createError({ message: error.message });
            }
        }
    })
}))


export const DYL_FIELDS_SCHEMA = yup.array().of(yup.object().shape({
    skipped: yup.boolean(),
    value: yup.mixed(),
    dyl_field: yup.string().nullable(true).when("skipped", {
        is: false,
        then: schema => schema.required("This field is required")
            .test("field_type_should_match_dyl_field", "Does not match field value or type", async function (dyl_field) {
                if (!!!this.parent.value) {
                    return true;
                }
                const { field_type, value, options, valid_field } = this.parent;
                let field = value;
                if(field_type === "phone"){
                    field = PhoneUtil.sanitizePhoneNumber(value);
                }
                if(field_type === "tagsinput" || field_type === "picklist" || field_type === "dependency" || field_type === "dropdown" || field_type === "rating"){
                    field = StringUtils.capitalize(value);
                }
                if(field_type === "currency"){
                    field = StringUtils.sanitizeCurrency(String(value));
                }
                if(field_type === "date"){
                    return valid_field;
                }
                
                //TODO: Use Case... if Field Value(value) for below field_type's has multiple values, will have to loop though data and check against validator
                // if(field_type === "tagsinput" || field_type === "picklist" || field_type === "dependency" || field_type === "dropdown" || field_type === "rating"){
                //     for(let i=0; i < default_value.length; i++){
                //         const isValid = await validator.isValid(ObjectUtils.findValue(options, default_value[i]));
                //         if(!isValid){
                //             return isValid
                //         }
                //     }
                //     return true;
                // }

                ///DYL_FIELD_VALIDATORS[dyl_field] validate w/ specific validation OR DYL_TYPE_VALIDATORS[this.parent.field_type] validate on primitive type
                const validator = DYL_FIELD_VALIDATORS(dyl_field, field_type, options)[dyl_field] || DYL_TYPE_VALIDATORS[field_type] || yup.mixed().notRequired();
                return await validator.isValid(field);
            })
    }),
    default_value: yup.mixed().when("dyl_field", {
        is: (field) => field !== undefined,
        then: schema => schema.test("field_type_should_match_default_value", "Does not match type", async function (default_value) {
            if (!!!default_value) {
                return true;
            }
            const { dyl_field, field_type, options } = this.parent;
            ///DYL_FIELD_VALIDATORS[dyl_field] validate w/ specific validation OR DYL_TYPE_VALIDATORS[this.parent.field_type] validate on primitive type
            const validator = DYL_FIELD_VALIDATORS(dyl_field, field_type, options)[dyl_field] || DYL_TYPE_VALIDATORS[field_type] || yup.mixed().notRequired();
            if(field_type === "tagsinput" || field_type === "picklist" || field_type === "dependency" || field_type === "dropdown" || field_type === "rating"){
                //No need to run validation, all default options provided are valid
                return true
            }
            return await validator.isValid(default_value);
        })
    }),
}).test("field_type_should_appear_only_once", "DYL Field already being used", function (currentHeader) {
    if (!!!currentHeader?.dyl_field) {
        return true;
    }
    const otherHeaders = this.parent.filter(header => !ObjectUtils.deepEqual(header, currentHeader));
    const isDuplicate = otherHeaders.some(header => (header?.dyl_field === currentHeader?.dyl_field) && !currentHeader.skipped && !header?.skipped );
    return !isDuplicate || this.createError({
        path: `${this.path}.dyl_field`,
        message: "DYL Field already being used",
    });
}))

export const findIcon = (fieldType) => {
    return FIELD_TYPE.find((field) => (field.value === fieldType)).icon
}

export const FIELD_TYPE_CONTROL = {
    [FIELD.TEXT]: (props) => (
        <Input
            type='text'
            {...props}
            icon={<Icon className={findIcon(FIELD.TEXT)} />}
            iconPosition='left'
        />
    ),
    [FIELD.TEXTAREA]: (props) => (
        <Input
            type='textarea'
            {...props}
            icon={<Icon className={findIcon(FIELD.TEXT)} />}
            iconPosition='left'
        />
    ),
    [FIELD.NUMBER]: (props) => (
        <Input
            {...props}
            type='number'
            min='0'
            step='1'
            icon={<Icon className={findIcon(FIELD.NUMBER)} />}
            iconPosition='left'
        />
    ),
    [FIELD.PERCENT]: (props) => (
        <Input
            {...props}
            type='number'
            min='0'
            max='100'
            icon={<Icon className={findIcon(FIELD.PERCENT)} />}
        />
    ),
    [FIELD.CURRENCY]: (props) => (
        <Input
            {...props}
            type='number'
            icon={<Icon className={findIcon(FIELD.CURRENCY)} />}
            iconPosition='left'
        />
    ),
    [FIELD.CHECKBOX]: ({ onChange, name, ...props }) => (
        <Checkbox
            {...props}
            onChange={(e, { checked }) => { onChange(e, { name, value: checked }) }}
            icon={<Icon className={findIcon(FIELD.CHECKBOX)} />}
            iconPosition='left'
        />
    ),
    [FIELD.URL]: (props) => (
        <Input
            {...props}
            icon={<Icon className={findIcon(FIELD.URL)} />}
            iconPosition='left'
        />
    ),
    [FIELD.EMAIL]: (props) => (
        <Input
            {...props}
            icon={<Icon className={findIcon(FIELD.EMAIL)} />}
            iconPosition='left'
            type='email'
        />
    ),
    [FIELD.PHONE]: (props) => (
        <Input
            {...props}
            icon={<Icon className={findIcon(FIELD.PHONE)} />}
            iconPosition='left'
            type='text'
        />
    ),
    [FIELD.DATE]: (props) => (
        <DateInput
            {...props}
            icon={findIcon(FIELD.DATE)}
            iconPosition='left'
        />
    ),
    [FIELD.TIME]: (props) => (
        <TimeInput
            {...props}
            icon={findIcon(FIELD.TIME)}
            iconPosition='left'
        />
    ),
    [FIELD.DATE_RANGE]: (props) => (
        <DatesRangeInput
            {...props}
            icon={findIcon(FIELD.DATE_RANGE)}
            iconPosition='left'
        />
    ),
    [FIELD.TIME_RANGE]: (props) => (
        <DatesRangeInput
            {...props}
            icon={findIcon(FIELD.TIME_RANGE)}
            iconPosition='left'
        />
    ),
    [FIELD.RADIO]: ({ onChange, name, ...props }) => (
        <Checkbox
            {...props}
            type='radio'
            onChange={(e, { checked }) => { onChange(e, { name, value: checked }) }}
            icon={<Icon className={findIcon(FIELD.RADIO)} />}
            iconPosition='left'
        />
    ),
    [FIELD.PICKLIST]: (props) => (
        <Dropdown
            {...props}
            multiple
            button 
            basic
            className='DataMapping__DefaultValue__Picklist icon'
            labeled
            // search //if search prop, it breaks clearable
            clearable
            scrolling
            icon={<Icon className={`${findIcon(FIELD.PICKLIST)} DataMapping__DefaultValue`} />}
        />
    ),
    [FIELD.USER]: (props) => (
        <Input
            type='text'
            {...props}
            icon={<Icon className={findIcon(FIELD.USER)} />}
            iconPosition='left'
        />
    ),
    [FIELD.TAGS]: (props) => (
        <Dropdown
            {...props}
            multiple
            allowAdditions
            button 
            basic
            className='DataMapping__DefaultValue icon'
            labeled
            // search //if search prop, it breaks clearable
            clearable
            scrolling
            icon={<Icon className={`${findIcon(FIELD.TAGS)} DataMapping__DefaultValue`} />}
        />
    ),
    [FIELD.DROPDOWN]: (props) => (
        <Dropdown
            {...props}
            button
            basic
            className='DataMapping__DefaultValue icon'
            labeled
            // search //if search prop, it breaks clearable
            clearable
            scrolling
            icon={<Icon className={`${findIcon(FIELD.DROPDOWN)} DataMapping__DefaultValue`} />}
        />
    ),
    [FIELD.STATE]: (props) => (
        <Dropdown
            {...props}
            button
            basic
            className='DataMapping__DefaultValue icon'
            labeled
            floating
            // search //if search prop, it breaks clearable
            clearable
            scrolling
            options={STATES.slice(1).map(state => ({ ...state, value: state.key }))}
            icon={<Icon className={`${findIcon(FIELD.STATE)} DataMapping__DefaultValue`} />}

        />
    ),
    [FIELD.DEPENDENCY]: (props) => (
        <NestedDropdown
            {...props}
            loading={false}
            display_parent
            className='DataMapping__DefaultValue icon'
            labeled
            button
            basic
            halfDiv
            allowedToSelectParent
            icon={`${findIcon(FIELD.DEPENDENCY)} DataMapping__DefaultValue`}
        />
    ),
    [FIELD.RATING]: (props) => (
        <Dropdown
            {...props}
            button 
            basic
            className='DataMapping__DefaultValue icon'
            labeled
            // search //if search prop, it breaks clearable
            clearable
            icon={<Icon className={`${findIcon(FIELD.RATING)} DataMapping__DefaultValue`} />}
            scrolling
        />
    ),
};

export const DUPLICATE_OPTIONS = [
    { key: 'skip', value: 'skip', text: 'Skip duplicate records' },
    { key: 'duplicate', value: 'duplicate', text: 'Create new duplicates' },
    { key: 'merge', value: 'merge', text: 'Merge and add new records' },
    { key: 'merge_only', value: 'merge_only', text: 'Merge only existing records' }
];

