import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header, Popup, Segment, Form } from 'semantic-ui-react';
import ContactGroupForm from 'shared/forms/ContactGroupForm';
import groupsActions from 'actions/groups';
import { useParams } from 'react-router-dom';
import { ButtonLink, Notification, STATUS_TYPES } from 'dyl-components';
import { useForm } from 'react-hook-form';
import './index.scss'

const GroupsSection = () => {
    const params = useParams();
    const { contact_id } = params;
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [groupOptions, setGroupOptions] = useState([]);
    const [search, setSearch] = useState('');

    const { groups, subgroups, contactGroups, contactGroupsCount, isReadingGroups, isAddingContact } = useSelector((state) => {
        return {
            groups: state.groups.groups,
            subgroups: state.groups.subgroups,
            contactGroups: state.groups.contactGroups,
            contactGroupsCount: state.groups.contactGroupsCount,
            isReadingGroups: state.groups.isReadingGroups,
            isReadingContactGroups: state.groups.isReadingContactGroups,
            isAddingContact: state.groups.isAddingContact
        }
    });

    const { control, handleSubmit, formState: { isDirty, isValid }, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            groups: contactGroups,
        }
    });

    const getSubgroups = async (parent_label_id) => {
        await dispatch(groupsActions.readSubgroups({ parent_label_id: Number(parent_label_id.slice(0, -1)) }));
    }

    const getGroups = useCallback(async (search) => {
        await dispatch(groupsActions.readGroups({ search, limit: 500 }));
    }, [dispatch])

    const getCurrentGroups = async () => {
        const result = await dispatch(groupsActions.readContactGroups(contact_id));
        reset({ groups: result.data || [] });
    }

    const onSearchChange = (e) => {
        setSearch(e.target.value);
    }

    const onCancel = () => {
        reset();
        setSearch("");
        setIsOpen(false)
    }

    const onSave = async (data) => {
        const { groups } = data;
        const contactId = parseInt(contact_id);

        const contactGroupIds = contactGroups.map((contactGroup) => contactGroup.id ? contactGroup.id : contactGroup.value);
        const groupsIds = groups.map((group) => group.id ? group.id : group.value);

        const toDelete = contactGroupIds.filter((contactGroupId) => !groupsIds.includes(contactGroupId));
        const toAdd = groupsIds.filter((groupId) => !contactGroupIds.includes(groupId));

        try {
            const promises = [];

            toAdd.forEach((group_id) => {
                promises.push(dispatch(groupsActions.addContactsToGroup({ group_id, contacts: [contactId] })));
            });

            toDelete.forEach((group_id) => {
                promises.push(dispatch(groupsActions.deleteContactGroup(contactId, { group_id })));
            });

            Promise.all(promises).then(() => {
                setIsOpen(false);
                reset();
                setSearch("");
                getCurrentGroups();
                Notification.alert('Successfully added contact to group', STATUS_TYPES.SUCCESS);
            })
        } catch (error) {
            console.log(error);
            Notification.alert('Failed to add contact to group', STATUS_TYPES.ERROR);
        }
    }

    useEffect(() => {
        getGroups(search);
    }, [search, getGroups])

    useEffect(() => {
        const groupOptionsAux = groups.map((group) => {
            let subgroupsAux = [];
            if (subgroups[group.id] && subgroups[group.id].length > 0) {
                subgroupsAux = subgroups[group.id].map((subgroup) => ({ text: subgroup.name, key: subgroup.id + "C", value: subgroup.id }));
            }
            return { text: group.name, key: group.id + "P", value: group.id, hasOptions: group.sub_labels, options: subgroupsAux, parent: group.parent_label_name };
        })
        setGroupOptions(groupOptionsAux);
    }, [groups, subgroups])

    return (
        <Popup
            trigger={
                <Segment>
                    <div style={{ display: 'flex' }}>
                        <Header style={{ margin: 0, flex: 1 }} as='h4'>Groups</Header>
                        <ButtonLink>{`${contactGroupsCount} group${contactGroupsCount !== 1 ? 's' : ''}`}</ButtonLink>
                    </div>
                </Segment>
            }
            className="GroupSection__popup"
            on="click"
            content={
                <Form loading={isAddingContact}>
                    <div>
                        <Header as='h4'>
                            Add/Edit Groups
                        </Header>
                        <ContactGroupForm
                            control={control}
                            handleSubmit={handleSubmit}
                            isDirty={isDirty}
                            isValid={isValid}
                            groups={groupOptions}
                            onHoverParent={getSubgroups}
                            onSave={onSave}
                            onCancel={onCancel}
                            search={search}
                            onSearchChange={onSearchChange}
                            isReadingGroups={isReadingGroups}
                            setSearch={setSearch}
                        />
                    </div>
                </Form>
            }
            position="bottom center"
            flowing
            basic
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
        />

    )
};

export default GroupsSection;
