
import routes from "actions/routes";
import { generateReadOneActionCreator, generateCreateActionCreator, generateUpdateActionCreator, generateDeleteActionCreator, generateReadActionCreator } from "actions/crudActionCreators";
import ACTION_NAMES from "actions/ACTION_NAMES";
import uploadActions from "actions/upload";

export default {
    readCompany: generateReadActionCreator(routes.API_COMPANY, ACTION_NAMES.COMPANY),
    createCompany: generateCreateActionCreator(routes.API_COMPANY, ACTION_NAMES.COMPANY, () => { }, () => { }),
    updateCompany: generateUpdateActionCreator(routes.API_COMPANY, ACTION_NAMES.COMPANY, () => { }, () => { }),

    readCompanyLocations: generateReadOneActionCreator(routes.API_COMPANY_LOCATIONS, ACTION_NAMES.COMPANY_LOCATIONS),
    createCompanyLocations: generateCreateActionCreator(routes.API_COMPANY_LOCATIONS, ACTION_NAMES.COMPANY_LOCATIONS, () => { }, () => { }),
    updateCompanyLocation: generateUpdateActionCreator(routes.API_COMPANY_LOCATIONS, ACTION_NAMES.COMPANY_LOCATIONS, () => { }, () => { }),
    deleteCompanyLocation: generateDeleteActionCreator(routes.API_COMPANY_LOCATIONS, ACTION_NAMES.COMPANY_LOCATIONS, () => { }, () => { }),

    readCompanyPhones: generateReadOneActionCreator(routes.API_COMPANY_PHONES, ACTION_NAMES.COMPANY_PHONES),
    createCompanyPhones: generateCreateActionCreator(routes.API_COMPANY_PHONES, ACTION_NAMES.COMPANY_PHONES, () => { }, () => { }),
    updateCompanyPhone: generateUpdateActionCreator(routes.API_COMPANY_PHONES, ACTION_NAMES.COMPANY_PHONES, () => { }, () => { }),
    deleteCompanyPhone: generateDeleteActionCreator(routes.API_COMPANY_PHONES, ACTION_NAMES.COMPANY_PHONES, () => { }, () => { }),

    readCompanySocialMedias: generateReadOneActionCreator(routes.API_COMPANY_SOCIAL_MEDIAS, ACTION_NAMES.COMPANY_SOCIAL_MEDIAS),
    createCompanySocialMedias: generateCreateActionCreator(routes.API_COMPANY_SOCIAL_MEDIAS, ACTION_NAMES.COMPANY_SOCIAL_MEDIAS, () => { }, () => { }),
    updateCompanySocialMedia: generateUpdateActionCreator(routes.API_COMPANY_SOCIAL_MEDIAS, ACTION_NAMES.COMPANY_SOCIAL_MEDIAS, () => { }, () => { }),
    deleteCompanySocialMedia: generateDeleteActionCreator(routes.API_COMPANY_SOCIAL_MEDIAS, ACTION_NAMES.COMPANY_SOCIAL_MEDIAS, () => { }, () => { }),

    readIndustry: generateReadOneActionCreator(routes.API_COMPANY_INDUSTRY, ACTION_NAMES.COMPANY_INDUSTRY),

    readSignatures: generateReadActionCreator(routes.API_SIGNATURE, ACTION_NAMES.COMPANY_SIGNATURE),
    createSignature: generateCreateActionCreator(routes.API_SIGNATURE, ACTION_NAMES.COMPANY_SIGNATURE),
    updateSignature: generateUpdateActionCreator(routes.API_SIGNATURE, ACTION_NAMES.COMPANY_SIGNATURE),
    deleteSignature: generateDeleteActionCreator(routes.API_SIGNATURE, ACTION_NAMES.COMPANY_SIGNATURE),

    readSignaturePreferences: generateReadActionCreator(routes.API_SIGNATURE_PREFERENCES, ACTION_NAMES.COMPANY_SIGNATURE_PREFERENCES, () => { }, () => { }),
    updateSignaturePreferences: generateUpdateActionCreator(routes.API_SIGNATURE_PREFERENCES, ACTION_NAMES.COMPANY_SIGNATURE_PREFERENCES, () => { }, () => { }),

    readCompanyLogo: generateReadActionCreator(routes.API_COMPANY_LOGO, ACTION_NAMES.COMPANY_LOGO, () => { }, () => { }),
    uploadCompanyLogo: (file) => async dispatch => {
        await dispatch(uploadActions.uploadProfile(file, "profile", "company"));
    },
    deleteCompanyLogo: generateDeleteActionCreator(routes.API_COMPANY_LOGO, ACTION_NAMES.COMPANY_LOGO, () => { }, () => { }),
}
