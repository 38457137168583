import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {Header} from 'semantic-ui-react';
import {TableWithHeader} from 'dyl-components';

import usersActions from 'actions/users';
import textActions from 'actions/text';
import oauthActions from 'actions/oauth';

import './index.scss';
import {KEY_ACCOUNT} from 'shared/constants/MODULE_COLUMNS';

const ModulesListView = ({
    defaultColumnsDisplayed,
    allowedColumns,
    readModules,

    routeRoot,
    moduleName,

    ToolbarComponent,
    TableComponent,

    columnDisplayOptions
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [params] = useSearchParams();

    const searchQuery = params.get('search') || '';
    const [search, setSearch] = useState(searchQuery);

    const onChangeSearch = (_, { value }) => {
        setSearch(value);
    }

    const cancelFunction = () => {
        setSearch("");
        const query = new URLSearchParams(params);
        query.delete('search');
        const query_string = query.toString();
        navigate(`/${routeRoot}${query_string ? `?${query_string}` : ''}`,);
    };

    const columnsParameter = params.get('columns')?.split(',')?.filter(column => columnDisplayOptions.includes(column)) || [];
    const displayedColumns = columnsParameter.length ? columnsParameter : defaultColumnsDisplayed;

    const filterQueryParameters = () => {
        const columns = displayedColumns.filter(column =>
            allowedColumns.includes(column)
        );

        return {
            page: 1,
            limit: 25,
            ...Object.fromEntries(params),
            columns: (columns.includes(KEY_ACCOUNT) ? columns : [...columns, KEY_ACCOUNT]).join(',')
        };
    }

    const location = useLocation();

    useEffect(() => {
        if (!location.state?.isTogglingPanel) {
            dispatch(usersActions.readUsers());

            dispatch(readModules(filterQueryParameters()));
            dispatch(textActions.getPhones());
            dispatch(oauthActions.getIntegrations({ scopes: 'email' }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    const [filters, setFilters] = useState({
        account_type: params.get('account_type')?.split(','),
        owned_by: params.get('owned_by')?.split(',')
    });

    const onFilter = (_, { name, value }) => {
        setFilters({
            ...filters,
            [name]: value
        });
    }

    useEffect(() => {
        const query = new URLSearchParams(params);
        if (search.trim()) {
            query.set('search', search);
        } else {
            query.delete('search');
        }
        const { account_type, owned_by } = filters;
        if (account_type?.length > 0) {
            query.set('account_type', account_type.join(','));
        } else {
            query.delete('account_type');
        }
        if (owned_by?.length > 0) {
            query.set('owned_by', owned_by.join(','));
        } else {
            query.delete('owned_by');
        }
        const query_string = query.toString();
        navigate(`/${routeRoot}${query_string ? `?${query_string}` : ''}`,);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    const onToggleColumn = async (column) => {
        const updatedColumns = isColumnHidden(column) ? [...displayedColumns, column] : displayedColumns.filter(visibleColumn => visibleColumn !== column);

        const updateColumnsParameter = () => {
            const query = new URLSearchParams(params);
            if (updatedColumns.length) {
                query.set('columns', updatedColumns.join(','));
            } else {
                query.delete('columns');
            }
            const query_string = query.toString();
            navigate(`/${routeRoot}${query_string ? `?${query_string}` : ''}`);
        }

        if (!isColumnHidden(column)) {
            const { account_type, owned_by } = filters;

            switch (column) {
                case "Owned By":
                    if (owned_by?.length > 0) {
                        onFilter(null, { name: "owned_by", value: [] });
                    } else {
                        updateColumnsParameter();
                    }
                    break;
                case "Account":
                    if (account_type?.length > 0) {
                        onFilter(null, { name: "account_type", value: [] });
                    } else {
                        updateColumnsParameter();
                    }
                    break;
                case "Created":
                    if (params.get('start') || params.get('end') || params.get('order_by') === 'created') {
                        const query = new URLSearchParams(params);
                        query.delete('start');
                        query.delete('end');
                        query.delete('order_by');
                        query.delete('order');
                        if (updatedColumns.length) {
                            query.set('columns', updatedColumns.join(','));
                        } else {
                            query.delete('columns');
                        }
                        const query_string = query.toString();
                        navigate(`/${routeRoot}${query_string ? `?${query_string}` : ''}`);
                    } else {
                        updateColumnsParameter();
                    }
                    break;
                case "Last Modified":
                    if (params.get('order_by') === 'activity') {
                        const query = new URLSearchParams(params);
                        query.delete('order_by');
                        query.delete('order');
                        if (updatedColumns.length) {
                            query.set('columns', updatedColumns.join(','));
                        } else {
                            query.delete('columns');
                        }
                        const query_string = query.toString();
                        navigate(`/${routeRoot}${query_string ? `?${query_string}` : ''}`);
                    } else {
                        updateColumnsParameter();
                    }
                    break;
                default:
                    updateColumnsParameter();
            }
        } else {
            updateColumnsParameter();
        }
    }

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`/${routeRoot}${query_string ? `?${query_string}` : ''}`);
    }

    const onSearchSubmit = (value) => {
        const query = new URLSearchParams(params);
        query.set('search', value.trim());
        query.set('page', "1");
        const query_string = query.toString();
        navigate(`/${routeRoot}${query_string ? `?${query_string}` : ''}`);
    }

    const isColumnHidden = (column) => {
        return !displayedColumns.includes(column);
    }

    return (
        <div className='ModulesListPage'>
            <Header className='ModulesListPage__PageHeader'>
                <Header.Content>{moduleName}</Header.Content>
            </Header>
            <TableWithHeader
                header={(
                    <ToolbarComponent
                        search={searchQuery}
                        onChangeSearch={onChangeSearch}
                        onSearchSubmit={onSearchSubmit}
                        cancelFunction={cancelFunction}
                        isColumnHidden={isColumnHidden}
                        onToggleColumn={onToggleColumn}
                    />
                )}
                table={(
                    <TableComponent
                        isColumnHidden={isColumnHidden}
                        onFilter={onFilter}
                        onPageChange={onPageChange}
                        filterQueryParameters={filterQueryParameters}
                    />
                )}
            />
        </div>
    );
}

export default ModulesListView;
