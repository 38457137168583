import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { Grid, Menu } from 'semantic-ui-react';

const Billing = () => {
    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Menu borderless pointing secondary color='primary' stackable>
                        <Menu.Item as={NavLink} name='Overview' to={`overview`} />
                        <Menu.Item as={NavLink} name='Transaction History' to={`transaction-history`} />
                        <Menu.Item as={NavLink} name='Payment Method' to={`payment-method`} />
                        <Menu.Item as={NavLink} name='Billing Contacts' to={`billing-contacts`} />
                    </Menu>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Outlet />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default Billing;
