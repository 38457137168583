import React from "react";
import { NestedDropdown } from "dyl-components";
import { Controller } from "react-hook-form";
import { Form } from "semantic-ui-react";
import { PhoneUtil } from "utils";
import "../index.scss";

const CallQueueModal = ({
    control,
    onCheckDuplicatedName,
    data,
    destinationOptions,
    ringGroupExcludedParents,
    isReadingDestinations,
    ringModeOptions,
    greetingSoundOptions,
    isReadingUnusedExtensions,
    internalExtensionOptionsList,
    formatExtensionsOptions,
    unusedExtensions,
}) => {
    return (
        <>
            <Form.Group widths="equal">
                <Controller
                    name={`label`}
                    control={control}
                    render={({
                        field: { name, value, onChange },
                        fieldState: { error },
                    }) => (
                        <Form.Input
                            name={name}
                            value={value}
                            onBlur={onCheckDuplicatedName}
                            error={error?.message}
                            onChange={(_, { value }) => {
                                onChange({ target: { name, value } });
                            }}
                            placeholder="Enter name"
                            label="Name"
                            required
                        />
                    )}
                />

                <Controller
                    name={`ring_strategy`}
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Select
                            name={name}
                            value={value}
                            search
                            onChange={(_, { value }) => {
                                onChange({
                                    target: { name, value },
                                });
                            }}
                            label={"Ring Mode"}
                            placeholder={`Select`}
                            selectOnBlur={false}
                            options={ringModeOptions}
                            required
                        />
                    )}
                />

                <Controller
                    name={`ring_time`}
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Select
                            name={name}
                            value={value}
                            search
                            onChange={(_, { value }) => {
                                onChange({
                                    target: { name, value },
                                });
                            }}
                            label="Ring Duration"
                            placeholder={`Select Ring Duration`}
                            selectOnBlur={false}
                            options={PhoneUtil.RING_DURATION_OPTIONS}
                            required
                        />
                    )}
                />

                <Controller
                    name={`destination_parent`}
                    control={control}
                    render={({
                        field: {
                            name: parentName,
                            value: parentValue,
                            onChange: onParentChange,
                        },
                    }) => (
                        <Controller
                            name={`after_destination_id`}
                            control={control}
                            render={({ field, fieldState: { error } }) => {
                                const {
                                    name: childName,
                                    value: childValue,
                                    onChange: onChildChange,
                                } = field;
                                return (
                                    <Form.Field
                                        control={NestedDropdown}
                                        child_value={
                                            data.label && !parentValue
                                                ? data?.after_destination
                                                      ?.destination_id
                                                : childValue
                                        }
                                        parent_value={
                                            data.label && !parentValue
                                                ? data?.after_destination?.type
                                                : parentValue
                                        }
                                        loading={isReadingDestinations}
                                        nested_options={destinationOptions}
                                        onChange={(
                                            _,
                                            { parent_value, child_value }
                                        ) => {
                                            onParentChange({
                                                target: {
                                                    name: parentName,
                                                    value: parent_value,
                                                },
                                            });
                                            onChildChange({
                                                target: {
                                                    name: childName,
                                                    value:
                                                        child_value ||
                                                        parent_value,
                                                },
                                            });
                                        }}
                                        excluded_parents={
                                            ringGroupExcludedParents
                                        }
                                        placeholder="Select destination"
                                        display_parent
                                        selection
                                        label="No Answer Destination"
                                        pointing="top"
                                        width={4}
                                        required={true}
                                        error={error?.message}
                                        className="no_answer_destinations_nested_dropdown"
                                    />
                                );
                            }}
                        />
                    )}
                />
            </Form.Group>

            <Form.Group>
                <Controller
                    name={`audio_while_ringing_sound_id`}
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Select
                            name={name}
                            value={value}
                            search
                            onChange={(_, { value }) => {
                                onChange({
                                    target: { name, value },
                                });
                            }}
                            label={{
                                children: <span>{"Audio While Ringing"}</span>,
                            }}
                            placeholder={`Select Audio`}
                            selectOnBlur={false}
                            width={4}
                            options={greetingSoundOptions.slice(1)}
                        />
                    )}
                />

                <Controller
                    name={`alias`}
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Select
                            name={name}
                            value={value}
                            loading={isReadingUnusedExtensions}
                            search
                            onChange={(_, { value }) => {
                                onChange({
                                    target: { name, value },
                                });
                            }}
                            label="Internal Extension"
                            placeholder={`Select Extension`}
                            selectOnBlur={false}
                            options={
                                data.label
                                    ? internalExtensionOptionsList
                                    : formatExtensionsOptions(unusedExtensions)
                            }
                            width={4}
                        />
                    )}
                />

                <Controller
                    name="view"
                    control={control}
                    render={({ field: { onChange, name, value } }) => {
                        return (
                            <Form.Checkbox
                                name={name}
                                className="displayOnOffClass"
                                checked={value}
                                toggle
                                label="Display on Office View"
                                onChange={(_, { checked }) => {
                                    onChange({
                                        target: {
                                            name,
                                            value: checked,
                                        },
                                    });
                                }}
                                width={4}
                            />
                        );
                    }}
                />
            </Form.Group>
        </>
    );
};

export default CallQueueModal;
